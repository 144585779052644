import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from 'antd';
import {
  levelsSelect,
  languages as languagesList,
} from '../../Constants/levels';
import { useFetchData } from '../../Hooks';
import {
  sessionModalityTypes,
  studentTypes,
} from '../../Constants/SelectionOptions';

const format = 'HH:mm';
const { Option } = Select;

export const GroupForm = ({ formRef, onSubmit }) => {
  const [advisers, advLoading] = useFetchData('advisers', 'status=1', 0, 50);

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="GroupForm"
      onFinish={values => {
        onSubmit(values);
      }}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item
            label="Nombre del Curso"
            name="name"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
            ]}
          >
            <Input placeholder="Ingresa nombre del curso" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Dirigido a"
            name="target"
            rules={[
              {
                required: true,
                message: 'Campo requerido.',
              },
            ]}
          >
            <Select options={studentTypes} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Idioma"
            name="language"
            rules={[
              {
                required: true,
                message: 'Debe seleccionar un idioma',
              },
            ]}
          >
            <Select options={languagesList} placeholder="Seleccionar idioma" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Nivel"
            name="level"
            rules={[
              {
                required: true,
                message: 'Debe seleccionar un nivel',
              },
            ]}
          >
            <Select options={levelsSelect} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Descripción" name="description">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item label="Días de la Semana" name="week_days">
            <Checkbox.Group>
              <Checkbox value={0}>Domingo</Checkbox>
              <Checkbox value={1}>Lunes</Checkbox>
              <Checkbox value={2}>Martes</Checkbox>
              <Checkbox value={3}>Miercoles</Checkbox>
              <Checkbox value={4}>Jueves</Checkbox>
              <Checkbox value={5}>Viernes</Checkbox>
              <Checkbox value={6}>Sábado</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Modalidad"
            name="modality"
            rules={[
              {
                required: true,
                message: 'Debe elegirse la modalidad de clase',
              },
            ]}
          >
            <Select options={sessionModalityTypes} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Hora Inicio"
            name="start_hour"
            rules={[
              {
                required: true,
                message: 'Seleccione una hora de inicio',
              },
            ]}
          >
            <TimePicker format={format} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Hora Fin"
            name="end_hour"
            rules={[
              {
                required: true,
                message: 'Seleccione una hora de fin',
              },
            ]}
          >
            <TimePicker format={format} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Fecha Inicio"
            name="start_date"
            rules={[
              {
                required: true,
                message: 'Seleccione una fecha de inicio',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Fecha Fin"
            name="end_date"
            rules={[
              {
                required: true,
                message: 'Seleccione una fecha de fin',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Límite de Alumnos"
            name="member_limit"
            rules={[
              {
                required: true,
                message: 'Límite requerido',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Total de Sesiones" name="session_total">
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Costo por Sesión"
            name="session_cost"
            rules={[{ required: true, message: 'Costo requerido' }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Asesor" name="adviser_id">
            <Select loading={advLoading}>
              {advisers?.data.map(oAdv => (
                <Option key={oAdv._id} value={oAdv._id}>
                  {oAdv.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item initialValue={1} label="Estatus" name="status">
            <Select>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

GroupForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
