import React from 'react';
import { Layout } from 'antd';
import { OuterContainer } from '../../Components/Atoms/OuterContainer';
import { FooterContainer } from '../../Components/Atoms/FooterContainer';
import { AdviserDetails } from '../../Components/AdviserDetails/AdviserDetails';

export const AdviserDetail = () => {
  return (
    <Layout className="outer-screen adviser-detail-screen top-bar">
      <OuterContainer>
        <AdviserDetails />
      </OuterContainer>
      <FooterContainer />
    </Layout>
  );
};
