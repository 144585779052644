import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Typography,
} from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useNavigation, useAuth } from './Hooks';
import { handleErrors } from './Utils/errors';
import logo from './Assets/img/educom-logo.png';
import background from './Assets/img/background.jpg';
import facebook from './Assets/img/facebook.png';
import google from './Assets/img/google.png';
// eslint-disable-next-line no-unused-vars
import { process, OPEN_SAVE, FORGOT } from './Service/Api';

const { Title, Text } = Typography;

const margen = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: { span: 16 },
};

function App() {
  const [, nav] = useNavigation();
  const [aForget, setForget] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [rLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [, login, , error, resetError, loading] = useAuth();
  const [loginForm] = Form.useForm();
  const [registerForm] = Form.useForm();
  // !const [forgotForm] = Form.useForm();

  const _handleLogin = values => {
    login(values.email, values.password);
  };

  const _handleRegister = async values => {
    setLoading(true);

    const response = await process(OPEN_SAVE, 'clients', values);

    if (response?.ok) {
      notification.success({
        message:
          'Usuario creado exitosamente, revisa tu correo para activarlo e iniciar sesión',
        description:
          'Si no lo encuentra en su bandeja de entrada, por favor revise su carpeta de spam o correo no deseado.',
      });
      setIsRegister(false);
    } else {
      const { data } = response;
      handleErrors(
        data,
        'usuarios (clientes)',
        'Hubo un problema al registrarte, si esto persiste, no dudes en comunicarte con nosotros'
      );
    }
    setLoading(false);
  };

  const _handleForgot = async values => {
    const response = await process(FORGOT, '', values);

    if (response?.ok) {
      notification.success({
        message: 'Correo enviado exitosamente',
      });
      setForget(!aForget);
    } else {
      notification.error({
        message: 'Error al enviar correo',
        description: response.data?.message,
      });
    }
  };

  return (
    <div className="login-screen">
      <Row className="main-container">
        <Col span={12} className="bg-container">
          <div className="wrapper">
            <div
              className="bg-image"
              style={{ backgroundImage: `url(${background})` }}
            >
              <div className="bg-logo">
                <a onClick={() => nav('/')}>
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col className="login-form" span={12}>
          {!aForget ? (
            <div className="spacer">
              <Title level={2}>
                {isRegister ? 'Crea una Cuenta, ¡es gratis!' : 'Iniciar Sesión'}
              </Title>
              <div className="social-media-options">
                <a className="social-icon facebook">
                  <img src={facebook} alt="facebook" />
                </a>

                <a className="social-icon google">
                  <img src={google} alt="google" />
                </a>
              </div>

              <Divider style={{ minWidth: '67.5%', maxWidth: '67.5%' }}>
                o
              </Divider>

              {isRegister ? (
                <Form
                  {...margen}
                  layout="vertical"
                  form={registerForm}
                  onFinish={_handleRegister}
                >
                  <Form.Item
                    label="Nombre(s)"
                    name="name"
                    rules={[{ required: true, message: 'Nombre(s) requerido' }]}
                  >
                    <Input size="large" placeholder="Nombre" />
                  </Form.Item>
                  <Form.Item
                    label="Apellido(s)"
                    name="last_name"
                    rules={[
                      { required: true, message: 'Apellido(s) requerido' },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre" />
                  </Form.Item>
                  <Form.Item
                    label="Correo"
                    name="email"
                    rules={[{ required: true, message: 'Correo requerido' }]}
                  >
                    <Input
                      type="email"
                      placeholder="sample@email.com"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Campo requerido',
                      },
                      {
                        message:
                          'Mínimo 5 caracteres y máximo 30, contener letras y/o números, y los caracteres especiales válidos son "!@#$%=;"',
                        pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="********"
                      size="large"
                      onChange={({ target: { value } }) => {
                        setPassword(value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirmar contraseña"
                    name="rpassword"
                    rules={[
                      {
                        required: true,
                        pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
                        validator: (_, value) => {
                          if (value !== password) {
                            return Promise.reject(
                              'Las contraseñas no coinciden'
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password placeholder="********" size="large" />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      onClick={registerForm.submit}
                      loading={rLoading}
                    >
                      Crear Cuenta
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form
                  {...margen}
                  layout="vertical"
                  form={loginForm}
                  onFinish={_handleLogin}
                >
                  <Form.Item label="Correo" name="email">
                    <Input placeholder="sample@email.com" size="large" />
                  </Form.Item>
                  <Form.Item label="Contraseña" name="password">
                    <Input.Password placeholder="********" size="large" />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button
                      size="large"
                      htmlType="submit"
                      type="primary"
                      block
                      loading={loading}
                      onClick={loginForm.submit}
                    >
                      Iniciar Sesión
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '67.5%',
                }}
              >
                <a
                  onClick={() => {
                    setIsRegister(!isRegister);
                    resetError(null);
                  }}
                >
                  {isRegister
                    ? '¿Ya tienes una cuenta?, Inicia Sesión'
                    : '¿No tienes una cuenta?, Registrate'}
                </a>

                {isRegister ? (
                  <a
                    onClick={() => nav('/advisers/signup')}
                    style={{ color: '#EF3900' }}
                  >
                    ¿Quieres ser un instructor en nuestra plataforma? Registrate
                    aqui
                  </a>
                ) : (
                  <>
                    <Divider style={{ width: '67.5%' }} />
                    <a
                      onClick={() => setForget(!aForget)}
                      style={{ color: '#EF3900' }}
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </>
                )}
                {error !== null && (
                  <Alert
                    style={{ textAlign: 'center' }}
                    type="error"
                    message={error.message || null}
                    description={error.description}
                  />
                )}
              </div>
            </div>
          ) : (
            //* Forgot password
            <div className="spacer">
              <Title level={3}>¿No recuerdas tu contraseña?</Title>
              <Text>No te preocupes te lo enviamos por correo.</Text>

              <Divider style={{ minWidth: '67.5%', maxWidth: '67.5%' }} />
              <Form
                {...margen}
                layout="vertical"
                form={loginForm}
                onFinish={_handleForgot}
              >
                <Form.Item label="Correo" name="email">
                  <Input placeholder="sample@email.com" size="large" />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    size="large"
                    htmlType="submit"
                    type="primary"
                    block
                    loading={loading}
                    onClick={loginForm.submit}
                    icon={<SendOutlined />}
                  >
                    Recuperar Contrasaseña
                  </Button>
                  <Divider />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a onClick={() => setForget(!aForget)} color="primary">
                      Iniciar Sesión
                    </a>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default App;
