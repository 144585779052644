import React, { useState } from 'react';
import { Modal, Rate, Input } from 'antd';
import PropTypes from 'prop-types';

const { TextArea } = Input;

export const EvaluationModal = ({
  adviserFullName,
  loading,
  visible,
  onCancel,
  onOk,
}) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleRatingChange = value => {
    setRating(value);
  };

  const handleCommentChange = e => {
    setComment(e.target.value);
  };

  const handleOk = () => {
    onOk(rating, comment);
    setRating(0);
    setComment('');
  };

  return (
    <Modal
      centered
      visible={visible}
      title={`Evaluación de Asesor ${adviserFullName}`}
      onCancel={onCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      style={{ maxWidth: '500px' }}
      bodyStyle={{ padding: '24px' }}
    >
      <div>
        <p
          style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '8px' }}
        >
          Calificación:
        </p>
        <Rate
          onChange={handleRatingChange}
          value={rating}
          style={{ fontSize: '24px' }}
        />
      </div>
      <div>
        <p
          style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '8px' }}
        >
          Comentarios:
        </p>
        <TextArea
          rows={4}
          value={comment}
          onChange={handleCommentChange}
          placeholder="Escribe tus comentarios aquí..."
          style={{ fontSize: '16px' }}
        />
      </div>
    </Modal>
  );
};

EvaluationModal.propTypes = {
  adviserFullName: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};
