/* eslint-disable indent */
import { React, useState } from 'react';
import { Avatar, Card, Col, Divider, Row } from 'antd';
import { CustomButton } from '../Atoms/CustomButtom';
import PropTypes from 'prop-types';
import world from '../../Assets/img/world.svg';
import dayjs from 'dayjs';
import {
  calculateNumClasses,
  formatLocaleDate,
  getDaysDisplay,
  getLevelDescription,
  useFetchData,
} from '../../Hooks';
import { ExpandableText } from '../Atoms/ExpanderText';
import { whatsapp_phone } from '../../Constants/Actions';

/* eslint-disable */
export const CardAdviserGroup = ({
  groupData,
  user,
  handlerSubscribe = row => ({}),
}) => {
  /* eslint-enable */

  const isLogedIn = !!Object.keys(user).length;
  const [adviser] = groupData?.adviser_id
    ? useFetchData('advisers', `_id=${groupData.adviser_id}`, 0, 1, !isLogedIn)
    : useState({ data: [] });

  const total_session =
    groupData?.session_total ??
    calculateNumClasses(
      groupData.week_days,
      groupData.start_date,
      groupData.end_date
    );

  const getModalityClass = (value = 'Presencial') => {
    return value;
  };

  const dateFormatter = value => {
    if (value === undefined || value === null) {
      return '';
    }

    let date = dayjs(value);

    return date.format('D MMMM');
  };

  const whatsAppMessage = () => {
    window.open(
      `https://wa.me/${whatsapp_phone}/?text=${encodeURI(
        `Hola! Estoy solicitando información de la clase ${groupData.language} ${groupData.level}`
      )}`,
      '_blank'
    );
  };

  return (
    <Card className="card-desing">
      <Row gutter={4} align={'middle'}>
        <Col span={8}>
          <Row gutter={[2, 2]} justify={'center'} align={'middle'}>
            <Avatar shape={'square'} size={80} src={world} />
          </Row>
        </Col>
        <Col span={16} style={{ paddingLeft: '1em' }}>
          <Row gutter={[4, 2]} justify={'start'}>
            <Col span={24}>
              <span className="label">Nombre del curso:</span>
            </Col>
            <Col span={24}>
              <span className="texto">
                {/* eslint-disable */}
                {groupData?.name
                  ? `${groupData.name}`
                  : `${groupData.level} ${getLevelDescription(
                    groupData.level
                  )}`}
                {/* eslint-enable*/}
              </span>
            </Col>
          </Row>
          <Row gutter={[4, 2]} justify={'start'}>
            <Col span={24}>
              <span className="label">Costo:</span>
            </Col>
            <Col span={24}>
              <span className="texto">
                {/* eslint-disable */}
                {`$ ${
                  !isNaN(groupData.session_cost * total_session)
                    ? (groupData.session_cost * total_session)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      .toString() + ' MXN'
                    : ' - '
                } de ${total_session ?? '-'} hrs`}
                {/* eslint-enable */}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider
        style={{ marginTop: 0 }}
        className="divider-style large-margin"
      />
      <Row justify={'start'}>
        <Col span={24} style={{ marginTop: 0, paddingTop: 0 }}>
          <span className="label">Modalidad, dias y horarios:</span>
        </Col>
        <Col span={24}>
          <span className="texto">
            {`${groupData?.modality ?? getModalityClass()}, ${getDaysDisplay(
              groupData.week_days
            )} de ${formatLocaleDate(
              groupData.start_hour
            )} a ${formatLocaleDate(groupData.end_hour)}`}
          </span>
        </Col>
        <Divider className="divider-style large-margin" />
        <Col span={24}>
          <span className="label">Inicio y fin:</span>
        </Col>
        <Col span={24}>
          <span className="texto">
            {`${dateFormatter(
              groupData.start_date
            )} y finaliza el ${dateFormatter(groupData.end_date)}`}
          </span>
        </Col>
        <Divider className="divider-style large-margin" />
        <Col span={24}>
          <span className="label">Curso para:</span>
        </Col>
        <Col span={24}>
          <span className="texto">
            Profesores de {groupData.language} {groupData.level}
          </span>
        </Col>
        <Col span={24} style={{ marginTop: 5 }}>
          <span className="label">Descripción:</span>
        </Col>
        <Col span={24} style={{ maxHeight: '75px', height: '75px' }}>
          <ExpandableText
            text={groupData.description ?? ''}
            className={'texto'}
            limit={100}
          ></ExpandableText>
        </Col>
        <CustomButton
          handleAction={() => whatsAppMessage()}
          title="Más información vía WhatsApp"
          type="default"
          className="btn-whats-app"
        />
        <Col span={24}>
          <span className="nota">
            Al finalizar este curso, recibiras un certificado que será válido
            para tu curriculum vitae. Además, el curso será impartido por un
            maestro en vivo, lo que garantiza una experiencia de aprendizaje
            interactiva y en tiempo real.
          </span>
        </Col>
        <Divider className="divider-style" />
        <CustomButton
          handleAction={() =>
            handlerSubscribe({
              ...groupData,
              adviser: adviser.data,
              total_session: total_session,
            })
          }
          size="large"
          title="Inscríbete ahora"
          type="primary"
          className="btn-primary"
        />
      </Row>
    </Card>
  );
};

CardAdviserGroup.propTypes = {
  groupData: PropTypes.array,
  user: PropTypes.any,
  handlerSubscribe: PropTypes.func,
};
