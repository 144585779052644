import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useNavigation, useAuth } from '../../Hooks';
import { ALL, ADMIN, ADVISER, CLIENT } from '../../Constants/roles';
import { DrawerChat } from './DrawerChat';
import PropTypes from 'prop-types';

const { Header, Content } = Layout;

const filterByRol = (items, rol) => {
  return items.filter(item => {
    if (Array.isArray(item.children)) {
      item.children = filterByRol(item.children, rol);
    }

    if (item.roles.includes(ALL)) {
      return true;
    }
    if (item.roles.includes(rol)) {
      return true;
    }
    return false;
  });
};

const filterByHideOn = items => {
  return items.filter(item => {
    if (Array.isArray(item.children)) {
      item.children = filterByHideOn(item.children);
    }

    if (item.hideon) {
      return false;
    }
    return true;
  });
};

export const OuterContainer = ({ children }) => {
  const [, nav] = useNavigation();
  const [
    {
      role: { rol },
    },
    ,
    logout,
  ] = useAuth();
  const history = useHistory(); // ? Adding history to use it for clearing the url
  const { user } = useSelector(state => state.auth);

  const isLogedIn = !!Object.keys(user).length;
  const isAdmin = rol === ADMIN;
  const [isMobile, setIsMobile] = useState(false);

  // ? This useEffect is for setting the isMobile state
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  const contactInfo = (
    <div className="contact-info">
      {isMobile ? null : (
        <>
          <span>Tel: 444 208 1211</span>
          <span>Correo: educomslp@gmail.com</span>
        </>
      )}
    </div>
  );

  const aMenuItems = [
    {
      key: 1,
      label: 'Inicio',
      onClick: () => nav('/'),
      needlogin: false,
      roles: [ALL],
    },
    {
      key: 2,
      label: 'Inscríbete como Maestro',
      onClick: () => nav('/advisers/signup'),
      needlogin: false,
      hideon: user.rol === ADVISER || user.rol === ADMIN,
      roles: [ALL],
    },
    {
      key: 3,
      label: 'Iniciar Sesión',
      onClick: () => nav('/login'),
      needlogin: false,
      hideon: isLogedIn,
      roles: [ALL],
    },
    {
      key: 4,
      label: 'Info. para Maestros',
      onClick: () => nav('/adviserInformation'),
      needlogin: false,
      roles: [ALL],
    },
    {
      key: 5,
      label: 'Info. para Alumnos',
      needlogin: false,
      onClick: () => nav('/clientInformation'),
      roles: [ALL],
    },
    {
      key: 6,
      label: 'Control Panel',
      onClick: () => nav('/dashboard'),
      needlogin: true,
      roles: [ADMIN],
    },
    {
      key: 7,
      label: 'Sobre Nosotros',
      onClick: () => nav('/about_us'),
      needlogin: false,
      roles: [ALL],
    },
    {
      key: 8,
      label: 'Empresas y Grupos',
      onClick: () => nav('/companies'),
      needlogin: false,
      roles: [ALL],
    },
    {
      key: 9,
      label: `Bienvenido, ${user.full_name}`,
      icon: <UserOutlined />,
      needlogin: true,
      roles: [ADVISER, CLIENT, ADMIN],
      children: [
        {
          label: 'Cuenta',
          onClick: () => {
            // ? Naving from /adviser/my_profile/ to /client/my_account/ without this line will cause a bug
            history.replace('/');
            nav('client/my_account/');
          },
          needlogin: true,
          roles: [ADVISER, CLIENT],
        },
        {
          label: 'Mi Perfil',
          onClick: () => {
            nav(`/adviser/my_profile/${user.adviser_id}`);
          },
          needlogin: true,
          roles: [ADVISER],
        },
        {
          label: 'Mis Solicitudes',
          onClick: () => {
            nav('/requests');
          },
          needlogin: true,
          roles: [ADVISER, CLIENT],
        },
        {
          label: 'Cerrar Sesión',
          onClick: () => {
            logout();
            nav('/');
          },
          needlogin: true,
          roles: [ALL],
        },
      ],
    },
    {
      key: 10,
      label: 'Contacto',
      onClick: () => nav('/contact'),
      needlogin: false,
      roles: [ALL],
    },
  ];

  return (
    <>
      <Layout className="site-layout">
        {isLogedIn && !isAdmin && <DrawerChat />}
        <div className="outer-wrapper">
          <Header
            className="layout-header"
            style={isMobile ? { paddingLeft: '0px' } : {}}
          >
            <div className="logo" />
            <Menu
              className="header-menu"
              items={filterByHideOn(filterByRol(aMenuItems, user.rol))}
              mode="horizontal"
              theme="dark"
            />
            {contactInfo}
          </Header>
          <Content className="outer-content">{children}</Content>
        </div>
      </Layout>
    </>
  );
};

OuterContainer.propTypes = {
  children: PropTypes.node,
};
