/* eslint-disable capitalized-comments */
import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { Button, Checkbox, Col, Form, Input, Row, notification } from 'antd';
import { useNavigation } from '../../Hooks';
import SideBackground from '../../Assets/img/sign-up-background.jpg';
import { process, OPEN_SAVE } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';

const getSize = () => (window.innerWidth >= 1800 ? 'default' : 'small');

const formLayout = () =>
  window.innerWidth >= 1800 ? 'vertical' : 'horizointal';

export const AdviserSignUpForm = () => {
  const [, nav] = useNavigation();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  // eslint-disable-next-line no-unused-vars
  // const [checked, setChecked] = useState(false);
  const [formRef] = Form.useForm();

  const handleSubmit = async values => {
    setLoading(true);
    const oSend = {
      ...values,
      cost_by_hour: parseInt(values.cost_by_hour, 10),
    };

    const response = await process(OPEN_SAVE, 'advisers', oSend);

    if (response.ok) {
      nav('/advisers/success');
      formRef.resetFields();
      notification.success({
        message: 'Asesor registrado exitosamente',
        description:
          'Se ha enviado el correo de verificación. Si no lo encuentra en su bandeja de entrada, por favor revise su carpeta de spam o correo no deseado.',
      });
    } else {
      const { data } = response;
      handleErrors(
        data,
        'asesores',
        'Hubo un problema al registrarte, si esto persiste, no dudes en comunicarte con nosotros'
      );
    }
    setLoading(false);
  };

  return (
    <div className="signup-adviser-screen">
      <Row className="main-container">
        <Col
          span={12}
          className="bg-container"
          style={{ backgroundImage: `url(${SideBackground})` }}
        >
          <div className="grey-layer">
            <div className="advertising">
              <label>ÚNETE A NUESTRA COMUNIDAD DE APRENDIZAJE DEL INGLÉS</label>
              <p>
                ¡Únete a nuestra plataforma y conviértete en un profesor
                destacado!
              </p>
              <p>
                Forma parte de nuestro equipo y comparte tu pasión por la
                enseñanza para hacer crecer tu audiencia y generar ingresos
                adicionales.
              </p>
            </div>
          </div>
        </Col>

        <Col span={12} className="form-container">
          <div className="signup-form">
            <h2>Conviértete en Instructor de Educom.</h2>

            <p>
              Inscríbete hoy mismo y sé parte de nuestro equipo de trabajo que
              está transformando el panorama de la enseñanza lingüística.{' '}
            </p>

            <Form
              form={formRef}
              layout={formLayout()}
              size={getSize()}
              onFinish={handleSubmit}
            >
              <Form.Item
                label="Nombre(s)"
                name="name"
                rules={[{ required: true, message: 'Nombre(s) requerido' }]}
              >
                <Input size={getSize()} placeholder="John" />
              </Form.Item>

              <Form.Item
                label="Apellido(s)"
                name="last_name"
                rules={[{ required: true, message: 'Apellido(s) requerido' }]}
              >
                <Input size={getSize()} placeholder="Doe" />
              </Form.Item>

              <Form.Item
                label="Correo"
                name="email"
                rules={[{ required: true, message: 'Correo requerido' }]}
              >
                <Input
                  type="email"
                  size={getSize()}
                  placeholder="sample@email.com"
                />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Campo requerido',
                  },
                  {
                    message:
                      'Mínimo 5 caracteres y máximo 30, contener letras y/o números, y los caracteres especiales válidos son "!@#$%=;"',
                    pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
                  },
                ]}
              >
                <Input.Password
                  size={getSize()}
                  placeholder="********"
                  onChange={({ target: { value } }) => {
                    setPassword(value);
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Confirmar Contraseña"
                name="rpassword"
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
                    validator: (_, value) => {
                      if (value !== password) {
                        return Promise.reject('Las contraseñas no coinciden');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password size={getSize()} placeholder="********" />
              </Form.Item>

              <Form.Item
                label="Indica tu costo por hora como maestro, en pesos mexicanos"
                name="cost_by_hour"
                extra="El valor que ingreses se tomará en cuenta en tu revisión y se podrá modificar más adelante."
                rules={[
                  { required: true, message: 'Costo x Sesión requerido' },
                ]}
              >
                <Input
                  type="number"
                  placeholder="100 MXN"
                  onChange={({ currentTarget: { value } }) => {
                    if (value < 1) {
                      formRef.setFieldsValue({ cost_by_hour: 1 });
                    }
                  }}
                />
              </Form.Item>

              {/* <Form.Item>
                <Checkbox
                  checked={checked}
                  onChange={({ target: { checked: value } }) => {
                    setChecked(value);
                  }}
                >
                  <p>
                    Deseo recibir correos electrónicos con sugerencias,
                    motivación, actualizaciones especiales y promociones
                    reservadas a instructores.
                  </p>
                </Checkbox>
              </Form.Item> */}

              <div className="submit-button">
                <Button
                  size={getSize()}
                  type="primary"
                  className="a-btn"
                  onClick={formRef.submit}
                  loading={loading}
                >
                  Registrate
                </Button>
              </div>

              <p>
                Al registrarte aceptas nuestros <a href="#">Términos de Uso</a>{' '}
                y <a href="#">Política de Privacidad</a>
              </p>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
