import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  Col,
  Image,
  message,
  Row,
  Spin,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ProfileForm } from '../Forms';
import { useSelector, useDispatch } from 'react-redux';
import { DELETE, FILE_SAVE, GET, UPDATE, process } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';
import { getServer } from '../../Utils/url';
import { Auth } from '../../Redux/reducers/auth';
import { useAuth } from '../../Hooks';

const { Title } = Typography;
const { TabPane } = Tabs;

export const Profile = () => {
  const [{ token }] = useAuth();
  const { user } = useSelector(state => state.auth);

  const [isEditing, setIsEditing] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [filePath, setFilePath] = useState(null);

  const url = getServer();
  const dispatch = useDispatch();

  const isClient = user?.rol === 'clients';

  const getPhotoUser = useCallback(async () => {
    setFileLoading(true);
    const photoId = user?.photo;
    if (photoId) {
      const res = await process(GET, 'multimedia', {}, { id: photoId });
      setFilePath(res?.data?.file_path);
    }
    setFileLoading(false);
    return false;
  }, []);

  useEffect(() => {
    getPhotoUser();
  }, []);

  const handleImageUpload = async file => {
    if (!file) {
      message.error('No se ha seleccionado un archivo');
      return false;
    }

    const isImage =
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg';

    if (!isImage) {
      message.error('El archivo seleccionado no es una imagen válida');
      return false;
    }

    setFileUploading(true);

    if (filePath) {
      try {
        await process(DELETE, 'multimedia', {}, { id: user.photo });
      } catch (error) {
        setFileUploading(false);
        message.error(
          'Hubo un problema cambiando su imagen de perfil: ',
          error.message
        );
      }
    }

    // eslint-disable-next-line no-undef
    let oBody = new FormData();
    oBody.append('is_public', true);
    oBody.append('files', file);

    const response = await process(FILE_SAVE, 'multimedia', oBody);

    if (response.ok) {
      const { data } = response;
      const userResponse = await process(
        UPDATE,
        'users',
        { photo: data[0]?._id },
        { id: user._id }
      );

      if (userResponse.ok) {
        message.success('Imagen de perfil actualizada correctamente');
        dispatch(
          Auth.updateUser({
            ...user,
            photo: data[0]?._id,
          })
        );
        setFilePath(data[0]?.file_path);
      } else {
        handleErrors(userResponse.data, 'Actualización de imagen');
      }
    } else {
      const { data: resData } = response;
      handleErrors(resData, 'Subida de imagen');
    }

    setFileUploading(false);
  };

  return (
    <>
      <Row className="client-profile-container">
        <Col xs={24} md={7}>
          <Card className="profile-card">
            <div className="profile-picture-container">
              {fileLoading || fileUploading ? (
                <Spin />
              ) : (
                <Image
                  src={
                    filePath
                      ? `${url}/upload?target=${filePath}&token=${token}`
                      : 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'
                  }
                  className="profile-picture"
                  preview={!fileUploading || !fileLoading}
                />
              )}
            </div>
            <div className="upload-btn">
              <Upload
                showUploadList={false}
                beforeUpload={handleImageUpload}
                accept="image/png, image/jpeg, image/jpg"
                disabled={fileLoading || fileUploading}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={fileUploading || fileLoading}
                >
                  Subir
                </Button>
              </Upload>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Typography.Text type="secondary">
                Solo se permiten imágenes de tipo PNG, JPEG o JPG.
              </Typography.Text>
              <br />
              <Typography.Text type="secondary">
                El tamaño máximo de la imagen es de 10 MB.
              </Typography.Text>
            </div>

            <Title level={4}>{user.full_name}</Title>
          </Card>
        </Col>

        <Col xs={24} md={16}>
          <Card>
            <Col span={24}>
              <Tabs activeKey="1" size="large" centered>
                <TabPane tab="Tu Información" key="1">
                  <ProfileForm
                    user={user}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                </TabPane>
                {isClient ? (
                  <TabPane tab="Constancias" key="2"></TabPane>
                ) : null}
              </Tabs>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
