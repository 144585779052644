import React from 'react';
import { ClientsInformation as ClientsInformationComponent } from '../../Components/ClientsInformation/ClientsInformation';
import { Layout } from 'antd';
import { OuterContainer } from '../../Components/Atoms/OuterContainer';
import { FooterContainer } from '../../Components/Atoms/FooterContainer';

export const ClientsInformation = () => {
  return (
    <Layout className="outer-screen top-bar full-content-with-topbar">
      <OuterContainer>
        <ClientsInformationComponent />
      </OuterContainer>
      <FooterContainer />
    </Layout>
  );
};
