import React, { useState, useEffect } from 'react';
import { Avatar, Button, Col, Drawer, List, Row, Tag } from 'antd';
import {
  LeftOutlined,
  MessageOutlined,
  SyncOutlined,
  VerticalLeftOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useFetchData } from '../../Hooks';
import { ADVISER, CLIENT } from '../../Constants/roles';
import { ChatBox } from './ChatBox';
import { isEqual, truncate } from 'lodash';
import { handleErrors } from '../../Utils/errors';
import { process, UPDATE, FIND } from '../../Service/Api';
import { RequestStatus } from '../../Constants/status';
import { request } from '../../Redux/reducers/requests';

export const DrawerChat = () => {
  const { user } = useSelector(state => state.auth);
  //*Global state to handle message updates and request status
  const { requests } = useSelector(state => state.requests);
  const dispatch = useDispatch();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  //*Control that the add request dispatch is only done once
  const [hasFetchedData, setHasFetchedData] = useState(false);

  //* List of requests that are being listed
  const [aRequests, loading, , update] = useFetchData(
    'requests',
    user.rol === ADVISER
      ? `adviser_id=${user.adviser_id}`
      : `client_id=${user.client_id}`,
    0,
    50
  );

  const isLogedIn = !!Object.keys(user).length;

  const handleToggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleSelectItem = item => {
    setSelectedItem(item); // Actualizamos el elemento seleccionado
  };

  const submitMessage = async (item, messages) => {
    let oSend = {
      $push: {
        schedule_proposal: messages,
      },
    };

    // ? When advisers confirm the request
    if (item.status === 0) {
      oSend.status = 1;
    }

    const response = await process(UPDATE, 'requests', oSend, { id: item._id });

    if (response?.ok) {
      update();
    } else {
      const { data } = response;
      handleErrors(data, 'Mensaje');
    }
  };

  useEffect(() => {
    if (aRequests?.data.length && isLogedIn && !hasFetchedData) {
      dispatch(request.addRequest(aRequests?.data));
      setHasFetchedData(true); // Marcamos que ya se ha ejecutado
    }
  }, [aRequests?.data.length, isLogedIn, hasFetchedData]);

  //Update messages
  useEffect(() => {
    if (selectedItem && requests[0]) {
      const updatedSelectedItem = requests.find(
        item => item._id === selectedItem._id && !isEqual(item, selectedItem)
      );
      if (updatedSelectedItem) {
        setSelectedItem(updatedSelectedItem);
      }
    }
  }, [requests]);

  // Survey to get regular updates
  useEffect(() => {
    if (!isLogedIn || request.length === 0) {
      return;
    }

    // eslint-disable-next-line no-undef
    const pollingInterval = setInterval(async () => {
      const response = await process(
        FIND,
        'requests',
        {},
        {
          queries: `${
            user.rol === ADVISER
              ? `adviser_id=${user.adviser_id}`
              : `client_id=${user.client_id}`
          }&$sort[created_at]=-1`,
          limit: 50,
          skip: 0,
        }
      );
      if (response.ok) {
        dispatch(request.updateRequest(response.data.data));
      }
    }, 300000);
    // * Note: Maybe this interval should be an env variable or even an user setting to be able to change it easily

    // eslint-disable-next-line no-undef
    return () => clearInterval(pollingInterval);
  }, []);

  return (
    <>
      {requests?.length > 0 ? (
        <>
          <div className="drawer-chat-container">
            <Button
              type="primary"
              shape="round"
              icon={<MessageOutlined />}
              size="large"
              onClick={handleToggleDrawer}
              style={{ height: 48, width: 48 }}
            />
          </div>
          <Drawer
            title={
              <div className="drawer-title">
                <div>
                  {selectedItem ? (
                    <Button
                      icon={<LeftOutlined />}
                      onClick={() => setSelectedItem(null)}
                      style={{ marginRight: '10px' }}
                      type="text"
                    />
                  ) : (
                    <>
                      <Button
                        icon={<VerticalLeftOutlined />}
                        onClick={() => setDrawerVisible(false)}
                        style={{ marginRight: '10px' }}
                        type="text"
                      />
                      <span>Mensajes</span>
                      &nbsp;
                      <Button type="text" onClick={update}>
                        <SyncOutlined />
                      </Button>
                    </>
                  )}
                </div>
                <div>
                  {selectedItem && (
                    <span>
                      {user.rol === CLIENT
                        ? `${selectedItem.adviser.name} ${selectedItem.adviser.last_name}`
                        : `${selectedItem.client.name} ${selectedItem.client.last_name}`}
                    </span>
                  )}
                </div>
              </div>
            }
            placement="right"
            closable={false}
            maskClosable={false}
            onClose={handleToggleDrawer}
            visible={drawerVisible}
          >
            {selectedItem ? (
              <div className="chat-box-container">
                <ChatBox
                  messages={selectedItem}
                  currentUserId={user._id}
                  onSubmit={submitMessage}
                  status={selectedItem.status}
                />
              </div>
            ) : (
              <List
                dataSource={requests}
                loading={loading}
                locale={{
                  emptyText: (
                    <span style={{ fontSize: '18px', margin: '30px' }}>
                      No hay solicitudes aún
                    </span>
                  ),
                }}
                renderItem={item => (
                  <List.Item
                    onClick={() => handleSelectItem(item)}
                    className={
                      item === selectedItem
                        ? 'drawer-selected-item'
                        : 'drawer-list-item'
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            item.user?.photo ||
                            'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'
                          }
                        />
                      }
                      title={
                        <Row justify="space-between">
                          <Col>
                            {item.adviser
                              ? `${item.adviser.name} ${item.adviser.last_name}`
                              : 'Name LastName'}
                          </Col>
                          <Col>
                            <Tag color={RequestStatus[item.status].color}>
                              {RequestStatus[item.status].label}
                            </Tag>
                          </Col>
                        </Row>
                      }
                      description={
                        /* eslint-disable indent */
                        item.schedule_proposal.length > 0
                          ? truncate(
                              item.schedule_proposal[
                                item.schedule_proposal.length - 1
                              ].message,
                              {
                                length: 50,
                              }
                            )
                          : ''
                      }
                    />
                  </List.Item>
                )}
              />
            )}
          </Drawer>
        </>
      ) : null}
    </>
  );
};
