import React from 'react';
import { Card, Divider, Typography } from 'antd';

const { Meta } = Card;
const { Title, Paragraph } = Typography;

export const Contact = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      height: '100vh',
    },
    cardContainer: {
      maxWidth: '600px',
      width: '100%',
      textAlign: 'center',
      background: 'rgba(255,255,255,0.7)',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    card: {
      width: '80%',
      margin: '0 auto',
      marginTop: '20px',
      border: 'none',
      background: 'rgba(255,255,255,0.7)',
    },
    divider: {
      marginBottom: '20px',
    },
    image: {
      maxHeight: '500px',
    },
    metaDescription: {
      marginBottom: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.cardContainer}>
        <Divider orientation="left" style={styles.divider}>
          <Title level={3}>Contacto</Title>
        </Divider>
        <Card
          style={styles.card}
          cover={
            <img
              alt="Educom"
              src="/img/fachada-educom.webp"
              style={styles.image}
            />
          }
        >
          <Meta
            title="¡Contáctanos para cualquier consulta!"
            description={`
              Dirección: Roya Del Cura 128
              Fraccionamiento Fuerte Ventura
              San Luis Potosí, capital. 78413.
            `}
            style={styles.metaDescription}
          />
          <Paragraph>Teléfono y WhatsApp: 444 208 1211</Paragraph>
          <Paragraph>Email: educomslp@gmail.com</Paragraph>
          <Paragraph>Facebook: EducomIdiomas</Paragraph>
          <Paragraph>X (Twitter): @educomslp</Paragraph>
        </Card>
      </div>
    </div>
  );
};
