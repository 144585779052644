/* eslint-disable capitalized-comments */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  CarryOutOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  ContactsOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HighlightOutlined,
  LoadingOutlined,
  StopOutlined,
  LikeOutlined,
  UnorderedListOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  List,
  message,
  Modal,
  Skeleton,
  Row,
  Tag,
  Tabs,
  Typography,
  Tooltip,
} from 'antd';
import { ADVISER, CLIENT } from '../../Constants/roles';
import {
  RequestStatus,
  RequestStatusMessage,
  AdviserRequestMessage,
  ClientRequestMessage,
} from '../../Constants/status';
import { RequestForm, RequestExamForm } from '../Forms';
import { useSelector } from 'react-redux';
import { getDaysDisplay, useFetchData } from '../../Hooks';
import { process, SAVE, UPDATE, FIND } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';
import { ChatBox } from '../Atoms/ChatBox';
import { EvaluationModal } from '../Atoms/EvaluationModal';
import { ScheduleCalendar } from '../Atoms/SheduleCalendar';
import dayjs from 'dayjs';

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Paragraph, Text, Title } = Typography;

const useQuery = () => {
  const { search } = useLocation();

  // eslint-disable-next-line no-undef
  return useMemo(() => new URLSearchParams(search));
};

const RequestActionButton = ({ item, aStatusActions }) => {
  const [statusLoading, setStatusLoading] = useState(false);

  const handleRequestStatus = async status => {
    setStatusLoading(true);

    const oSend = {
      status,
    };

    switch (status) {
      case 2:
        oSend.client_confirmation = 1;
        break;
      case 3:
        oSend.start_date = new Date();
        break;
      case 4:
        oSend.due_date = new Date();
        break;
      default:
        break;
    }

    const response = await process(UPDATE, 'requests', oSend, { id: item._id });

    if (response?.ok) {
      message.success(RequestStatusMessage[status]);

      if (typeof aStatusActions[item?.status].onOk === 'function') {
        aStatusActions[item?.status].onOk(item);
      }
    } else {
      const { data } = response;
      handleErrors(data, 'solicitudes');
    }
    setStatusLoading(false);
  };

  return (
    <Tooltip
      key={`${item.id}${Math.random()}`}
      title={aStatusActions[item?.status]?.title}
    >
      <Button
        key={aStatusActions[item?.status]?.key}
        ghost
        size="large"
        icon={
          statusLoading ? (
            <LoadingOutlined />
          ) : (
            aStatusActions[item?.status]?.icon
          )
        }
        type="primary"
        onClick={() => {
          handleRequestStatus(aStatusActions[item?.status]?.nextStatus);
        }}
      />
    </Tooltip>
  );
};

RequestActionButton.propTypes = {
  item: PropTypes.object.isRequired,
  aStatusActions: PropTypes.object.isRequired,
};

const openContactModal = (adviser, fromAction = false) => {
  Modal.info({
    centered: true,
    width: fromAction ? 400 : 600,
    title: fromAction ? 'Datos de Contacto' : 'Contactar con el Asesor',
    content: (
      <div>
        {fromAction ? null : (
          <>
            <p>
              Ahora el siguiente paso es que te pongas en contacto con él
              Asesor, aquí te dejamos sus datos de contacto:
            </p>
            <br />
          </>
        )}
        <div style={{ textAlign: fromAction ? 'left' : 'center' }}>
          <p>
            <b>Nombre(s): </b>
            <Paragraph copyable>
              {adviser
                ? `${adviser?.name} ${adviser?.last_name}`
                : 'Por definir'}
            </Paragraph>
          </p>
          <p>
            <b>No. de Teléfono(s): </b>
            <Paragraph copyable={!!adviser?.user?.phone}>
              {adviser?.user?.phone || '444 208 1211'}
            </Paragraph>
          </p>
          <p>
            <b>Email: </b>
            <Paragraph copyable>
              {adviser?.user?.email || 'educomslp@gmail.com'}
            </Paragraph>
          </p>
        </div>
        {fromAction ? null : (
          <p>
            <u>
              No te preocupes si no los anotas en este momento, puedes
              consultarlos desde este apartado de <b>Mis Solicitudes</b>.
            </u>
          </p>
        )}
      </div>
    ),
    onOk() {
      if (fromAction) {
        return;
      }
      window.location.replace('/requests');
    },
  });
};

const openGroupContactModal = (group, fromAction = false) => {
  Modal.info({
    centered: true,
    width: fromAction ? 400 : 600,
    title: fromAction
      ? 'Datos de Contacto del grupo'
      : 'Contactar con el Asesor del grupo',
    content: (
      <div>
        {fromAction ? null : (
          <>
            <p>
              Ahora el siguiente paso es que te pongas en contacto con él Asesor
              del grupo, aquí te dejamos sus datos de contacto:
            </p>
            <br />
          </>
        )}
        <div style={{ textAlign: fromAction ? 'left' : 'center' }}>
          <p>
            <b>Nombre del curso:</b>
            <Paragraph copyable>
              {group?.name ?? `${group?.language} - ${group?.level}`}
            </Paragraph>
          </p>
          <p>
            <b>Nombre del asesor(s): </b>
            <Paragraph copyable>
              {group?.adviser?.name} {group?.adviser?.last_name}
            </Paragraph>
          </p>
          <p>
            <b>No. de Teléfono(s): </b>
            <Paragraph copyable={!!group?.adviser?.user?.phone}>
              {group?.adviser?.user?.phone ||
                'Sin número de teléfono registrado'}
            </Paragraph>
          </p>
          <p>
            <b>Email: </b>
            <Paragraph copyable>{group?.adviser?.user?.email}</Paragraph>
          </p>
        </div>
        {fromAction ? null : (
          <p>
            <u>
              No te preocupes si no los anotas en este momento, puedes
              consultarlos desde este apartado de <b>Mis Solicitudes</b>.
            </u>
          </p>
        )}
      </div>
    ),
    onOk() {
      if (fromAction) {
        return;
      }
      window.location.replace('/requests');
    },
  });
};

export const RequestList = () => {
  const { user } = useSelector(state => state.auth);
  const { requests: reduxRequests } = useSelector(state => state.requests);
  const query = useQuery();
  const [selected, setSelected] = useState({});
  const [modal, setModal] = useState(false);
  const [requests, loading, , update] = useFetchData(
    'requests',
    `${
      user.rol === ADVISER
        ? `adviser_id=${user.adviser_id}`
        : `client_id=${user.client_id}`
    }&$sort[created_at]=-1`,
    0,
    50
  );

  const [groupRequests, gLoading] = useFetchData(
    'group-requests',
    `${
      user.rol === ADVISER
        ? `adviser_id=${user.adviser_id}`
        : `client_id=${user.client_id}`
    }&$sort[created_at]=-1`,
    0,
    50
  );

  const [adviser] = useFetchData(
    user.adviser_id !== undefined ? `advisers/${user.adviser_id}` : 'default'
  );

  const [request, setRequest] = useState({});
  const [aLanguages, setLanguages] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [requestExamModal, setRequestExamModal] = useState(false);
  const [chatModalVisible, setChatModalVisible] = useState(false);
  const [chatModalIndex, setChatModalIndex] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);
  const [examLoading, setExamLoading] = useState(false);
  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [evaluationModalVisible, setEvaluationVisible] = useState(false);
  const [view, setView] = useState('list');
  const isAdviser = user?.rol === ADVISER;
  const isClient = user?.rol === CLIENT;
  const key = 'updatable';
  const sId = useMemo(() => query.get('id'));
  const sType = useMemo(() => query.get('type'));

  const [renderRequests, setRenderRequests] = useState([]);

  const updateRequest = useCallback(async () => {
    if (!sId) {
      return;
    }
    const oRequest = await process(
      FIND,
      sType === 'group' ? 'group-requests' : 'requests',
      {},
      { queries: `pay_code=${sId}` }
    );

    if (oRequest?.ok) {
      const oData = oRequest.data.data[0];

      // It was already accepted
      if (oData?.status === 2) {
        window.location.replace('/requests');
        return;
      }

      const oSend = {
        validate: true,
      };

      const oResponse = await process(
        UPDATE,
        sType === 'group' ? 'group-requests' : 'requests',
        oSend,
        {
          id: oData._id ?? oData.id,
        }
      );

      if (oResponse?.ok) {
        if (sType === 'group') {
          openGroupContactModal(oData.group);
        } else {
          openContactModal(oData.adviser);
        }
      } else {
        const { data } = oResponse;
        handleErrors(
          data,
          'solicitudes',
          'Hubo un problema consultando su ultima solicitud'
        );
      }
    } else {
      const { data } = oRequest;
      handleErrors(
        data,
        'solicitudes',
        'Hubo un problema consultando su ultima solicitud'
      );
    }
  }, [sId]);

  useEffect(() => {
    updateRequest();
  }, [updateRequest]);

  useEffect(() => {
    if (!requests.data?.length && !groupRequests.data?.length) {
      return;
    }
    setLanguages(
      Array.from(
        new Set(
          requests.data
            .filter(r => r?.status === 4)
            .map(item => `${item.language}-${item.level}`)
        )
      )
    );
    let mappedRequests = requests.data.map(row => {
      return {
        ...row,
        request_type: 'advisory',
      };
    });
    let mappedGroups = groupRequests.data.map(row => {
      return {
        ...row,
        request_type: 'group',
      };
    });
    setRenderRequests(mappedRequests.concat(mappedGroups));
  }, [requests.data, groupRequests.data]);

  // const loadMore = !loading ? (
  //   requests.data?.length < requests?.total ? (
  //     <div
  //       style={{
  //         textAlign: 'center',
  //         marginTop: 12,
  //         height: 32,
  //         lineHeight: '32px',
  //       }}
  //     >
  //       <Button
  //         onClick={() => {
  //           change('', nSkip, 10);
  //           setSkip(prev => prev + 10);
  //         }}
  //       >
  //         Cargar más
  //       </Button>
  //     </div>
  //   ) : null
  // ) : null;

  const handleDescriptionToggle = index => {
    const newExpandedDescriptions = [...expandedDescriptions];
    newExpandedDescriptions[index] = !newExpandedDescriptions[index];
    setExpandedDescriptions(newExpandedDescriptions);
  };

  const openMessage = (msj, error) => {
    message.loading({ content: 'Cargando...', key });
    if (error) {
      setTimeout(() => {
        message.warning({ content: msj, key, duration: 2 });
      }, 1000);
    } else {
      setTimeout(() => {
        message.success({ content: msj, key, duration: 2 });
      }, 1000);
    }
  };

  const handleEdit = item => {
    setSelected(item);
    setModal(true);
  };

  const handleAccept = async (item, messages, schedules = []) => {
    const oSend = {
      status: 2,
      adviser_confirmation: 1,
      client_confirmation: 1,
      $push: {
        schedule_proposal: messages,
      },
    };

    if (schedules?.length > 0) {
      oSend.schedule = schedules;
    }

    const response = await process(UPDATE, 'requests', oSend, { id: item._id });

    if (response?.ok) {
      openMessage(`La Solicitud fue aceptada`, false);
      update();
    } else {
      const { data } = response;
      handleErrors(data, 'solicitudes');
    }
  };

  const handleDecline = async (item, messages) => {
    const response = await process(
      UPDATE,
      'requests',
      {
        status: 5,
        $push: {
          schedule_proposal: messages,
        },
      },
      { id: item._id }
    );

    if (response?.ok) {
      openMessage(
        isAdviser ? `La Solicitud fue rechazada` : `La Solicitud fue Cancelada`,
        false
      );
      window.location.reload();
    } else {
      const { data } = response;
      handleErrors(data, 'solicitudes');
    }
  };

  const handleOpenChatModal = (index, accepted) => {
    setChatModalIndex(index);
    setChatModalVisible(true);
    setIsAccepted(accepted);
  };

  const handleEvaluation = async (stars, comments) => {
    setEvaluationLoading(true);
    const oSend = {
      stars,
      comments,
      request_id: request.id,
    };

    const response = await process(SAVE, 'reviews', oSend);

    if (response?.ok) {
      message.success(`La evaluación fue enviada`);
      setEvaluationVisible(false);
    } else {
      const { data } = response;
      handleErrors(data, 'evaluaciones');
    }
    setEvaluationLoading(false);
  };

  const handleOpenModalEvaluation = () => {
    setEvaluationVisible(!evaluationModalVisible);
  };

  const handleRequestExam = async () => {
    setExamLoading(true);

    // TODO: Add request exam logic
    setTimeout(() => {
      setRequestExamModal(false);
      setExamLoading(false);
      message.success(
        'La solicitud fue enviada, pronto nos pondremos en contacto'
      );
    }, 1000);
  };

  const showExameConfirm = () => {
    confirm({
      title: '¿Estás seguro de solicitar un examen?',
      icon: <ExclamationCircleOutlined color="#1677FF" />,
      content:
        'Esta acción enviará una solicitud de examen a uno de nuestros administradores quienes se encargarán de contactarte para coordinar el examen.',
      okText: 'Solicitar',
      cancelText: 'Cancelar',
      okButtonProps: {
        loading: examLoading,
      },
      onOk() {
        setExamLoading(true);
        setTimeout(() => {
          setExamLoading(false);
          message.success(
            'La solicitud fue enviada, pronto nos pondremos en contacto'
          );
        }, 1000);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const aStatusActions = {
    2: {
      title: 'Indicar Inicio de Sesiones',
      key: 'list-sessions-start',
      icon: <CarryOutOutlined />,
      nextStatus: 3,
      showWhen: true,
      onOk: () => update(),
    },
    3: {
      title: 'Indicar Finalización de las Sesiones',
      key: 'list-sessions-end',
      icon: <StopOutlined />,
      nextStatus: 4,
      showWhen: true,
      onOk: item => {
        update();
        if (isClient) {
          setTimeout(() => {
            setRequest({
              id: item._id,
              adviser: `${item?.adviser?.name} ${item?.adviser?.last_name}`,
            });
            setEvaluationVisible(true);
          }, 1000);
        }
      },
    },
  };

  return (
    <>
      <RequestForm
        adviser={isAdviser ? adviser : null}
        selected={selected}
        visible={modal}
        isEditing={true}
        isEditingAdviser={isAdviser}
        onCancel={() => setModal(false)}
        onOk={() => {
          setModal(false);
          update();
        }}
      />
      <Tabs centered>
        <TabPane tab="Solicitudes de clases" key="1">
          <Row justify="center">
            <Col span={20}>
              <Row justify="start">
                <Title level={2}>
                  {view === 'list' ? `Solicitudes de Clases` : `Calendario`}
                </Title>
              </Row>
            </Col>
            <Col
              span={20}
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginBottom: '1%',
              }}
            >
              <Tooltip
                title={`Vista: ${
                  view === 'list' ? 'Calendario' : 'Solicitudes de Clases'
                }`}
              >
                <Button
                  onClick={() => setView(view === 'list' ? 'calendar' : 'list')}
                  icon={
                    view === 'list' ? (
                      <CalendarOutlined />
                    ) : (
                      <UnorderedListOutlined />
                    )
                  }
                />
              </Tooltip>
            </Col>
          </Row>
          {view === 'list' ? (
            <Row gutter={[12, 12]}>
              <Col span={20} offset={2}>
                <Card
                  style={{
                    margin: 'auto',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {loading || gLoading ? (
                    <Skeleton active />
                  ) : (
                    <List
                      pagination={{
                        pageSize: 500,
                      }}
                      itemLayout="horizontal"
                      dataSource={renderRequests}
                      // loadMore={loadMore}
                      renderItem={(item, index) => {
                        let currentRequest;
                        if (reduxRequests?.length > 0) {
                          currentRequest = reduxRequests.find(
                            req => req._id === item._id
                          );
                        }

                        return (
                          <List.Item
                            // TODO Make this list dynamic
                            actions={[
                              item.request_type === 'advisory' ? (
                                <Button
                                  key="list-loadmore-edit"
                                  ghost
                                  type="primary"
                                  size="large"
                                  disabled={currentRequest?.status !== 0}
                                  icon={<EditOutlined />}
                                  onClick={() => handleEdit(item)}
                                />
                              ) : null,
                              <Button
                                key="list-loadmore-edit"
                                size="large"
                                disabled={[4, 5].includes(
                                  currentRequest?.status
                                )}
                                icon={<ContactsOutlined />}
                                onClick={() =>
                                  item.request_type === 'group'
                                    ? openGroupContactModal(item.group)
                                    : openContactModal(item.adviser, true)
                                }
                              />,
                              <Tooltip
                                title={
                                  // eslint-disable-next-line capitalized-comments
                                  // prettier-ignore
                                  isAdviser
                                    ? AdviserRequestMessage[currentRequest?.status]
                                    : isClient
                                      ? ClientRequestMessage[currentRequest?.status]
                                      : ''
                                }
                                key={`${item.id}${Math.random()}`}
                              >
                                <Button
                                  key="list-loadmore-edit"
                                  ghost
                                  type="primary"
                                  size="large"
                                  disabled={
                                    isClient
                                      ? true
                                      : currentRequest?.status !== 0
                                  }
                                  style={{
                                    backgroundColor: '#FFF',
                                  }}
                                  icon={
                                    isClient ? (
                                      <QuestionCircleOutlined />
                                    ) : (
                                      <CheckCircleOutlined />
                                    )
                                  }
                                  onClick={() =>
                                    isAdviser
                                      ? handleOpenChatModal(index, true)
                                      : null
                                  }
                                />
                              </Tooltip>,
                              // eslint-disable-next-line capitalized-comments
                              // prettier-ignore
                              // ? Is made this way because there is an small space between buttons if its made with the common ternary operator
                              ...(aStatusActions[currentRequest?.status] !== undefined ? aStatusActions[currentRequest?.status]?.showWhen && (
                                [
                                  <RequestActionButton
                                    key={1}
                                    item={item}
                                    aStatusActions={aStatusActions}
                                  />,
                                ]
                              ) || [] : ([])),
                              <Tooltip
                                key={4}
                                title={
                                  // eslint-disable-next-line capitalized-comments
                                  // prettier-ignore
                                  isAdviser
                                    ? item?.status === 0
                                      ? 'Rechazar'
                                      : 'Cancelar'
                                    : isClient
                                      ? 'Cancelar'
                                      : ''
                                }
                              >
                                <Button
                                  key="list-loadmore-cancel"
                                  ghost
                                  danger
                                  size="large"
                                  icon={<CloseCircleOutlined />}
                                  onClick={() =>
                                    handleOpenChatModal(index, false)
                                  }
                                  disabled={[4, 5].includes(
                                    currentRequest?.status
                                  )}
                                />
                              </Tooltip>,
                              <Tooltip key={5} title={'Evaluar al Asesor'}>
                                {isClient ? (
                                  <Button
                                    key="list-loadmore-Eval"
                                    ghost
                                    type="primary"
                                    size="large"
                                    icon={<LikeOutlined />}
                                    onClick={handleOpenModalEvaluation}
                                    disabled={currentRequest?.status !== 4}
                                  />
                                ) : null}
                              </Tooltip>,
                            ]}
                          >
                            <Skeleton
                              avatar
                              title={false}
                              loading={loading}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={
                                      item.user?.photo ||
                                      'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'
                                    }
                                  />
                                }
                                title={
                                  item.request_type === 'group' ? (
                                    <>
                                      <b style={{ color: '#108EE9' }}>
                                        Curso:{' '}
                                      </b>
                                      {`${
                                        item?.group?.name ??
                                        `${item?.group?.language} ${item?.group?.level}`
                                      }`}
                                    </>
                                  ) : isClient ? (
                                    <>
                                      <b style={{ color: '#108EE9' }}>
                                        Asesor:{' '}
                                      </b>
                                      {`${item?.adviser?.name} ${item?.adviser?.last_name}`}
                                    </>
                                  ) : (
                                    <>
                                      <b style={{ color: '#108EE9' }}>
                                        Cliente:{' '}
                                      </b>
                                      {`${item?.client?.name} ${item?.client?.last_name}`}
                                    </>
                                  )
                                }
                                description={
                                  // eslint-disable-next-line capitalized-comments
                                  // prettier-ignore
                                  <Row glutter={[12, 12]}>
                                    <Col span={24} style={{ marginBottom: '12px' }}>
                                      <Text>
                                        {item.request_type !== 'group' ? (
                                          <>
                                            <u>Propuesta Inicial:</u>&nbsp;
                                            {Array.isArray(item?.schedule_proposal)
                                              ? item?.schedule_proposal[0]?.message
                                                ?.length > 70
                                                ? expandedDescriptions[index]
                                                  ? item?.schedule_proposal[0]?.message
                                                  : item?.schedule_proposal[0]?.message?.slice(
                                                    0,
                                                    65
                                                  ) + '...'
                                                : item?.schedule_proposal[0]?.message
                                              : 'No se añadio propuesta'}
                                          </>
                                        ) : (
                                          <>
                                            <u>Horario:</u>
                                            {` ${getDaysDisplay(item.group?.week_days)} de ${
                                                item.group?.start_hour
                                            } a ${item.group?.end_hour}`}
                                          </>
                                        )}
                                      </Text>
                                    </Col>
                                    <Col>
                                      <Tag color="#001529">
                                        Sesiones: {item.request_type === 'advisory' ? item.sessions : item.group?.session_total}
                                      </Tag>
                                    </Col>
                                    <Col>
                                      {item.request_type === 'advisory'
                                        ? (
                                          <Tag color="#001529">Idioma: {item.language}-{item.level}</Tag>
                                        ) : (
                                          <Tag color="#001529">Idioma: {item.group?.language}-{item.group?.level}</Tag>
                                        )}
                                    </Col>
                                    <Col>
                                      <Tag icon={<ClockCircleOutlined />}>Creada: {dayjs(item.created_at).format('DD-MMMM-YYYY')}</Tag>
                                    </Col>
                                  </Row>
                                }
                              />
                              {Array.isArray(item?.schedule_proposal) ? (
                                item?.schedule_proposal[0]?.message?.length >
                                /* eslint-disable indent */
                                70 ? (
                                  <Button
                                    key="list-loadmore-more"
                                    type="link"
                                    onClick={() =>
                                      handleDescriptionToggle(index)
                                    }
                                  >
                                    {expandedDescriptions[index]
                                      ? 'Ver menos'
                                      : 'Ver más'}
                                  </Button>
                                ) : null
                              ) : null}
                              <Tag
                                color={
                                  RequestStatus[
                                    item?.status ?? currentRequest?.status
                                  ]?.color
                                }
                              >
                                {
                                  RequestStatus[
                                    item?.status ?? currentRequest?.status
                                  ]?.label
                                }
                              </Tag>
                            </Skeleton>
                          </List.Item>
                        );
                      }}
                    />
                  )}
                </Card>
                <Modal
                  title={
                    isAccepted
                      ? 'Propuesta de la Solicitud'
                      : 'Motivo de Cancelación'
                  }
                  visible={chatModalVisible}
                  onCancel={() => setChatModalVisible(false)}
                  footer={null}
                  destroyOnClose
                >
                  {chatModalIndex !== null && (
                    <ChatBox
                      messages={requests.data[chatModalIndex]}
                      currentUserId={user._id}
                      onSubmit={isAccepted ? handleAccept : handleDecline}
                      isAccepted={isAccepted}
                      setModal={setChatModalVisible}
                      schedulesClient={requests?.data[chatModalIndex]?.schedule}
                      schedulesAdviser={adviser?.schedules}
                    />
                  )}
                </Modal>
              </Col>
              <EvaluationModal
                adviserFullName={request.adviser}
                visible={evaluationModalVisible}
                loading={evaluationLoading}
                onCancel={() => setEvaluationVisible(false)}
                onOk={handleEvaluation}
              />
            </Row>
          ) : (
            <Row justify="center">
              <Col span={20}>
                <Card
                  style={{
                    margin: 'auto',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <ScheduleCalendar />
                </Card>
              </Col>
            </Row>
          )}
        </TabPane>

        {isClient && (
          <TabPane
            tab={
              <Tooltip title="En este apartado puedes solicitar un examen para evaluar tus aptitudes en los idiomas que has estado repasando, así como tambien puedes solicitar uno personalizado">
                <span>Solicitudes de Exámenes</span>
              </Tooltip>
            }
            key="2"
          >
            <RequestExamForm
              loading={examLoading}
              visible={requestExamModal}
              handleCancel={() => setRequestExamModal(false)}
              onFinish={handleRequestExam}
            />
            <Row justify="center">
              <Col span={16}>
                <Row justify="start">
                  <Title level={2}>Solicitudes de exámenes</Title>
                </Row>
              </Col>
              <Col
                span={4}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  type="primary"
                  onClick={() => setRequestExamModal(true)}
                >
                  Examen Personalizado
                </Button>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={20} offset={2}>
                <Card
                  style={{
                    backgroundColor: '#f0f2f5',
                    margin: 'auto',
                  }}
                >
                  <List
                    dataSource={aLanguages}
                    grid={{
                      gutter: 24,
                      column: 4,
                    }}
                    renderItem={item => (
                      <List.Item>
                        <Card
                          actions={[
                            <div key="request" onClick={showExameConfirm}>
                              <HighlightOutlined /> Solicitar
                            </div>,
                          ]}
                          style={{
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          <Row justify="center">
                            <Title level={4}>{item}</Title>
                          </Row>
                        </Card>
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
          </TabPane>
        )}
      </Tabs>
    </>
  );
};
