import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import {
  CheckCircleTwoTone,
  EditOutlined,
  PlusCircleFilled,
  StopTwoTone,
} from '@ant-design/icons';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import { useFetchData, useWindowSize } from '../../Hooks';
import { generateQueries } from '../../Utils/query';
import { SearchBar } from '../Atoms/SearchBar';
import { aSearchElements, oInitState } from './GroupConstants';
import { GroupActions } from './GroupActions';
import { UPDATE, process, SAVE } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';

dayjs.extend(utc);

export const GroupTable = () => {
  const [groups, loading, change, updater] = useFetchData('groups');
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [selected, setSelected] = useState([]);
  const { width } = useWindowSize();
  const [formRef] = Form.useForm();

  const formatLocaleDate = sDate => {
    const oToday = dayjs().utc().format('YYYY-MM-DD');
    const stillUtc = moment(sDate, 'HH:mm');
    const sTime = `${oToday}T${stillUtc.format('HH:mm')}:00`;

    return dayjs(sTime).utc('z').local();
  };

  const _handleEdit = oRow => {
    setSelected(oRow);

    formRef.setFieldsValue({
      ...oRow,
      start_date: dayjs.utc(oRow.start_date),
      end_date: dayjs.utc(oRow.end_date),
      start_hour: formatLocaleDate(oRow.start_hour),
      end_hour: formatLocaleDate(oRow.end_hour),
    });
    setModal(true);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Nombre',
    },
    {
      dataIndex: 'language',
      title: 'Idioma',
    },
    {
      dataIndex: 'level',
      title: 'Nivel',
    },
    {
      dataIndex: 'start_date',
      title: 'Fecha Inicio',
      render: date => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      dataIndex: 'end_date',
      title: 'Fecha Fin',
      render: date => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      dataIndex: 'member_limit',
      title: 'Inscritos',
      render: value => `0/${value}`,
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: value => {
        if (value === 0) {
          return <Tag color="#F50">Inactivo</Tag>;
        } else {
          return <Tag color="#87D068">Activo</Tag>;
        }
      },
    },
    {
      dataIndex: 'status',
      title: 'Acciones',
      render: (s, row) => {
        return (
          <Row>
            <Col>
              <Button onClick={() => _handleEdit(row)}>
                <EditOutlined />
              </Button>
              <Tooltip placement="right" title={s ? 'Desactivar' : 'Activar'}>
                <Popconfirm
                  onConfirm={() => handleGroupStatus(row._id, s)}
                  title={`Está seguro de ${
                    s ? 'desactivar' : 'activar'
                  } este grupo?`}
                >
                  <Button>
                    {s ? (
                      <StopTwoTone twoToneColor="#FF2100" />
                    ) : (
                      <CheckCircleTwoTone twoToneColor="#52C41A" />
                    )}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const _handleSubmit = async values => {
    setModalLoading(true);
    const oSend = {
      ...values,
    };
    const isoRegex =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/;

    // Convertir de iso a el formato de 00:00
    if (isoRegex) {
      oSend.start_hour = moment.utc(oSend?.start_hour, 'HH:mm').format('HH:mm');
      oSend.end_hour = moment.utc(oSend?.end_hour, 'HH:mm').format('HH:mm');
    }

    let response;
    if (selected?._id) {
      response = await process(UPDATE, 'groups', oSend, {
        id: selected._id,
      });
    } else {
      response = await process(SAVE, 'groups', oSend);
    }

    if (response?.ok) {
      message.success('Guardado correctamente');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      handleErrors(data, 'grupos');
    }
    setModalLoading(false);
  };

  const _handleReset = () => {
    setSearch(oInitState);

    if (groups.queries === '' && !isEqual(oInitState, search)) {
      return;
    }

    change();
  };

  const _handleSearch = () => change(generateQueries(search, aSearchElements));

  const handleGroupStatus = async (sId, status) => {
    const response = await process(
      UPDATE,
      'groups',
      { status: +!status },
      { id: sId }
    );
    if (response?.ok) {
      message.success(`${status ? 'Desactivado' : 'Activado'} correctamente`);
      updater();
    } else {
      message.error(`Error al ${status ? 'desactivar' : 'activar'}`);
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3>Grupos</h3>
        </Col>
        <Col span={width < window.screen.width / 1.25 ? 6 : 2}>
          <Row justify="space-between">
            <Col>
              <Button
                icon={<PlusCircleFilled />}
                onClick={() => setModal(true)}
                type="primary"
              >
                Añadir
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <GroupActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: _handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={aSearchElements}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        isEqual={isEqual(oInitState, search)}
        {...{ search, setSearch }}
      />
      <Table
        columns={columns}
        dataSource={groups?.data}
        loading={loading}
        pagination={{
          current: groups.skip / 10 + 1,
          onChange: e => change(groups.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: groups.total,
        }}
        rowKey={row => row._id}
      />
    </div>
  );
};
