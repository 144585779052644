export const studentTypes = [
  { value: 'teachers', label: 'Para asesores' },
  { value: 'general', label: 'Para estudiantes generales' },
];

export const sessionModalityTypes = [
  { value: 'virtual', label: 'Clase virtual' },
  { value: 'insitu', label: 'Clase Presencial' },
];

export const classModalityTypes = [
  { value: 'private_class', label: 'Clases individuales' },
  { value: 'group_class', label: 'Clases grupales' },
];

export const budgetsList = [
  { value: 0, label: 'Sin presupuesto' },
  { value: 1, label: 'Menos de $100 por hora' },
  { value: 2, label: '$100 - $200 por hora' },
  { value: 3, label: '$201 - $300 por hora' },
  { value: 4, label: '$301 - $400 por hora' },
  { value: 5, label: '$401 - $500 por hora' },
  { value: 6, label: 'Más de $500 por hora' },
];
