import React, { useState } from 'react';
import { Col, Layout, Popconfirm, Row } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import SubRouter from './SubRouter';
import { DynamicMenu } from './DynamicMenu';
import { useAuth } from '../../Hooks';
import logo from '../../Assets/img/educom-logo.png';

const { Content, Header, Sider } = Layout;

export const MainContainer = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [, , logout] = useAuth();

  const toggle = () => setCollapsed(!isCollapsed);

  return (
    <>
      <Sider
        collapsible
        collapsed={isCollapsed}
        trigger={null}
        width={250}
        className="custom-sidebar"
      >
        <div className="logo">
          <img src={logo} width="100%" />
        </div>
        <DynamicMenu />
      </Sider>
      <Layout className="site-layout">
        <div className="wrapper">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Row align="middle" style={{ marginRight: '10px' }}>
              <Col span={22}>
                {isCollapsed ? (
                  <MenuUnfoldOutlined className="trigger" onClick={toggle} />
                ) : (
                  <MenuFoldOutlined className="trigger" onClick={toggle} />
                )}
              </Col>
              <Col span={2}>
                <Row align="middle">
                  <Col span={12}>
                    <Popconfirm
                      cancelText="Cancelar"
                      okText="Si"
                      onConfirm={() => logout()}
                      placement="topRight"
                      title="¿Desea cerrar sesión?"
                    >
                      <LogoutOutlined />
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
            }}
          >
            <SubRouter />
          </Content>
        </div>
      </Layout>
    </>
  );
};
