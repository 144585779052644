export const cefrLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

export const levelsSelect = [
  { value: 'A1' },
  { value: 'A2' },
  { value: 'B1' },
  { value: 'B2' },
  { value: 'C1' },
  { value: 'C2' },
];

export const languages = [
  { value: 'Ingles', label: 'Inglés' },
  { value: 'Frances', label: 'Francés' },
  { value: 'Italiano', label: 'Italiano' },
  { value: 'Aleman', label: 'Alemán' },
  { value: 'Espanol', label: 'Español' },
  { value: 'Portugues', label: 'Portugués' },
  { value: 'Chino Mandarin', label: 'Chino Mandarín' },
  { value: 'Japones', label: 'Japonés' },
  { value: 'Espanol para extranjeros', label: 'Español para extranjeros' },
];
