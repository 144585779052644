import { React, useEffect, useState } from 'react';
import { Row, Col, Typography, Modal, message, notification } from 'antd';
import { CardAdviser } from './CardAdviser';
import { CardGroup } from './CardGroup';
import { ADVISER, CLIENT } from '../../Constants/roles';
import { useSelector } from 'react-redux';
import { NeedSignIn, RequestForm } from '../Forms';
import PropTypes from 'prop-types';
import { process, SAVE } from '../../Service/Api';
import { CardAdviserGroup } from './CardAdviserGroup';

const { Title, Text } = Typography;

const genericAdviser = {
  name: '',
  last_name: '',
  location: '',
  bio: '',
  description: '',
  duration: '',
  rate: 0,
  cost_by_hour: '100.0',
  img: null,
  availability: '',
  prices: [],
  acceptReservations: '',
  experience: '',
  popularTeacher: '',
  languages: [],
  reviews: [],
};

export const CourseGroupCard = ({
  adviserData = [],
  groupData = [],
  showAdvisers = true,
  isAdvisersFirst = false,
  groupUpdater,
}) => {
  const { user } = useSelector(state => state.auth);
  const isLogedIn = !!Object.keys(user).length;
  const isAdviser = user?.rol === ADVISER;
  const isClient = user?.rol === CLIENT;
  const [modal, setModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [selectedAdviser, setSelectedAdviser] = useState(genericAdviser);
  const [packageSession, setPackageSession] = useState([]);
  const [messagePostSelect] = useState('');

  const _handleSubmit = async values => {
    const oSend = {
      client_id: user?.client_id,
      group_id: values._id,
      total_session: values.total_session,
    };
    if (!oSend?.group_id || !oSend?.client_id) {
      return message.error('Se requiere el id del grupo y el id del cliente');
    }

    const response = await process(SAVE, 'group-requests', { ...oSend });

    if (response?.ok) {
      let oMessage = {
        message: `Solicitud enviada exitosamente`,
        duration: 4,
      };

      notification.success(oMessage);

      setTimeout(() => {
        if (!response.data.pay_url) {
          return;
        }
        window.location.replace(response.data.pay_url);
      }, [2000]);

      groupUpdater();
    } else {
      message.error('Error al suscribir');
    }
  };

  const comfirmSubscription = row => {
    Modal.confirm({
      centered: true,
      title: 'Confirmar',
      content: `Está seguro de continuar con la suscripción para el grupo de ${
        row.language
      } con nivel ${row.level} ${
        row?.adviser?.length === 1
          ? 'con el instructor ' + row.adviser[0]?.name
          : ''
      } por un total de ${row.session_cost * row.total_session} MXN?`,
      // eslint-disable-next-line no-shadow
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk: () => _handleSubmit(row),
    });
  };

  const showPaymentInfo = row => {
    Modal.confirm({
      centered: true,
      title: 'Información sobre Pago',
      content: (
        <div>
          <p>
            Dado que al suscribir a un grupo, se estará reservando un espacio en
            el grupo del asesor, es requerido pagar por adelantado. Si estás de
            acuerdo, puedes continuar con el proceso.
          </p>
          <u>
            (Si surge algún problema con la asesoría, se te reembolsará el
            dinero sin ningún inconveniente)
          </u>
        </div>
      ),
      okText: 'Entendido',
      cancelText: 'Cancelar',
      onOk: () => {
        comfirmSubscription(row);
      },
    });
  };
  const showAdviserPaymentInfo = () => {
    Modal.confirm({
      centered: true,
      title: 'Información sobre Pago',
      content: (
        <div>
          <p>
            Dado que al solicitar una asesorsía, se estará reservando un espacio
            en el horario del asesor, es requerido pagar por adelantado. Si
            estás de acuerdo, puedes continuar con el proceso.
          </p>
          <u>
            (Si surge algún problema con la asesoría, se te reembolsará el
            dinero sin ningún inconveniente)
          </u>
        </div>
      ),
      okText: 'Entendido',
      cancelText: 'Cancelar',
      onOk: () => {
        setRequestModal(true);
      },
      onCancel: () => {
        setSelectedAdviser(genericAdviser);
        setPackageSession(null);
      },
    });
  };

  useEffect(() => {
    if (
      !(
        selectedAdviser === null ||
        selectedAdviser === undefined ||
        selectedAdviser === genericAdviser
      )
    ) {
      if (!isLogedIn) {
        setModal(true);
        return;
      }

      if (isAdviser) {
        // ? This might be changed later
        message.info('No puedes solicitar asesorias a otro instructor');
        return;
      }

      if (isClient) {
        if (
          !Array.isArray(selectedAdviser?.schedules) ||
          selectedAdviser?.schedules?.length === 0
        ) {
          notification.error({
            message: 'Este asesor no tiene horarios registrados',
            description:
              'No se puede continuar con la solicitud, por favor intenta con otro asesor',
          });
          return;
        }

        if (
          !Array.isArray(selectedAdviser.languages) ||
          selectedAdviser.languages?.length === 0
        ) {
          notification.error({
            message: 'Este asesor no tiene idiomas registrados',
            description:
              'No se puede continuar con la solicitud, por favor intenta con otro asesor',
          });
          return;
        }

        showAdviserPaymentInfo();
      }
    }
  }, [selectedAdviser]);

  const _handleSubscribeAdviser = (adviser, packageS) => {
    setSelectedAdviser(adviser);
    setPackageSession(packageS);
    console.log(selectedAdviser);
    console.log(packageSession);
  };

  const [isMobile, setIsMobile] = useState(false);

  // ? This useEffect is for setting the isMobile state
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return (
    <Row style={{ margin: '1rem 0 0 0' }}>
      <NeedSignIn
        adviser={selectedAdviser}
        visible={modal}
        onCancel={() => setModal(false)}
        onOk={() => {
          setModal(false);
        }}
      />
      <RequestForm
        adviser={selectedAdviser}
        visible={requestModal}
        onCancel={() => setRequestModal(false)}
        onOk={() => setRequestModal(false)}
        packages={packageSession}
        postMessage={messagePostSelect || ''}
        preSelectedSchedules={[]}
      />
      <Col span={24} order={isAdvisersFirst ? 1 : 2}>
        {showAdvisers && adviserData.length > 0 ? (
          <div className={'card-adviser'}>
            {/* eslint-disable */}
            <div style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
              <Title className={'titulo'} level={4} style={{ display: 'block' }}>
                {isAdvisersFirst
                  ? 'Asesores'
                  : 'Asesores que podrían interesarte'}
              </Title>
              <Text className={'subtitulo'}>
                Aquí te presentamos los asesores que se adaptan a tus
                necesidades o preferencias seleccionadas.
              </Text>
            </div>
            <Row
              gutter={[10, 10]}
              style={{ margin: '0.3rem' }}
              justify={isMobile ? 'center' : 'start'}
            >
              {adviserData.map(elem => (
                <Col key={elem._id} xl={8} xxl={8} md={12} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CardAdviser
                      adviserData={elem}
                      handlerSubscribe={_handleSubscribeAdviser}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            {/* eslint-enable */}
          </div>
        ) : (
          <div
            className="card-adviser"
            style={{ marginLeft: '1rem', marginBottom: '1rem' }}
          >
            <Title className={'titulo'} level={4} style={{ display: 'block' }}>
              Asesores
            </Title>
            <Text className={'subtitulo'}>
              No se encontraron registros de asesores que se ajusten a tus
              necesidades.
            </Text>
          </div>
        )}
      </Col>
      <Col span={24} order={isAdvisersFirst ? 2 : 1}>
        {groupData.length > 0 ? (
          <div className={'card-group'}>
            <div style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
              <Title className="titulo" level={4} style={{ display: 'block' }}>
                {isAdvisersFirst
                  ? 'Clases grupales que podrían interesarte'
                  : 'Clases grupales'}
              </Title>
              <Text className="subtitulo">
                Aquí te presentamos algunos de los grupos que se adaptan a tus
                necesidades o preferencias seleccionadas.
              </Text>
            </div>
            <Row
              gutter={[10, 10]}
              style={{ margin: '0.3rem' }}
              justify={isMobile ? 'center' : 'start'}
            >
              {/* eslint-disable */}
              {groupData.map(elem => (
                <Col key={elem._id} xl={8} xxl={8} md={12} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {showAdvisers ? (
                      <CardGroup
                        groupData={elem}
                        user={user}
                        handlerSubscribe={row => {
                            if (!isLogedIn) {
                              setModal(true);
                              return;
                            }
                            if (isClient) {
                              showPaymentInfo(row);
                            } else {
                              message.error('Solo los clientes pueden suscribirse');
                            }
                          }
                        }
                      />
                    ) : (
                      <CardAdviserGroup
                        groupData={elem}
                        user={user}
                        handlerSubscribe={row => {
                            if (!isLogedIn) {
                              setModal(true);
                              return;
                            }
                            if (isClient) {
                              showPaymentInfo(row);
                            } else {
                              message.error('Solo los clientes pueden suscribirse');
                            }
                          }
                        }
                      />
                    )}
                  </div>
                </Col>
              ))}
              {/* eslint-enable */}
            </Row>
          </div>
        ) : (
          <div
            className="card-group"
            style={{ marginLeft: '1rem', marginBottom: '1rem' }}
          >
            <Title className="titulo" level={4} style={{ display: 'block' }}>
              Clases grupales
            </Title>
            <Text className="subtitulo">
              No se encontraron registros de grupos que se ajusten a tus
              necesidades.
            </Text>
          </div>
        )}
      </Col>
    </Row>
  );
};

CourseGroupCard.propTypes = {
  adviserData: PropTypes.array,
  groupData: PropTypes.array,
  showAdvisers: PropTypes.bool,
  isAdvisersFirst: PropTypes.bool,
  groupUpdater: PropTypes.func,
};
