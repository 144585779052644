import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Row,
  Select,
  Button,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DatePicker } from '../Atoms/DatePicker';
import { useSelector } from 'react-redux';
import { process, SAVE, UPDATE } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';
import dayjs from 'dayjs';
import AvailabilityModal from '../AdviserDetails/AvailabilityModal';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import {
  formatSchedules,
  days,
  isScheduleSelected,
} from '../../Utils/formatShedule';

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Option } = Select;

// TODO: Add this as global styles for disabled input
const oDisabled = { color: '#000', backgroundColor: '#FFF' };

export const RequestForm = ({
  adviser,
  isEditing = false,
  isEditingAdviser = null,
  onCancel,
  onOk,
  selected,
  visible,
  packages,
  postMessage,
  preSelectedSchedules,
}) => {
  const [loading, setLoading] = useState(false);
  const [thereIsFilters, setThereIsFilters] = useState(false);
  const [aLanguages, setLanguages] = useState([]);
  const [aLevels, setLevels] = useState([]);
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState([]);

  const { user } = useSelector(state => state.auth);
  const { language, level } = useSelector(state => state.filters);
  const [formRef] = Form.useForm();

  useEffect(() => {
    if (!visible) {
      return;
    }

    // ? New request
    if (postMessage) {
      formRef.setFieldsValue({
        schedule_proposal: [{ message: postMessage }],
      });
    }

    if (preSelectedSchedules) {
      setSelectedSchedules(preSelectedSchedules);
    }

    if (!isEditing) {
      if (
        (language !== '' && language !== undefined) ||
        (level !== '' && level !== undefined)
      ) {
        setLanguages([language]);
        setLevels([level]);

        formRef.setFieldsValue({
          language: language,
          level: level,
        });

        setThereIsFilters(true);

        return;
      }

      // ? Setting values from adviser
      setLanguages(adviser.languages.map(l => l.language));
      return;
    }

    // ? Editing request
    formRef.setFieldsValue({
      ...selected,
      start_date: dayjs(selected.start_date),
      adviser_id: `${selected.adviser?.name} ${selected.adviser?.last_name}`,
      client_id: `${selected.client?.name} ${selected.client?.last_name}`,
    });

    if (!isEditingAdviser) {
      return;
    }

    setLanguages(adviser.languages.map(l => l.language));
  }, [visible]);

  const onSelectLanguage = v => {
    const oSelected = adviser.languages.find(oLang => oLang.language === v);

    formRef.setFieldsValue({
      level: null,
    });
    setLevels(oSelected.levels);
  };

  const handleSubmit = async values => {
    setLoading(true);

    const lowerCaseDays = days.map(day => day.toLowerCase());
    // Deleting title selectedSchedules
    const updatedSchedules = selectedSchedules.map(schedule => {
      // eslint-disable-next-line no-unused-vars
      const { title, ...scheduleWithoutTitle } = schedule;
      const dayNumber = lowerCaseDays.indexOf(schedule.day) + 1;

      scheduleWithoutTitle.day = dayNumber;

      return scheduleWithoutTitle;
    });
    const oSend = {
      ...values,
      adviser_id: isEditing ? selected.adviser_id : adviser._id,
      client_id: user.client_id,
      schedule: updatedSchedules,
    };

    let response;
    if (isEditing) {
      // ? Removing unnecessary fields on update
      ['schedule_proposal'].forEach(sKey => {
        delete oSend[sKey];
      });

      response = await process(UPDATE, 'requests', oSend, {
        id: selected._id,
      });
    } else {
      response = await process(SAVE, 'requests', oSend);
    }

    if (response?.ok) {
      let oMessage = {
        message: `Solicitud ${
          isEditing ? 'actualizada' : 'enviada'
        } exitosamente`,
        duration: 4,
      };

      if (!isEditing && !isEditingAdviser) {
        oMessage.description =
          'Ahora se te redirigirá al pago. Asi mismo, puedes revisar el estatus de tu solicitud desde el apartado "Mis solicitudes';
      }

      notification.success(oMessage);
      formRef.resetFields();
      onCancel();

      if (!isEditing && !isEditingAdviser) {
        setTimeout(() => {
          if (!response.data.pay_url) {
            return;
          }
          window.location.replace(response.data.pay_url);
        }, [2000]);
      }

      onOk();
    } else {
      const { data } = response;
      notification.error({
        message: 'Error',
        description: data.message,
      });
      handleErrors(data, 'solicitudes');
    }
    setLoading(false);
  };

  const handleEventEdited = info => {
    const selectedEvent = info.event;
    const startDateTime =
      dayjs(selectedEvent?.start) || dayjs(selectedEvent?.end);
    const selectedSchedule = {
      start: selectedEvent.start,
      end: selectedEvent.end,
      title: selectedEvent.title,
      day: startDateTime.format('dddd'),
    };

    if (!isScheduleSelected(selectedSchedule, selectedSchedules)) {
      // Let's make sure the status is updated before performing checks
      setSelectedSchedules(prevSelectedSchedules => {
        const newSelectedSchedules = [
          ...prevSelectedSchedules,
          selectedSchedule,
        ];
        const schedulesSelectedClient = formatSchedules(
          newSelectedSchedules,
          true
        );
        const message = formatMessage(schedulesSelectedClient);
        formRef.setFieldsValue({
          schedule_proposal: [{ message }],
        });
        return newSelectedSchedules;
      });

      return formatSchedules([selectedSchedule], true);
    } else {
      notification.warning({
        message: 'Horario Duplicado',
        description: 'Este horario ya ha sido seleccionado previamente.',
      });
    }
  };

  const formatMessage = values => {
    if (values.length === 0) {
      return '';
    }
    const message = `Me gustaría agendar mis sesiones para el siguiente horario: ${values.join(
      ', '
    )}. `;
    return message.substring(0, message.length > 255 ? 255 : message.length);
  };

  const handleRemoveSchedule = index => {
    setSelectedSchedules(prevSelectedSchedules => {
      const updatedSchedules = [...prevSelectedSchedules];
      updatedSchedules.splice(index, 1);
      const schedulesSelectedClient = formatSchedules(updatedSchedules, true);
      const message = formatMessage(schedulesSelectedClient);
      formRef.setFieldsValue({ schedule_proposal: [{ message }] });
      return updatedSchedules;
    });
  };

  return (
    <Modal
      destroyOnClose
      afterClose={() => {
        formRef.resetFields();
      }}
      title={isEditing ? 'Editar Solicitud' : 'Solicitar Asesoria'}
      centered
      open={visible}
      onCancel={onCancel}
      onOk={() => {
        const oForm = formRef.getFieldsValue(true);

        if (isEditing || isEditingAdviser) {
          formRef.submit();
        } else {
          let aPrices = [];
          let nHours = oForm.sessions;
          const aPackages = packages.data.sort((a, b) => {
            return a.hours > b.hours ? -1 : a.hours < b.hours ? 1 : 0;
          });
          for (const oPackage of aPackages) {
            if (nHours > oPackage.hours) {
              let nTimes = parseInt(nHours / oPackage.hours);
              aPrices.push({
                cost: oPackage.cost * nTimes,
                id: oPackage.price_id,
                qty: nTimes,
              });
              nHours -= nTimes * oPackage.hours;
            } else if (nHours === oPackage.hours) {
              aPrices.push({
                cost: oPackage.cost,
                id: oPackage.price_id,
                qty: 1,
              });
              nHours = 0;
            }
          }
          if (nHours > 0) {
            aPrices.push({
              cost: adviser.cost_by_hour * nHours,
              id: adviser.base_price_id,
              qty: nHours,
            });
          }
          const nTotal = aPrices.reduce((a, b) => a + b.cost, 0);
          Modal.confirm({
            centered: true,
            title: 'Confirmar',
            content: `Está seguro de continuar con la solicitud para asesoria con ${
              oForm.adviser_id
            } por un total de ${nTotal.toFixed(2)} MXN?`,
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            ),
            onOk: () => formRef.submit(),
          });
        }
      }}
      okButtonProps={{ loading }}
      width={500}
    >
      <Form form={formRef} layout="vertical" onFinish={handleSubmit}>
        <Row justify="center" gutter={{ xs: 8, sm: 16, md: 34, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              label="Cliente (Usted)"
              name="client_id"
              initialValue={user.full_name}
            >
              <Input disabled style={oDisabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Asesor"
              name="adviser_id"
              initialValue={
                !isEditing ? `${adviser.name} ${adviser.last_name}` : null
              }
            >
              <Input disabled style={oDisabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Idioma" name="language">
              <Select
                onSelect={onSelectLanguage}
                disabled={
                  (isEditingAdviser !== null && !isEditingAdviser) ||
                  aLanguages.length === 0 ||
                  thereIsFilters
                }
              >
                {aLanguages.map((value, i) => (
                  <Option key={i} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Nivel" name="level">
              <Select
                disabled={
                  (isEditingAdviser !== null && !isEditingAdviser) ||
                  aLevels.length === 0 ||
                  thereIsFilters
                }
              >
                {aLevels.map(sLevel => (
                  <Option key={sLevel} value={sLevel}>
                    {sLevel}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="No. Sesiones"
              name="sessions"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa el número de sesiones',
                },
                { type: 'number', min: 1, message: 'Mínimo 1 sesión' },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                disabled={isEditingAdviser || isEditing}
              />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              label="Fecha Inicio"
              name="start_date"
              extra={
                isEditing
                  ? null
                  : "La fecha que indiques aqui es solo 'tentativa', por lo que puede ser modificada después"
              }
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa la fecha de inicio',
                },
              ]}
            >
              <DatePicker disabled={isEditingAdviser} />
            </Form.Item>
          </Col>
          {/* TODO: pasar estilos a less */}
          <Col span={24}>
            {/* TODO: This condition should be removed when a way to edit the schedule is found */}
            {isEditing ? null : (
              <Form.Item label="Propuesta" name={'schedule'}>
                <AvailabilityModal
                  handler={setAvailabilityModal}
                  visible={availabilityModal}
                  schedules={adviser?.schedules}
                  handlerEventEdited={handleEventEdited}
                />

                {selectedSchedules && (
                  <List
                    dataSource={formatSchedules(selectedSchedules, true)}
                    renderItem={(item, i) => (
                      <List.Item
                        style={{
                          background: 'lightyellow',
                          border: '1px solid #d3d3d3',
                          borderRadius: '4px',
                          marginBottom: '8px',
                          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                          fontFamily: 'cursive',
                        }}
                      >
                        <div
                          key={i}
                          style={{
                            fontSize: '15px',
                            marginLeft: '8px',
                          }}
                          defaultChecked={true}
                        >
                          {item}
                        </div>
                        <Button
                          type="link"
                          danger
                          onClick={() => handleRemoveSchedule(i)}
                          icon={<CloseOutlined />}
                        />
                      </List.Item>
                    )}
                  />
                )}
              </Form.Item>
            )}
            <Form.Item name={['schedule_proposal', 0, 'message']}>
              <Input.TextArea
                autoSize
                count={{
                  show: true,
                  max: 255,
                }}
                placeholder="Aquí puedes incluir comentarios sobre tu disponibilidad para las sesiones (opcional se agregara un mensaje por defecto si no se agrega ninguno)"
                disabled={isEditing}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

RequestForm.propTypes = {
  adviser: PropTypes.object,
  isEditing: PropTypes.bool,
  isEditingAdviser: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  selected: PropTypes.object,
  visible: PropTypes.bool,
  packages: PropTypes.array,
  postMessage: PropTypes,
  preSelectedSchedules: PropTypes.array,
};
