import React from 'react';
import { Col, Row } from 'antd';
import { Calendar } from './Calendar';
import PropTypes from 'prop-types';
import { formatSchedulesTimes, formatEvents } from '../../Utils/formatShedule';

export const ScheduleCalendar = ({
  handlerEventEdited,
  fromAvailability,
  schedules,
}) => {
  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: '1%' }}></Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={20} offset={2}>
          <Calendar
            timeZone="UTC" // ? This prop is kinda tricky
            initialView="timeGridWeek"
            headerToolbar={{
              start: 'title',
              center: '',
              end: '',
            }}
            events={formatEvents(formatSchedulesTimes(schedules))}
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            height={500}
            handlerEventEdited={handlerEventEdited}
            fromAvailability={fromAvailability}
          />
        </Col>
      </Row>
    </div>
  );
};

ScheduleCalendar.propTypes = {
  schedules: PropTypes.array,
  handlerEventEdited: PropTypes.func,
  fromAvailability: PropTypes.bool,
};
