import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import { useAuth, useNavigation } from '../../Hooks';
import { filterRoutesByRol } from '../../Utils/menu';

export const DynamicMenu = ({ ...props }) => {
  const [{ role }] = useAuth();
  const [{ pathname }, nav] = useNavigation();

  const _getActive = () => {
    return pathname.replace('/dashboard', '') || '';
  };

  const _renderItem = ({
    childs,
    icon,
    path,
    slug,
    title,
    clientOption = false,
  }) => {
    return childs?.length === 0 ? (
      <Menu.Item
        icon={icon}
        key={slug}
        onClick={() => nav(clientOption ? path : `/dashboard${path}`)}
      >
        {title}
      </Menu.Item>
    ) : (
      <Menu.SubMenu key={slug} title={title}>
        {childs.map(oChild =>
          oChild?.childs?.length === 0 ? (
            <Menu.Item
              icon={oChild.icon}
              key={path + oChild.path}
              onClick={() => nav(`/dashboard${path}${oChild.path}`)}
              // ? Use this console.log to see the path for each item if you don't know it
              // onClick={() => console.log(`/dashboard${path}${oChild.path}`)}
            >
              {oChild.title}
            </Menu.Item>
          ) : (
            _renderItem(oChild)
          )
        )}
      </Menu.SubMenu>
    );
  };

  _renderItem.propTypes = {
    childs: PropTypes.array,
    icon: PropTypes.element,
    path: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  };

  const _renderRoutes = () => {
    const routes = filterRoutesByRol(role.rol);

    return routes.map(oRoute => _renderItem(oRoute));
  };

  return (
    <Menu
      mode="inline"
      selectedKeys={[_getActive()]}
      style={{ backgroundColor: 'transparent' }}
      theme="dark"
      {...props}
    >
      {_renderRoutes()}
    </Menu>
  );
};
