import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const days = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];

const CLASS_TYPE = 'class';

export const formatEvents = eventsList => {
  const formattedEvents = [];

  eventsList.forEach(event => {
    const startDateTime = dayjs(event.start);
    const endDateTime = dayjs(event.end);
    const interval = 60; // Duración del intervalo en minutos (1 hora)

    if (endDateTime.diff(startDateTime, 'minutes') <= interval) {
      formattedEvents.push({
        id: event.id,
        title: event.title === CLASS_TYPE ? 'Clase' : ' Examen',
        start: startDateTime.toISOString(),
        end: endDateTime.toISOString(),
        day: event.day,
      });
    } else {
      let currentStart = startDateTime;
      const originalEnd = endDateTime;
      while (currentStart.isBefore(originalEnd)) {
        let currentEnd = currentStart.add(interval, 'minutes');
        if (currentEnd.isAfter(originalEnd)) {
          currentEnd = originalEnd;
        }
        formattedEvents.push({
          id: event.id,
          title: event.title === CLASS_TYPE ? 'Clase' : ' Examen',
          start: currentStart.toISOString(),
          end: currentEnd.toISOString(),
          day: event.day,
        });
        currentStart = currentEnd;
      }
    }
  });

  return formattedEvents;
};

export const isDay = item => {
  return days.includes(item);
};

export const formatSchedulesTimes = (schedules = []) => {
  if (schedules.length === 0) {
    return [];
  }

  return schedules.map(schedule => {
    const day = schedule.day === 7 ? 0 : schedule.day;
    const currentDay = dayjs().day();
    const daysDifference = day - currentDay;
    const start =
      dayjs().add(daysDifference, 'day').format('YYYY-MM-DD') +
      dayjs(schedule.start_date).format('THH:mm:ss.SSS+00:00');
    const end =
      dayjs().add(daysDifference, 'day').format('YYYY-MM-DD') +
      dayjs(schedule.end_date).format('THH:mm:ss.SSS+00:00');

    return {
      id: schedule._id,
      title: schedule.type,
      start,
      end,
      day: schedule.day,
    };
  });
};

export const formatSchedules = (schedules, includeDayName = false) => {
  const formattedSchedules = [];
  const groupedSchedules = {};
  schedules.forEach(schedule => {
    let day = schedule.day;
    if (typeof day === 'number') {
      day = days[day - 1];
    }
    const startTime = dayjs(schedule.start || schedule.start_date)
      .utc()
      .format('h:mm A');
    const endTime = dayjs(schedule.end || schedule.end_date)
      .utc()
      .format('h:mm A');
    const scheduleText = `${startTime} a ${endTime}`;

    if (!groupedSchedules[day]) {
      groupedSchedules[day] = [];
    }

    if (includeDayName) {
      groupedSchedules[day].push(`${day} - ${scheduleText}`);
    } else {
      groupedSchedules[day].push(scheduleText);
    }
  });

  for (const day in groupedSchedules) {
    if (!includeDayName) {
      formattedSchedules.push(day);
    }
    formattedSchedules.push(...groupedSchedules[day]);
  }

  return formattedSchedules;
};

export const isScheduleSelected = (schedule, selectedSchedules) => {
  const result = selectedSchedules.some(selectedSchedule => {
    const isSameDay = selectedSchedule.day === schedule.day;
    const isSameStart = dayjs(selectedSchedule.start).isSame(
      dayjs(schedule.start),
      'minute'
    );
    const isSameEnd = dayjs(selectedSchedule.end).isSame(
      dayjs(schedule.end),
      'minute'
    );

    return isSameStart && isSameEnd && isSameDay;
  });

  return result;
};

/* eslint-disable indent */
