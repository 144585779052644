import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';
import { FIND, OPEN_FIND, process } from '../Service/Api';

const DEFAULT_PATH = 'default';

export const useFetchData = (
  model = DEFAULT_PATH,
  queries = '',
  skip = 0,
  limit = 10,
  isOpen = false,
  concept
) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, skip, limit });

  const getData = useCallback(async () => {
    if (model === DEFAULT_PATH) {
      return;
    }

    setLoading(true);
    const response = await process(
      isOpen ? OPEN_FIND : FIND,
      model,
      {},
      { ...params }
    );

    if (response?.ok) {
      setData(response.data);
    } else {
      message.error(`No se pudieron consular los ${concept || model}`);
    }

    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, skip: s, limit: l });

  const update = () => getData();

  return [{ ...data, ...params }, loading, onChangeParams, update];
};
