import { React } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Modal, Form, Row, Select } from 'antd';
import { languages, cefrLevels } from '../../Constants/levels';

const { Option } = Select;

export const RequestExamForm = ({
  loading,
  visible,
  onFinish,
  handleCancel,
}) => {
  const [formRef] = Form.useForm();

  return (
    <Modal
      afterClose={() => formRef.resetFields()}
      title="Solicitud de Examen"
      visible={visible}
      onOk={formRef.submit}
      okButtonProps={{ loading }}
      onCancel={handleCancel}
    >
      <Form
        name="requestExam"
        form={formRef}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Alert
              message="Esta acción enviará una solicitud de examen a uno de nuestros administradores quienes se encargarán de contactarte para coordinar el examen."
              type="info"
              showIcon
              closable
            />
          </Col>
          <Col span={12}>
            <Form.Item label="Idioma" name="language">
              <Select placeholder="¿Qué te interesa aprender?">
                {languages.map(({ value }, i) => (
                  <Option key={i} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nivel" name="level">
              <Select placeholder="¿Qué nivel buscas validar?">
                {cefrLevels.map(({ value }, i) => (
                  <Option key={i} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

RequestExamForm.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  onFinish: PropTypes.func,
  handleCancel: PropTypes.func,
};
