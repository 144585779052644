import React from 'react';
export const FooterContainer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-container" />
        <div className="contact-info">
          <p>Teléfono: 444 208 1211</p>
          <p>Email: educomslp@gmail.com</p>
        </div>
      </div>
    </footer>
  );
};
