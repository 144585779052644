import React from 'react';
import { Button } from 'antd';
import SuccesDraw from '../../Assets/img/success.png';
import SuccessBg from '../../Assets/img/success-background.jpg';
import { useNavigation } from '../../Hooks';

export const AdviserSuccess = () => {
  const [, nav] = useNavigation();

  return (
    <div
      className="success-screen"
      style={{ backgroundImage: `url(${SuccessBg})` }}
    >
      <div className="bg-layer">
        <div className="message-container">
          <h2>¡Felicidades, ya diste el primer paso!</h2>
          <div className="draw-container">
            <img src={SuccesDraw} alt="" className="success-draw" />
          </div>
          <p>
            Gracias por tu interés en formar parte de nuestra plataforma.
            Estamos emocionados de tenerte aquí. Solo necesitamos un poco de
            tiempo para revisar tu solicitud. Una vez que hayamos revisado tu
            solicitud, te enviaremos una respuesta por correo electrónico.
          </p>
          <br />
          <div className="signature">
            <label>Saludos</label>
            <br />
            <label>El equipo de Educom</label>
          </div>
          <Button onClick={() => nav('/')} size="large">
            Volver al Inicio
          </Button>
        </div>
      </div>
    </div>
  );
};
