import { generateQueries } from '../Utils/query';
/**
 * Generic hook to get a handler for SearchSelector component
 * @param {string} mainKey - String with the key to search
 * @param {object} extraKeys - Object with extra keys or values to search
 * @param {object} searchConfig - Object with the configuration of the search
 * @param {function} query - Function to call the api
 * @param {number} limit - Number of results to show
 */
export const useSearch = (
  mainKey,
  extraKeys = {},
  searchConfig,
  query,
  limit = 50
) => {
  const handleSearch = s => {
    if (s.length >= 3) {
      const sQueries = generateQueries(
        { [mainKey]: s, ...extraKeys },
        searchConfig
      );
      query(sQueries, 0, limit);
    } else {
      query();
    }
  };

  return handleSearch;
};
