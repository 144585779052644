/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Col, message, Rate, Row, Tag, Table, Tooltip } from 'antd';
import { HighlightOutlined, DeleteOutlined } from '@ant-design/icons';
import { ReviewAprobationModal } from './ReviewAprobationModal';
import { process, UPDATE } from '../../Service/Api';
import { useFetchData, useSearch } from '../../Hooks';
import { handleErrors } from '../../Utils/errors';
import { handleRemove } from '../../Utils/remove';
import { SearchBar } from '../Atoms/SearchBar';
import { generateQueries } from '../../Utils/query';
import { getSorter } from '../../Utils/sorters';
import { aSearchElements, oInitState } from './ReviewConstants';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';

const aAdviserSearch = [
  {
    name: 'name,last_name',
    type: 'input',
    or: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const ReviewTable = () => {
  const [selected, setSelected] = useState({});
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [reviews, loading, change, updater] = useFetchData('reviews');
  const [advisers, , queryAdvisers] = useFetchData('advisers', '', 0, 50);
  const handleSearchAdvisers = useSearch(
    aAdviserSearch[0].name,
    {},
    aAdviserSearch,
    queryAdvisers
  );

  const columns = [
    {
      dataIndex: 'adviser_id',
      title: 'Asesor',
      render: (_, row) => `${row.adviser?.name} ${row.adviser?.last_name}`,
    },
    {
      dataIndex: 'stars',
      title: 'Calificación',
      render: stars => <Rate disabled defaultValue={stars} />,
      sorter: getSorter('stars', 'number'),
    },
    {
      dataIndex: 'client_id',
      title: 'Cliente',
      render: (_, row) =>
        `${row.client?.name || 'Sin'} ${row.client?.last_name || 'Cliente'}`,
    },
    {
      dataIndex: 'date',
      title: 'Fecha',
      render: d => <Tag color="geekblue">{dayjs(d).format('DD-MM-YYYY')}</Tag>,
      sorter: getSorter('date', 'date'),
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: value => {
        const aStatus = [
          {
            color: 'error',
            text: 'Rechazada',
          },
          {
            color: 'success',
            text: 'Activa',
          },
          {
            color: 'gold',
            text: 'Pendiente de Revisión',
          },
        ];

        return <Tag color={aStatus[value].color}>{aStatus[value].text}</Tag>;
      },
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (sId, row) => {
        return (
          <Row>
            <Col>
              <Tooltip title="Revisar">
                <Button
                  onClick={() => {
                    setSelected(row);
                    setModal(true);
                  }}
                >
                  <HighlightOutlined />
                </Button>
              </Tooltip>
              {/* <Popconfirm
                onConfirm={() =>
                  handleRemove(sId, 'del', 'reviews', updater)
                }
                title="Está seguro de eliminar este Asesor?"
              >
                <Button><DeleteOutlined /></Button>
              </Popconfirm> */}
            </Col>
          </Row>
        );
      },
    },
  ];

  const _handleStatus = async (sId, status) => {
    if (Object.keys(selected).length === 0) {
      message.info('Seleccione una reseña');
      return;
    }

    const oSend = {
      status,
      stars: selected.stars,
      comments: selected.comments,
    };

    setModalLoading(true);
    const response = await process(UPDATE, 'reviews', oSend, { id: sId });
    if (response?.ok) {
      message.success(
        `Reseña ${status ? 'aprobada' : 'rechazada'} correctamente`
      );
      setModal(false);
      updater();
    } else {
      handleErrors(response, 'reseñas');
    }
    setModalLoading(false);
  };

  const _handleReset = () => {
    setSearch(oInitState);

    if (reviews.queries === '' && !isEqual(oInitState, search)) {
      return;
    }

    change();
  };

  const _handleSearch = () =>
    change(
      generateQueries(
        search,
        aSearchElements(advisers.data, handleSearchAdvisers)
      )
    );

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3>Reseñas</h3>
        </Col>
      </Row>
      <ReviewAprobationModal
        isConsulting={selected?.status !== 2}
        review={selected}
        visible={modal}
        loading={modalLoading}
        afterClose={() => setSelected({})}
        onAprove={() => _handleStatus(selected._id, 1)}
        onCancel={() => setModal(false)}
        onReject={() => _handleStatus(selected._id, 0)}
      />
      <SearchBar
        elements={aSearchElements(advisers.data, handleSearchAdvisers)}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        isEqual={isEqual(oInitState, search)}
        {...{ search, setSearch }}
      />
      <Table
        columns={columns}
        dataSource={reviews?.data}
        loading={loading}
        pagination={{
          current: reviews.skip / 10 + 1,
          onChange: e => change(reviews.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: reviews.total,
        }}
        rowKey={row => row._id}
      />
    </div>
  );
};
