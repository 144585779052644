import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import {
  DeleteOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { SearchSelector } from './SearchSelector';
import { DatePicker as DATEPicker } from './DatePicker';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export const SearchBar = ({
  elements,
  handleReset,
  handleSearch,
  isEqual,
  search,
  setSearch,
}) => {
  const aVisibles = elements.filter(({ visible = true }) => visible);
  const nFieldsSize = Math.round(16 / aVisibles.length);

  const getDateForm = ({ name, value }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleDates = aDates => {
    if (aDates) {
      setSearch({
        ...search,
        date_ini: aDates[0],
        date_end: aDates[1],
      });
    }
  };

  const handleDate = date => {
    if (date) {
      let field = elements.find(oElement => oElement.type === 'date').name;
      setSearch({
        ...search,
        [field]: date,
      });
    }
  };

  return (
    <Form layout="vertical">
      <Row
        gutter={[20, 20]}
        style={{ flexDirection: 'row-reverse', justifyContent: 'start' }}
      >
        <Col>
          <Form.Item label="&nbsp;">
            <Button
              onClick={handleSearch}
              style={{ borderStyle: 'none', boxShadow: 'none' }}
            >
              <SearchOutlined />
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="&nbsp;">
            {handleReset && (
              <Button
                onClick={handleReset}
                style={{
                  borderStyle: 'none',
                  boxShadow: 'none',
                  color: isEqual ? '#2DB7F5' : '#F05249',
                  marginLeft: 10,
                }}
              >
                {isEqual ? <SyncOutlined /> : <DeleteOutlined />}
              </Button>
            )}
          </Form.Item>
        </Col>
        {elements
          .filter(({ visible = true }) => visible)
          .reverse()
          .map(
            (
              {
                label,
                name,
                placeholder = '',
                sizes = { small: 12, normal: 0 },
                type,
                multiple = false, // ? SearchSelector
                handler = () => null, // ? SearchSelector
                cleaner = () => null, // ? SearchSelector
                values,
              },
              nIndex
            ) => (
              <Col
                key={nIndex}
                md={sizes.normal || nFieldsSize}
                sm={sizes.small || 12}
                xs={24}
              >
                <Form.Item label={label}>
                  {type === 'input' || type === 'input-fixed' ? (
                    <Input
                      name={name}
                      onChange={({ target }) => getDateForm(target)}
                      placeholder={placeholder}
                      type="text"
                      value={search[name]}
                    />
                  ) : type === 'select' ? (
                    <Select
                      name={name}
                      onChange={value => getDateForm({ name, value })}
                      placeholder={placeholder}
                      style={{ width: '100%' }}
                      value={search[name]}
                    >
                      {typeof values === 'function' && values()}
                    </Select>
                  ) : type === 'search-select' ? (
                    <SearchSelector
                      name={name}
                      mode={multiple ? 'multiple' : undefined}
                      handleSearch={debounce(handler, 300)}
                      onChange={value => getDateForm({ name, value })}
                      onSelect={cleaner}
                      placeholder={placeholder}
                      style={{ width: '100%' }}
                      value={search[name]}
                    >
                      {typeof values === 'function' && values()}
                    </SearchSelector>
                  ) : type === 'range-date' ? (
                    <RangePicker
                      format={'DD-MM-YYYY'}
                      onChange={handleDates}
                      style={{ width: '100%' }}
                      value={[search?.date_ini, search?.date_end]}
                    />
                  ) : type === 'date' ? (
                    <DATEPicker
                      format={'DD-MM-YYYY'}
                      onChange={handleDate}
                      style={{ width: '100%' }}
                      value={search?.data ? dayjs(search.date) : undefined}
                    />
                  ) : null}
                </Form.Item>
              </Col>
            )
          )}
      </Row>
    </Form>
  );
};

SearchBar.propTypes = {
  elements: PropTypes.array.isRequired,
  handleReset: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  isEqual: PropTypes.bool,
  search: PropTypes.object.isRequired,
  setSearch: PropTypes.func.isRequired,
};
