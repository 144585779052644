import React from 'react';
import { Card, Comment, List, Divider, Rate } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

export const CommentsList = ({ comments, loading, title, valuation }) => {
  return (
    <Card>
      <Divider>{title}</Divider>
      <List
        loading={loading}
        className="comment-list"
        header={`${comments.length} Reviews`}
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={item => (
          <li>
            <Comment
              author={`${item.client?.name} ${item.client?.last_name}`}
              avatar={item.client?.photo || <UserOutlined />}
              actions={
                valuation && [
                  <Rate
                    key="comment-list-rating"
                    value={item.stars}
                    disabled
                  />,
                ]
              }
              content={item.comments}
            />
          </li>
        )}
      />
    </Card>
  );
};

CommentsList.propTypes = {
  comments: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.string,
  valuation: PropTypes.bool,
};

export default CommentsList;
