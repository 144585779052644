import React from 'react';
import { Select } from 'antd';

export const aSearchElements = (
  aAdvisers = [],
  handleAdvisers = () => null
) => [
  {
    label: 'Instuctor',
    name: 'adviser_id',
    placeholder: 'Todos',
    type: 'search-select',
    handler: handleAdvisers,
    values: () => (
      <>
        {aAdvisers.map(adv => (
          <Select.Option key={adv._id} value={adv._id}>
            {adv.name} {adv.last_name}
          </Select.Option>
        ))}
      </>
    ),
  },
  {
    label: 'Calificación',
    name: 'stars',
    placeholder: 'Todos',
    type: 'select',
    values: () =>
      Array.from({ length: 5 }, (_, i) => i + 1).map((s, i) => (
        <Select.Option key={i} value={i}>
          {s} estrella{`${s > 1 ? 's' : ''}`}
        </Select.Option>
      )),
  },
  {
    label: 'Estatus',
    name: 'status',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={1}>Activa</Select.Option>
        <Select.Option value={2}>Pendiente de Revisión</Select.Option>
        <Select.Option value={0}>Cancelada</Select.Option>
      </>
    ),
  },
];

export const oInitState = {
  adviser_id: undefined,
  stars: undefined,
  status: undefined,
};
