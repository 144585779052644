import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  message,
  notification,
  List,
  Popconfirm,
  Row,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  CheckCircleTwoTone,
  EditOutlined,
  IdcardOutlined,
  PlusCircleFilled,
  StopTwoTone,
  UnorderedListOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import { AdviserCard } from '../Atoms/AdviserCard';
import { process, SAVE, UPDATE } from '../../Service/Api';
import { useFetchData, useWindowSize } from '../../Hooks';
import { useSelector } from 'react-redux';
import { AdviserActions } from './AdviserActions';
import { AdviserInfo } from './AdviserInfo';
import { handleErrors } from '../../Utils/errors';
// eslint-disable-next-line no-unused-vars
import { handleRemove } from '../../Utils/remove';
import { SearchBar } from '../Atoms/SearchBar';
import { generateQueries } from '../../Utils/query';
import { getSorter } from '../../Utils/sorters';
import { aSearchElements, oInitState } from './AdviserConstants';
import { CEFRForm } from '../Forms';
import isEqual from 'lodash/isEqual';

export const AdviserTable = () => {
  const [view, setView] = useState('table');
  const [selected, setSelected] = useState([]);
  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [levelModal, setLevelModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [aLanguages, setLanguages] = useState([]);
  const [search, setSearch] = useState(oInitState);
  const [advisers, loading, change, updater] = useFetchData('advisers');
  const { width } = useWindowSize();
  const [formRef] = Form.useForm();

  const { user } = useSelector(state => state.auth);

  const _handleStatus = async (sId, status) => {
    const response = await process(
      UPDATE,
      'advisers',
      { status: +!status },
      { id: sId }
    );
    if (response?.ok) {
      message.success(`${status ? 'Desactivado' : 'Activado'} correctamente`);
      setLevelModal(false);
      updater();
    } else {
      message.error(`Error al ${status ? 'desactivar' : 'activar'}`);
    }
  };

  const handleStatusLevel = async (sId, status, languages) => {
    // ? This validation is for the case when an adviser already has a languages, to do not open the modal and just update the status
    if (languages.length > 0 || !languages) {
      _handleStatus(sId, status);
      return;
    }

    notification.info({
      message: 'Este asesor aun no tiene lenguajes asignados',
      description: 'Por favor agregue uno para poder activarlo.',
      duration: 4,
    });
  };

  const _handleEdit = oRow => {
    setSelected(oRow);
    formRef.setFieldsValue(oRow);
    setModal(true);
  };

  const _handleLanguages = oRow => {
    setLanguages(oRow.languages);
    setSelected(oRow);
    setLevelModal(true);
  };

  const _handleInfo = oRow => {
    setSelected(oRow);
    setInfoModal(true);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Nombre(s)',
      sorter: getSorter('name'),
    },
    {
      dataIndex: 'last_name',
      title: 'Apellido(s)',
      sorter: getSorter('last_name'),
    },
    {
      dataIndex: 'cost_by_hour',
      title: 'Costo x Hr',
      sorter: getSorter('cost_by_hour', 'number'),
    },
    {
      dataIndex: 'terms_accepted',
      title: 'Términos Aceptados',
      render: terms => <Tag>{terms ? 'Sí' : 'No'}</Tag>,
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: value => {
        if (value === 0) {
          return <Tag color="#f50">Inactivo</Tag>;
        } else {
          return <Tag color="#87d068">Activo</Tag>;
        }
      },
    },
    {
      dataIndex: 'status',
      title: 'Acciones',
      render: (s, row) => {
        return (
          <Row>
            <Col>
              <Button onClick={() => _handleInfo(row)}>
                <IdcardOutlined />
              </Button>
              <Button onClick={() => _handleLanguages(row)}>
                <TranslationOutlined />
              </Button>
              <Button onClick={() => _handleEdit(row)}>
                <EditOutlined />
              </Button>
              <Tooltip placement="right" title={s ? 'Desactivar' : 'Activar'}>
                <Popconfirm
                  onConfirm={() => {
                    handleStatusLevel(row._id, s, row.languages);
                  }}
                  title={`Está seguro de ${
                    s ? 'desactivar' : 'activar'
                  } este Instructor?`}
                >
                  <Button>
                    {s ? (
                      <StopTwoTone twoToneColor="#FF2100" />
                    ) : (
                      <CheckCircleTwoTone twoToneColor="#52C41A" />
                    )}
                  </Button>
                </Popconfirm>
              </Tooltip>
              {/* <Popconfirm
                onConfirm={() =>
                  handleRemove(row._id, 'del', 'advisers', updater)
                }
                title="Está seguro de eliminar este Asesor?"
              >
                <Button><DeleteOutlined /></Button>
              </Popconfirm> */}
            </Col>
          </Row>
        );
      },
    },
  ];

  const _handleSubmit = async values => {
    setModalLoading(true);
    let response;

    if (selected?._id) {
      response = await process(UPDATE, 'advisers', values, {
        id: selected._id,
      });
    } else {
      response = await process(SAVE, 'advisers', values);
    }
    setModalLoading(false);
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      handleErrors(data, 'instructores');
    }
    setModalLoading(false);
  };

  const _handleReset = () => {
    setSearch(oInitState);

    if (advisers.queries === '' && !isEqual(oInitState, search)) {
      return;
    }

    change();
  };

  const _handleSearch = () => change(generateQueries(search, aSearchElements));

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3>Instructores</h3>
        </Col>
        {/* // * This can be improved */}
        <Col span={width < window.screen.width / 1.25 ? 6 : 2}>
          <Row justify="space-between">
            <Col>
              <Button
                onClick={() => setModal(true)}
                icon={<PlusCircleFilled />}
                type="primary"
              >
                Añadir
              </Button>
            </Col>
            <Col>
              <Tooltip
                title={`Vista: ${view === 'table' ? 'Tarjetas' : 'Tabla'}`}
              >
                <Button
                  onClick={() => setView(view === 'table' ? 'card' : 'table')}
                  icon={
                    view === 'table' ? (
                      <UnorderedListOutlined />
                    ) : (
                      <IdcardOutlined />
                    )
                  }
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <CEFRForm
        selected={selected}
        languages={aLanguages}
        visible={levelModal}
        onCancel={() => {
          setLevelModal(false);
        }}
        onFinish={() => {
          setLevelModal(false);
          updater();
        }}
      />
      <AdviserActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected: selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: _handleSubmit,
          visible: modal,
        }}
      />
      <AdviserInfo
        fn={{
          handler: setInfoModal,
          handlerSelected: setSelected,
          selected: selected,
        }}
        modal={{
          form: formRef,
          handler: setInfoModal,
          loading: modalLoading,
          visible: infoModal,
        }}
      />
      <SearchBar
        elements={aSearchElements}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        isEqual={isEqual(oInitState, search)}
        {...{ search, setSearch }}
      />
      {view === 'table' ? (
        <Table
          columns={columns}
          dataSource={advisers?.data}
          loading={loading}
          pagination={{
            current: advisers.skip / 10 + 1,
            onChange: e => change(advisers.queries, (e - 1) * 10),
            pageSizeOptions: [10],
            total: advisers.total,
          }}
          rowKey={row => row._id}
        />
      ) : (
        <List
          // TODO This can be improved
          grid={{
            gutter: width <= 1920 ? 24 : 16,
            column: width >= 1920 ? 3 : 2,
          }}
          dataSource={advisers?.data}
          loading={loading}
          renderItem={item => (
            <List.Item>
              <AdviserCard
                adviser={item}
                isAdmin={user.rol === 'admin'}
                fn={{
                  _handleEdit,
                  _handleStatus,
                }}
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};
