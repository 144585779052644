import immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const { Creators, Types } = createActions({
  filtersSet: ['language', 'level'],
  filtersClear: [],
});

const INITIAL_STATE = immutable({
  language: '',
  level: '',
});

function set(state, action) {
  let { language, level } = action;

  return state.merge({
    language,
    level,
  });
}

function clear() {
  return INITIAL_STATE;
}

const HANDLERS = {
  [Types.FILTERS_SET]: set,
  [Types.FILTERS_CLEAR]: clear,
};

export const Filters = Creators;

export const appTypes = Types;

export default createReducer(INITIAL_STATE, HANDLERS);
