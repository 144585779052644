import React from 'react';
import {
  ClockCircleOutlined,
  PercentageOutlined,
  UsergroupAddOutlined,
  BookOutlined,
} from '@ant-design/icons';
import { Card, Avatar, Button, Carousel } from 'antd';
const { Meta } = Card;
import { Link } from 'react-router-dom';

export const AboutUs = () => {
  const style = { fontSize: '40px', color: '#1890ff', margin: '20px' };
  const UniqueFactorsList = [
    {
      id: 1,
      title: 'Eliges tus horarios de trabajo',
      description: 'Trabaja cuando quieras, sin horarios fijos.',
      icon: <ClockCircleOutlined style={style} />,
    },
    {
      id: 2,
      title: 'Eliges tus cuotas',
      description: 'Establece tus propias tarifas y elige tus propios precios.',
      icon: <PercentageOutlined style={style} />,
    },
    {
      id: 3,
      title: 'Los alumnos te buscan a ti',
      description:
        'La plataforma te conecta con alumnos que buscan tus servicios.',
      icon: <UsergroupAddOutlined style={style} />,
    },
    {
      id: 4,
      title: 'Cursos para maestros',
      description:
        'Cursos de capacitación para maestros, para mejorar tus habilidades.',
      icon: <BookOutlined style={style} />,
    },
  ];

  const TeamMembersList = [
    {
      id: 1,
      name: 'Maestro Refugio Alberto Del Campo',
      position: 'Fundador',
      img: '/team/maestro-refugio.webp',
    },
    {
      id: 2,
      name: 'Virginie Ruiz',
      position: 'Maestra de Francés',
      img: '/team/virginie-ruiz.webp',
    },
    {
      id: 3,
      name: 'Gabriela Saucedo',
      position: 'Maestra de Inglés',
      img: '/team/gaby-saucedo.webp',
    },
  ];

  const styleCarousel = {
    height: '400px',
    margin: '0 auto',
    display: 'block',
  };
  return (
    <div>
      <div className="about-us-container" id="about-us">
        <div className="background-image"></div>
        <div className="content-about-us">
          <h1>
            Sobre <span style={{ color: '#3498db' }}>nosotros</span>
          </h1>
          <p>
            Educom es una escuela de enseñanza de idiomas, presencial y en línea
            que nació en 2008 en San Luis Potosí.
          </p>
          <p>
            Fue fundada por un apasionado en la enseñanza de idiomas, Refugio
            Alberto Del Campo, y a lo largo del tiempo ha trabajado con maestros
            de inglés, francés, alemán, portugués, italiano, y español para
            extranjeros, entre otros, enseñado idiomas a cientos de alumnos y a
            muchas empresas de todos tamaños, con el firme propósito de dar la
            mejor calidad en la enseñanza de idiomas como lengua extranjera.
          </p>
          <p>
            Educom puede, bajo petición, dar referencias de alumnos y empresas
            internacionales con las que ha trabajado desde hace muchos años.
          </p>
          <p>
            Estamos orgullosos del trabajo realizado por todos los que
            participan y han participado como personal administrativo y como
            maestros de idiomas, y estamos orgullosos de lo aprendido por
            nuestros alumnos, algunos han empezado desde el nivel básico o A1, y
            han decidido dar clases de idiomas al terminar sus estudios.
          </p>
        </div>
      </div>

      <div className="company-container">
        <div className="about-company">
          <h2>Acerca de la Empresa</h2>
          <p>
            Educom nace en el 2008 en San Luis Potosí, capital, como una escuela
            de enseñanza de idiomas, en un principio llegó a dar un curso de
            computación, para luego enfocarse únicamente en idiomas, hoy
            continúa siendo una escuela exclusiva de idiomas.
          </p>
          <p>
            Su nombre implicaba desde un inicio enseñanza en línea, “EDU” por
            Educación, y COM por “.com”. Sin embargo, en un principio la
            enseñanza en línea aún no era muy popular, y Educom comenzó
            enseñando físicamente en salones, y posteriormente empezó a dar
            clases en línea.
          </p>
          <p>
            Las primeras instalaciones estuvieron en la planta alta de un
            edificio de 2 pisos frente a la Facultad de Estomatología de la
            Universidad Autónoma de San Luis Potosí, en la calle de Niño
            Artillero. Posteriormente se mudó a un local en un segundo piso en
            la calle de Fray Diego de la Magdalena, casi esquina con Nereo
            Rodríguez Barragán, también en San Luis Potosí, capital.
          </p>
          <p>
            Educom se enfocó desde un inicio en dar la mejor calidad en el
            aprendizaje de idiomas, contratando y capacitando excelentes
            maestros locales, la mayoría mexicanos, y algunos extranjeros con
            estancia en México, e inclusive invitando a maestros extranjeros a
            venir a trabajar a México bajo contrato exclusivo y con permiso
            laboral de 1 año con Educom, así llegaron maestros de Estados
            Unidos, Alemania, Francia y Bélgica.
          </p>
          <p>
            Hoy en día las clases son en línea, y presenciales para el caso de
            empresas y clases particulares, pero en ubicaciones convenientes
            para el maestro y para los alumnos.
          </p>
        </div>
        <div className="company-image">
          <img
            src="/img/edc.webp"
            alt="Fundador Mtro. Refugio Alberto Del Campo"
          />
        </div>
      </div>

      <div className="fototeca">
        <h2>Fototeca</h2>
        <h3>Conoce nuestras instalaciones</h3>
        <Carousel
          autoplay
          style={{
            backgroundColor: '#57606f',
          }}
        >
          <div className="carousel-item">
            <img
              src="/img/fachada-educom.webp"
              alt='Fachada de la escuela "Educom"'
              style={styleCarousel}
            />
          </div>
          <div className="carousel-item">
            <img
              src="/img/fachada-educom-2010.webp"
              alt='Fachada de la escuela "Educom"'
              style={styleCarousel}
            />
          </div>
        </Carousel>
      </div>

      <div className="unique-factors-container">
        <div className="unique-factors">
          <h2>Trabajar en Educom</h2>
          <h3>¿Por qué trabajar con nosotros?</h3>
        </div>
        <div className="unique-factors-cards">
          {UniqueFactorsList.map(factor => (
            <Card
              key={factor.id}
              className="card-container"
              style={{ width: 300, height: 250 }}
            >
              <div style={{ textAlign: 'center' }}>{factor.icon}</div>
              <Meta title={factor.title} description={factor.description} />
            </Card>
          ))}
        </div>
      </div>

      <div className="our-team-container">
        <div className="our-team">
          <h2>Conoce nuestro equipo</h2>
          <p>
            Conoce a los maestros que forman parte de nuestro equipo y que te
            ayudarán a alcanzar tus objetivos en el aprendizaje de idiomas.
          </p>
        </div>
        <div className="team-members-container">
          <div className="team-members-row">
            {TeamMembersList.map(member => (
              <div key={member.id} className="team-member">
                <Avatar
                  size={100}
                  src={member.img}
                  style={{ backgroundColor: '#3498db', margin: 30 }}
                >
                  {member.name.charAt(0)}
                </Avatar>
                <h3>{member.name}</h3>
                <p>{member.position}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="work-section">
        <h2 className="work-title">Conoce nuestro trabajo</h2>
        <p className="work-description">
          Descubre lo que hemos logrado y cómo podemos ayudarte.
        </p>
        <Link to="/">
          <Button type="primary" size="large">
            Vamos
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AboutUs;
