import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Modal, Row } from 'antd';

export const ReviewAprobationModal = ({
  isConsulting = false,
  review,
  visible,
  loading,
  afterClose,
  onAprove,
  onCancel,
  onReject,
}) => {
  return (
    <Modal
      centered
      afterClose={afterClose}
      title={isConsulting ? 'Detalles' : 'Aprobar Reseña'}
      open={visible}
      onCancel={onCancel}
      footer={
        // eslint-disable-next-line capitalized-comments
        // prettier-ignore
        isConsulting
          ? [
            <Button key="cancel" onClick={onCancel}>
              Cerrar
            </Button>,
          ]
          : [
            <Button key="cancel" onClick={onCancel} disabled={loading}>
              Cancelar
            </Button>,
            <Button key="back" danger type="primary" onClick={onReject} disabled={loading}>
              Rechazar
            </Button>,
            <Button key="submit" type="primary" onClick={onAprove} disabled={loading}>
              Aprobar
            </Button>,
          ]
      }
    >
      <Row style={{ marginBottom: '5%' }}>
        <Col span={12}>
          <label>
            <b>Asesor:</b> {review?.adviser?.name} {review?.adviser?.last_name}
          </label>
        </Col>
        <Col span={12}>
          <label>
            <b>Cliente:</b> {review?.client?.name} {review?.client?.last_name}
          </label>
        </Col>
      </Row>

      <Input.TextArea
        disabled
        value={review?.comments}
        rows={4}
        style={{ color: '#000', backgroundColor: '#FFF' }}
      />
    </Modal>
  );
};

ReviewAprobationModal.propTypes = {
  isConsulting: PropTypes.bool,
  review: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  afterClose: PropTypes.func.isRequired,
  onAprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};
