import React, { useState } from 'react';
import {
  Avatar,
  Alert,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Col,
} from 'antd';
import { CenterCol } from '../Atoms/CenterColumn';
import { useAuth } from '../../Hooks';
import { process, OPEN_SAVE } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';
import PropTypes from 'prop-types';

export const NeedSignIn = ({ adviser, visible, onCancel, onOk }) => {
  const [isRegister, setIsRegister] = useState(true);
  const [rLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [, login, , error, resetError, loading] = useAuth(onOk);
  const [loginForm] = Form.useForm();
  const [registerForm] = Form.useForm();

  const _handleLogin = values => {
    login(values.email, values.password);
  };

  const _handleRegister = async values => {
    setLoading(true);

    const response = await process(OPEN_SAVE, 'clients', values);

    if (response?.ok) {
      notification.success({
        message:
          'Usuario creado exitosamente, revisa tu correo para activarlo e iniciar sesión y continuar con tu asesoría',
      });
      setIsRegister(false);
      onOk();
    } else {
      const { data } = response;
      handleErrors(
        data,
        'usuarios (clientes)',
        'Hubo un problema al registrarte, si esto persiste, no dudes en comunicarte con nosotros'
      );
    }
    setLoading(false);
  };

  return (
    <Modal
      afterClose={() => {
        resetError(null);
        loginForm.resetFields();
        registerForm.resetFields();
        setIsRegister(true);
      }}
      centered
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={500}
    >
      <Row justify="center">
        <CenterCol span={12}>
          <h2>{isRegister ? 'Inscríbete' : 'Conéctate'}</h2>
        </CenterCol>
        <CenterCol span={16}>
          <p>
            para contactar a {adviser?.name} {adviser?.last_name}
          </p>
        </CenterCol>
      </Row>
      <Row justify="center">
        <Avatar
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 80,
            xxl: 100,
          }}
          src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
        />
      </Row>
      <Row justify="center">
        <Col span={20} style={{ display: 'flex', justifyContent: 'center' }}>
          {isRegister ? (
            <Form
              layout="vertical"
              form={registerForm}
              onFinish={_handleRegister}
            >
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item
                    label="Nombre(s)"
                    name="name"
                    rules={[{ required: true, message: 'Nombre(s) requerido' }]}
                  >
                    <Input size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Apellido(s)"
                    name="last_name"
                    rules={[
                      { required: true, message: 'Apellido(s) requerido' },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Correo"
                    name="email"
                    rules={[{ required: true, message: 'Correo requerido' }]}
                  >
                    <Input
                      type="email"
                      placeholder="sample@email.com"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Campo requerido',
                      },
                      {
                        message:
                          'Mínimo 5 caracteres y máximo 30, contener letras y/o números, y los caracteres especiales válidos son "!@#$%=;"',
                        pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="********"
                      size="large"
                      onChange={({ target: { value } }) => {
                        setPassword(value);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Confirmar contraseña"
                    name="rpassword"
                    rules={[
                      {
                        required: true,
                        pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
                        validator: (_, value) => {
                          if (value !== password) {
                            return Promise.reject(
                              'Las contraseñas no coinciden'
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password placeholder="********" size="large" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      block
                      onClick={registerForm.submit}
                      loading={rLoading}
                    >
                      Crear Cuenta
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <Form layout="vertical" form={loginForm} onFinish={_handleLogin}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item label="Correo" name="email">
                    <Input placeholder="sample@email.com" size="large" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Contraseña" name="password">
                    <Input.Password placeholder="********" size="large" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="large"
                      type="primary"
                      block
                      loading={loading}
                      onClick={loginForm.submit}
                    >
                      Iniciar Sesión
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: '2%' }}>
        {error && (
          <Alert
            style={{ textAlign: 'center' }}
            type="error"
            message={error?.message || null}
            description={error?.description}
          />
        )}
      </Row>
      <Row justify="center">
        <a
          onClick={() => {
            setIsRegister(!isRegister);
            resetError(null);
          }}
        >
          {isRegister
            ? '¿Ya tienes una cuenta?, Inicia Sesión'
            : '¿No tienes una cuenta?, Registrate'}
        </a>
      </Row>
    </Modal>
  );
};

NeedSignIn.propTypes = {
  adviser: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};
