import React from 'react';
import { message, notification } from 'antd';

export const handleErrors = (data, model, customMsg = undefined) => {
  if (data?.code === 400) {
    let sErrors = [];
    if (Array.isArray(data.errors)) {
      for (const oError of data.errors) {
        if (oError.type === 'unique violation') {
          sErrors.push(`El valor ${oError.path} ya existe en BD`);
        }
      }
    } else {
      for (const sKey in data?.errors) {
        sErrors.push(
          `${
            data.errors[sKey] === 'Formato no valido' ? sKey.toUpperCase() : ''
          } ${data.errors[sKey]}` + '\n'
        );
      }
    }
    if (sErrors !== '') {
      notification.error({
        duration: 4,
        message: 'Errores',
        description: (
          <>
            {sErrors.length > 0 ? (
              sErrors.map((e, i) => (
                <>
                  {e}
                  <br key={i} />
                </>
              ))
            ) : (
              <>{data?.message}</>
            )}
          </>
        ),
      });
    }
  } else if (data?.code === 409) {
    message.error(`Valor duplicado ${data?.message}`);
  } else if (customMsg) {
    message.error(customMsg);
  } else {
    message.error(`Error en ${model}`);
  }
};
