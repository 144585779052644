import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Form, Input, Row, Select, Checkbox } from 'antd';
import { cefrLevels } from '../../Constants/levels';

const { Option } = Select;

export const AdviserForm = ({ formRef, isUpdating, onSubmit }) => {
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const isAllowExam = formRef.getFieldValue('allow_exam');

  useEffect(() => {
    if (checked !== isAllowExam) {
      setChecked(isAllowExam);
    }
  }, [isAllowExam]);

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="AdviserForm"
      onFinish={values => {
        onSubmit({ ...values, allow_exam: checked });
      }}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item
            label="Nombre(s)"
            name="name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Apellido(s)"
            name="last_name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Costo por Hora" name="cost_by_hour">
            <Input
              type="number"
              placeholder="MXN"
              onChange={({ currentTarget: { value } }) => {
                if (value < 1) {
                  formRef.setFieldsValue({ cost_by_hour: 1 });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Nivel"
            // eslint-disable-next-line capitalized-comments
            // rules={[{ required: true }]}
          >
            <Select>
              {cefrLevels.map(level => (
                <Option key={level} value={level} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Estatus" name="status" initialValue={1}>
            <Select>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Permitir solicitud de examen" name="allow_exam">
            <Checkbox
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Biografía" name="bio">
            <Input.TextArea />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Descripción" name="description">
            <Input.TextArea />
          </Form.Item>
        </Col>

        <Divider>Usuario</Divider>

        <Col span={8}>
          <Form.Item
            label="Correo"
            name="email"
            rules={[{ required: !isUpdating, message: 'Correo requerido' }]}
          >
            <Input type="email" placeholder="sample@email.com" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[
              {
                required: !isUpdating,
                message: 'Campo requerido',
              },
              {
                message:
                  'Mínimo 5 caracteres y máximo 30, contener letras y/o números, y los caracteres especiales válidos son "!@#$%=;"',
                pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
              },
            ]}
          >
            <Input.Password
              placeholder="********"
              onChange={({ target: { value } }) => {
                setPassword(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Confirmar Contraseña"
            name="rpassword"
            rules={[
              {
                required: !isUpdating,
                pattern: /^[a-zA-Z0-9!@#$%=;]{5,30}$/,
                validator: (_, value) => {
                  if (value !== password && !isUpdating) {
                    return Promise.reject('Las contraseñas no coinciden');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password placeholder="********" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

AdviserForm.propTypes = {
  formRef: PropTypes.object,
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
