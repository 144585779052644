import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ProtectedRoute from './ProtectedRoute';
import Login from '../App';
import {
  AdviserSignUp,
  AdviserSuccess,
  AdviserDetail,
  ClientProfile,
  Dashboard,
  ResetPassword,
  RequestScreen,
  AboutUs,
  AdviserInformation,
  ClientsInformation,
  Companies,
  Contact,
  RequestGroup,
  LayoutHomeScreen,
} from '../Screens';
import { VerifyScreen } from '../Screens/Verify';

const Router = ({ token }) => {
  const { user } = useSelector(state => state.auth);

  const allowedRoles = ['admin'];

  const getPath = () => {
    let sRoute = '/';
    if (allowedRoles.includes(user.rol)) {
      sRoute = 'dashboard';
    } else if (user.rol === 'advisers') {
      sRoute = `/advisers/details/${user.adviser_id}`;
    }

    return sRoute;
  };

  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/login"
        component={Login}
        token={token === '' ? '1' : ''}
        redirect={{
          pathname: getPath(),
          state: {
            key: getPath(),
          },
        }}
      />
      <Route exact path="/" component={LayoutHomeScreen} />
      <Route exact path="/advisers/signup" component={AdviserSignUp} />
      <Route exact path="/advisers/success" component={AdviserSuccess} />
      <Route
        exact
        path="/advisers/details/:adviser"
        component={AdviserDetail}
      />
      <Route
        exact
        path="/adviser/my_profile/:adviser"
        component={AdviserDetail}
      />

      <Route exact path="/client/my_account/" component={ClientProfile} />

      <Route
        exact
        path="/requests"
        component={RequestScreen}
        token={token === '' ? '1' : ''}
      />
      <Route path="/resetPassword" component={ResetPassword} />

      <Route path="/about_us" component={AboutUs} />

      <Route path="/adviserInformation" component={AdviserInformation} />

      <Route path="/clientInformation" component={ClientsInformation} />

      <Route path="/companies" component={Companies} />

      <Route path="/contact" component={Contact} />

      <Route path="/requestGroup" component={RequestGroup} />
      <ProtectedRoute
        component={Dashboard}
        path="/dashboard"
        token={token}
        extraConditon={allowedRoles.includes(user.rol)}
        redirect={{
          pathname: '/',
          state: {
            key: '/',
          },
        }}
      />
      <ProtectedRoute
        exact
        path="/verify/:token"
        component={VerifyScreen}
        token={token === '' ? '1' : ''}
        redirect={{
          pathname: 'dashboard',
          state: {
            key: 'dashboard',
          },
        }}
      />
    </Switch>
  );
};

const mapStateStore = state => {
  return {
    token: state.auth.token,
    location: state.router.location,
  };
};

const mapStateFunc = { navigatePush: push };

Router.propTypes = {
  location: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateStore, mapStateFunc)(Router);
