export const RequestStatus = [
  {
    label: 'Pendiente',
    color: 'gold',
  },
  {
    label: 'Confirmada por Cliente', //! cambio de posicion mientras se ajusta la api los estados
    color: 'success',
  },
  {
    label: 'Confirmada ',
    color: 'success',
  },
  {
    label: 'Sesiones Iniciadas',
    color: 'processing',
  },
  {
    label: 'Sesiones Finalizadas',
    color: 'warning',
  },
  {
    label: 'Cancelada',
    color: 'error',
  },
];

export const RequestStatusMessage = {
  2: 'Confirmo la solicitud exitosamente',
  3: 'Confirmo que las sesiones iniciaron exitosamente',
  4: 'Confirmo que las sesiones finalizaron exitosamente',
};

export const AdviserRequestMessage = [
  'Aceptar',
  'Ya aceptaste esta solicitud',
  'El cliente ya confirmo esta solicitud',
  'Las sesiones ya iniciaron',
  'Las sesiones ya finalizaron',
  'Esta solicitud fue cancelada',
];

export const ClientRequestMessage = [
  'Esta solicitud esta pendiente de confirmación',
  'El asesor ya confirmo esta solicitud',
  'Ya confirmaste esta solicitud',
  'Las sesiones ya iniciaron',
  'Las sesiones ya finalizaron',
  'Esta solicitud fue cancelada',
];
