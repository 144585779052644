import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, Typography } from 'antd';
import { ScheduleCalendar } from '../Atoms/SheduleCalendar';

const { Title } = Typography;

const AvailabilityModal = ({
  visible,
  handler,
  schedules = [],
  handlerEventEdited,
  setModalRequest,
  fromAvailability = false,
  handlerSchedules,
}) => {
  return (
    <>
      <Button
        type="ghost"
        onClick={() => handler(!visible)}
        style={{ margin: '5px' }}
      >
        {!setModalRequest ? 'Seleccionar horarios' : 'Ver Disponibilidad'}
      </Button>
      <Modal
        centered
        title="Disponibilidad del Asesor"
        open={visible}
        okText="Aceptar"
        width={schedules?.length === 0 ? 500 : 800}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => handler(!visible)}
        onCancel={() => {
          handler(!visible);
          if (handlerSchedules) {
            handlerSchedules([]);
          }
        }}
      >
        {schedules?.length === 0 ? (
          <Alert
            message="El asesor no tiene horarios disponibles o no los ha registrado."
            type="warning"
            showIcon
          />
        ) : (
          <>
            <Title level={4}>
              {!setModalRequest
                ? 'Selecciona los horarios que desees para la propuesta al Asesor'
                : 'Aqui se listan los horarios del Asesor'}
            </Title>
            <Alert
              message="Los horarios en azul son los espacios disponibles"
              type="info"
              showIcon
            />
            <ScheduleCalendar
              schedules={schedules}
              handlerEventEdited={handlerEventEdited}
              fromAvailability={fromAvailability}
            />
          </>
        )}
      </Modal>
    </>
  );
};

AvailabilityModal.propTypes = {
  visible: PropTypes.bool,
  schedules: PropTypes.array,
  handler: PropTypes.func.isRequired,
  handlerEventEdited: PropTypes.func,
  setModalRequest: PropTypes.func,
  fromAvailability: PropTypes.bool,
  handlerSchedules: PropTypes.func,
};

export default AvailabilityModal;
