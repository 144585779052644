import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { UPDATE, process } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';
import { Auth } from '../../Redux/reducers/auth';
const { Title } = Typography;

const customStyles = {
  borderBottom: '1px solid #d9d9d9',
  borderRadius: '2px',
  padding: '4px 11px',
  color: '#000',
  background: '#fff',
  width: '100%',
  height: '32px',
  lineHeight: '1.5715',
  display: 'inline-block',
  transition: 'all 0.3s',
  fontSize: '14px',
  fontWeight: '400',
  textAlign: 'left',
  userSelect: 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

// TODO: Add a few select inputs

export const ProfileForm = ({ user, isEditing, setIsEditing }) => {
  const [formRef] = Form.useForm();
  const [securityForm] = Form.useForm();
  const [userProfile, setUserProfile] = useState(user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      return;
    }

    const oUser = {
      ...user,
    };

    if (user.birthday !== null) {
      oUser.birthday = dayjs(user.birthday);
    }

    formRef.setFieldsValue(oUser);
    setUserProfile(oUser);
  }, [user]);

  const conditionalInput = (sField, component) =>
    isEditing ? (
      component ? (
        component()
      ) : (
        <Input />
      )
    ) : (
      <p style={customStyles}>{userProfile[sField] || 'Sin asignar'}</p>
    );

  const _handleSubmit = async values => {
    setLoading(true);
    const oSend = {
      ...values,
    };

    const response = await process(UPDATE, 'users', oSend, {
      id: userProfile._id,
    });

    if (response.ok) {
      formRef.resetFields();
      message.success('Datos actualizados correctamente');
      dispatch(
        Auth.updateUser({
          ...user,
          ...response.data,
        })
      );
      setIsEditing(false);
    } else {
      const { data } = response;
      handleErrors(
        data,
        'Cuenta',
        'Hubo un problema al actualizar sus datos, si esto persiste, no dudes en comunicarte con nosotros'
      );
    }
    setLoading(false);
  };

  const _handlePassword = values => {
    if (
      values.password === '' ||
      values.rpassword === '' ||
      values.password === undefined ||
      values.rpassword === undefined
    ) {
      message.error('Si desea cambiar su contraseña, debe llenar ambos campos');
      return;
    }

    if (values.password !== values.rpassword) {
      message.error('Las contraseñas no coinciden');
      return;
    }

    // TODO: Add logic to change password
    message.info(
      <>
        Caracteristica aún en desarrollo&nbsp;&nbsp; <SmileOutlined />
      </>
    );
  };

  return (
    <>
      <Row justify="space-betwewen" style={{ marginTop: '2%' }}>
        <Col span={22}>
          <div style={{ marginLeft: '3%' }}>
            <Title level={4}>Datos Generales</Title>
          </div>
        </Col>
      </Row>
      <Form
        layout="vertical"
        form={formRef}
        onFinish={_handleSubmit}
        style={{ marginTop: '2%' }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item label="Nombre Completo" name="full_name">
              {conditionalInput('full_name')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="email" name="email">
              {conditionalInput('email')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="País" name="country">
              {conditionalInput('country')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Estado" name="state">
              {conditionalInput('state')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Ciudad" name="city">
              {conditionalInput('city')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Código Postal" name="zip_code">
              {conditionalInput('zip_code')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Dirección" name="address">
              {conditionalInput('address')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Telefono" name="phone">
              {conditionalInput('phone')}
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" glutter={[12, 12]}>
          <Col>
            {isEditing ? null : (
              <Button type="primary" onClick={() => setIsEditing(true)}>
                Editar Información General
              </Button>
            )}
          </Col>
          {isEditing ? (
            <>
              <Col span={4}>
                <Button
                  type="primary"
                  danger
                  onClick={() => setIsEditing(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => formRef.submit()}
                >
                  Guardar Cambios
                </Button>
              </Col>
            </>
          ) : null}
        </Row>
      </Form>

      <Form layout="vertical" form={securityForm} onFinish={_handlePassword}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Divider orientation="left">
              <Title level={4}>Seguridad</Title>
            </Divider>
          </Col>

          <Col span={12}>
            <Form.Item label="Contraseña" name="password">
              <Input.Password />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Repetir Contraseña" name="rpassword">
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={securityForm.submit}>
              Actualizar Contraseña
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
};
