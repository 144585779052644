import React from 'react';
import { GroupTable } from '../../Components/Groups/GroupTable';

export const GroupsScreen = () => {
  return (
    <div className="adviser-screen">
      <GroupTable />
    </div>
  );
};
