import React from 'react';
import { Layout } from 'antd';
import { OuterContainer } from '../../Components/Atoms/OuterContainer';
import { FooterContainer } from '../../Components/Atoms/FooterContainer';
import { RequestList } from '../../Components/Requests/RequestList';

export const RequestScreen = () => {
  return (
    <Layout className="outer-screen adviser-detail-screen top-bar full-content-with-topbar">
      <OuterContainer>
        <RequestList />
      </OuterContainer>
      <FooterContainer />
    </Layout>
  );
};
