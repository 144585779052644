import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import {
  AdvisersScreen,
  GroupsScreen,
  ReviewsScreen,
  RolesScreen,
  UsersScreen,
} from '../../Screens';

const SubRouter = ({ match: { url }, locations }) => {
  return (
    <Switch>
      <Route
        component={AdvisersScreen}
        exact
        location={locations}
        path={`${url}/advisers`}
      />
      <Route
        component={GroupsScreen}
        exact
        location={locations}
        path={`${url}/groups`}
      />
      <Route
        component={UsersScreen}
        exact
        location={locations}
        path={`${url}/users`}
      />
      <Route
        component={ReviewsScreen}
        exact
        location={locations}
        path={`${url}/reviews`}
      />
      <Route
        component={RolesScreen}
        exact
        location={locations}
        path={`${url}/roles`}
      />
    </Switch>
  );
};

SubRouter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  locations: PropTypes.object,
};

export default withRouter(SubRouter);
