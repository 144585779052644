import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import app from './app';
import error from './error';
import filters from './filters';
import requests from './requests';

const reducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    requests,
    error,
    filters,
  });

export default reducer;
