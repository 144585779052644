// ? Documentation --> https://fullcalendar.io/docs#toc

// ! Useful info
// ? Calendar Views: https://fullcalendar.io/docs/initialView
// ? Events as an array: https://fullcalendar.io/docs/events-array
// ? Events as a function: https://fullcalendar.io/docs/events-function
// ? Date selecting: https://fullcalendar.io/docs/date-clicking-selecting
// ? Calendar Toolbar: https://fullcalendar.io/docs/headerToolbar

import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { message } from 'antd';
import PropTypes from 'prop-types';

export const Calendar = ({
  handlerEventEdited,
  fromAvailability,
  ...props
}) => {
  const selectOption = handlerEventEdited ? 'eventClick' : false;

  // TODO: Move this out of the component
  const handleEventClick = info => {
    if (handlerEventEdited) {
      const sheduleAdd = handlerEventEdited(info);
      if (sheduleAdd && !fromAvailability) {
        message.success(
          `Se ha agregado el horario ${sheduleAdd} a la propuesta`
        );
      }
    }
  };

  return (
    <>
      <FullCalendar
        locale={esLocale}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        {...props}
        select={selectOption}
        eventClick={handleEventClick}
      />
    </>
  );
};

Calendar.propTypes = {
  handlerEventEdited: PropTypes.func,
  fromAvailability: PropTypes.bool,
};
