import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Popconfirm, Row, Tag, Tooltip, Rate } from 'antd';
import {
  CheckCircleTwoTone,
  EditOutlined,
  EyeOutlined,
  SendOutlined,
  StopTwoTone,
} from '@ant-design/icons';
import {
  useAuth,
  useFetchData,
  useNavigation,
  useWindowSize,
} from '../../Hooks';
import PropTypes from 'prop-types';
import { getServer } from '../../Utils/url';
import { useSelector } from 'react-redux';

const { Meta } = Card;

const MAX_COST = 1000; // ? This value can be changed or removed

export const AdviserCard = ({
  adviser,
  isAdmin = false,
  fn = {
    _handleEdit: () => ({}),
    _handleStatus: () => ({}),
  },
}) => {
  const [, nav] = useNavigation();
  const { width } = useWindowSize();
  const [{ token }] = useAuth();
  const url = getServer();
  const { user } = useSelector(state => state.auth);
  const isLogedIn = !!Object.keys(user).length;

  const _handleDetails = sId => {
    nav(`/advisers/details/${sId}`);
  };

  const _handleEmail = () => {
    console.log('Emailing');
  };

  const [aReviews, reviewsLoading] = useFetchData(
    'reviews',
    `adviser_id=${adviser._id}&status=1`,
    undefined,
    undefined,
    !isLogedIn
  );

  const [averageStarsState, setAverageStarsState] = useState(null);

  useEffect(() => {
    if (!isNaN(averageStarsState)) {
      setAverageStarsState(averageStarsState);
    }
  }, [averageStarsState]);

  const reviews = aReviews.data;

  const { totalStarts, totalReviews } = reviews.reduce(
    (accumulator, review) => ({
      totalStarts: accumulator.totalStarts + review.stars,
      totalReviews: accumulator.totalReviews + 1,
    }),
    { totalStarts: 0, totalReviews: 0 }
  );

  const averageStarsCalculation = Math.round(totalStarts / totalReviews);

  return (
    <Card
      hoverable={!isAdmin}
      size={width >= 1920 ? 'default' : 'small'}
      onClick={() => (isAdmin ? null : _handleDetails(adviser._id))}
      cover={
        <Image
          alt="profile-picture"
          preview={false}
          src={
            adviser?.user?.photo
              ? `${url}/upload?target=${adviser.user.photo}&token=${token}`
              : 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'
          }
        />
      }
      className="search-card"
      actions={
        // eslint-disable-next-line capitalized-comments
        // prettier-ignore
        isAdmin
          ? [
            <Tooltip title="Editar" key="edit">
              <EditOutlined onClick={() => fn._handleEdit(adviser)} />
            </Tooltip>,
            <Tooltip title="Contactar" key="contact">
              <SendOutlined onClick={_handleEmail} />
            </Tooltip>,
            <Tooltip title="Preview" key="preview">
              <EyeOutlined onClick={() => _handleDetails(adviser._id)} />
            </Tooltip>,
            <Popconfirm
              key="status"
              onConfirm={() => fn._handleStatus(adviser._id, adviser.status)}
              title={`Está seguro de ${
              adviser.status ? 'desactivar' : 'activar'
            } este Instructor?`}
            >
              <Tooltip
                placement="bottom"
                title={adviser.status ? 'Desactivar' : 'Activar'}
              >
                {adviser.status ? (
                  <StopTwoTone twoToneColor="#FF2100" />
                ) : (
                  <CheckCircleTwoTone twoToneColor="#52C41A" />
                )}
              </Tooltip>
            </Popconfirm>,
          ]
          : [
            <Tag
              key={`${adviser._id}_${adviser.cost_by_hour}`}
              color={
              // eslint-disable-next-line capitalized-comments
              // prettier-ignore
                +adviser.cost_by_hour <= MAX_COST / 3
                  ? 'blue'
                  : +adviser.cost_by_hour > MAX_COST / 3 &&
                        +adviser.cost_by_hour <= MAX_COST / 1.5
                    ? 'gold'
                    : 'volcano'
              }
            >
            $ {adviser.cost_by_hour.toFixed(2)} MXN x hora
            </Tag>
          ]
      }
    >
      <div className="rating">
        {!reviewsLoading ? (
          <Rate
            disabled
            value={
              averageStarsCalculation != null ? averageStarsCalculation : 0
            }
          />
        ) : (
          <Rate disabled value={0} />
        )}
      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Meta
            title={
              <span>
                <b>{adviser.name}</b>
                <br />
                <b>{adviser.last_name}</b>
              </span>
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

AdviserCard.propTypes = {
  adviser: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  fn: PropTypes.shape({
    handleEdit: PropTypes.func,
    handleStatus: PropTypes.func,
  }),
};
