/* eslint-disable capitalized-comments */
export const generateQueries = (values, config, prefix) => {
  const aQueries = [];
  let nOrCounter = 0;

  config.forEach(({ name, or, type, whitelist }) => {
    const aFields = name.split(',');

    aFields.forEach(sField => {
      const oValue = values[name];

      if (
        type !== 'date'
          ? oValue === 0 || (oValue && oValue !== '')
          : values.date_ini !== undefined && values.date_end !== undefined
      ) {
        if (or) {
          aQueries.push(`$or[${nOrCounter}][${sField}][$regex]=${oValue}`);
          aQueries.push(`$or[${nOrCounter}][${sField}][$options]=i`);
          nOrCounter++;
        } else {
          if (type === 'input') {
            const sFieldName = prefix ? `${prefix}[${sField}]` : sField;

            // ? Adding whitelist flag to determine if $options is added to the query because some services doesn't have this option available at the moment
            aQueries.push(
              `${sFieldName}[$regex]=%${oValue}%&${sFieldName}${
                whitelist ? '[$options]=i' : ''
              }`
            );
          } else if (type === 'date') {
            // const sFieldName = prefix ? `${prefix}[date]` : 'date';

            aQueries.push(
              `${sField}[$gte]=${values['date_ini'].format(
                'YYYY-MM-DD'
              )} 00:00:00`
            );
            aQueries.push(
              `${sField}[$lte]=${values['date_end'].format(
                'YYYY-MM-DD'
              )} 23:59:59`
            );
            // if (sField.indexOf('_ini') > -1) {
            //   aQueries.push(
            //     `${sFieldName}[$gte]=${values[sField].format(
            //       'YYYY-MM-DD'
            //     )} 00:00:00`
            //   );
            // } else if (sField.indexOf('_end') > -1) {
            //   aQueries.push(
            //     `${sFieldName}[$lte]=${oValue.format('YYYY-MM-DD')} 23:59:59`
            //   );
            // }
          } else {
            const sFieldName = prefix ? `${prefix}[${sField}]` : sField;
            aQueries.push(`${sFieldName}=${oValue}`);
          }
        }
      }
    });
  });

  return aQueries.join('&');
};
