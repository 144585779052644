import React from 'react';
import { Typography, Divider, List } from 'antd';

const { Title, Text } = Typography;

export const ClientsInformation = () => {
  const data = [
    'Te unes a una escuela con más 15 años de experiencia dando clases particulares a grupos y a empresas.',
    'Eliges a tus maestros en el caso de clases privadas, de acuerdo con su perfil, costo por clase y horarios disponibles.',
    'Eliges horarios, días de clases y número de clases a tomar, siempre y cuando el maestro esté disponible.',
    'Puedes tomar clases en grupos de máximo 5 personas, a un costo muy accesible.',
    'Recibes constancia de aprendizaje.',
    'Puedes recibir una constancia de aprendizaje sin tomar clases, simplemente aprobando el examen.',
    'Puedes trabajar en Educom una vez aprobado tu examen de nivel B1.',
  ];
  const textStyle = {
    marginBottom: '10px',
    display: 'block',
  };
  return (
    <div className="clients-information" style={{ padding: '20px' }}>
      <div
        className="clients-information-header"
        style={{ textAlign: 'center' }}
      >
        <Title level={2}>Información para alumnos</Title>
      </div>
      <div className="clients-information-content">
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Idiomas</Title>
          </Divider>
          <Text>
            Educom ha impartido clases de de los siguientes idiomas, y está
            abierto siempre a trabar con nuevos: Inglés, francés, alemán,
            portugués, italiano, español para extranjeros, japonés y chino.
          </Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Ventajas de estudiar en Educom</Title>
          </Divider>
          <List
            size="large"
            header={<Text strong>Ventajas de estudiar en Educom:</Text>}
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <Text>{item}</Text>
              </List.Item>
            )}
          />
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Niveles</Title>
          </Divider>
          <Text style={textStyle}>
            Educom el avance es de acuerdo con el “Marco Común Europeo de
            Referencia para las Lenguas”, este es un estándar europeo que mide
            el nivel de una persona en un idioma y es el más usado a nivel
            mundial y en el que se basan la mayoría de los libros de idiomas.
          </Text>
          <Text style={textStyle}>Consta de A1, A2, B1, B2, C1 y C2.</Text>
          <Text style={textStyle}>
            Un nivel de conversación fluido con buenas bases gramaticales se
            puede considerar a partir de B1.
          </Text>
          <Text style={textStyle}>Ejemplo nivel A1:</Text>
          <Text style={textStyle}>
            El alumno es capaz de comprender y utilizar expresiones cotidianas
            de uso muy frecuente, así como frases sencillas destinadas a
            satisfacer necesidades de tipo inmediato. Puede presentarse a sí
            mismo y a otros, pedir y dar información personal básica sobre su
            domicilio, sus pertenencias y las personas que conoce. Puede
            relacionarse de forma elemental siempre que su interlocutor hable
            despacio y con claridad y esté dispuesto a cooperar.
          </Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Metodología</Title>
          </Divider>
          <Text type="danger" style={textStyle}>
            Las clases son por video conferencia. A menos que el alumno y el
            maestro acuerden un lugar específico para la clase, que puede ser en
            casa, oficina o en algún café. De igual forma en el caso de las
            empresas las clases pudieran acordarse en las instalaciones de esta.
          </Text>
          <Text style={textStyle}>
            En las clases privadas, cada maestro aplica su propia metodología y
            material. Para pasar un nivel, sin embargo, es necesario presentar
            el examen estandarizado que aplica Educom, y que puede o no ser
            aplicado por el mismo maestro, con el fin de respetar el estilo del
            maestro y de conservar la calidad de enseñanza al mismo tiempo. Cabe
            mencionar que al final del curso el alumno siempre evalúa al
            maestro, y estas evaluaciones son públicas, adicionalmente, Educom
            constantemente ofrece cursos de metodología para maestros.
          </Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Constancias</Title>
          </Divider>
          <Text>
            Educom emite a sus alumnos constancias firmadas con el nivel
            aprobado a cada alumno, estas constancias son una prueba real de la
            capacidad del alumno para leer, escribir, comprender y hablar el
            idioma al que se refiere.
          </Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Trabaja en Educom</Title>
          </Divider>
          <Text>
            Una vez aprobado tu nivel A1, el cual pudiste o no haber cursado en
            Educom, puedes inscribirte como maestro en la plataforma. Educom
            siempre entrevista a los maestros que se inscriben en su plataforma
            y avala su nivel.
          </Text>
        </div>
      </div>
    </div>
  );
};
