import { React, useState } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
const { Text } = Typography;

export const ExpandableText = ({ text, limit, className = '' }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const getShortText = (aTextInto, aLimit) => {
    if (aTextInto.length > aLimit) {
      return aTextInto.slice(0, aLimit) + '...';
    } else {
      return aTextInto;
    }
  };

  return (
    <div style={{ maxHeight: expanded ? '100px' : 'none', overflowY: 'auto' }}>
      <Text className={className}>
        {expanded ? text : getShortText(text, limit)}
        {text.length > limit && (
          <Text
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={toggleExpansion}
          >
            {expanded ? ' Ver menos' : ' Ver más'}
          </Text>
        )}
      </Text>
    </div>
  );
};

ExpandableText.propTypes = {
  text: PropTypes.string.isRequired,
  limit: PropTypes.number,
  className: PropTypes.string,
};
