import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

// ? Reference for ConfigProvider --> https://codesandbox.io/s/wrong-locale-n7fyy?file=/index.
import { ConfigProvider } from 'antd/es';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { history, persistor, store } from './Redux/store';
import Router from './Components/Router';
import * as serviceWorker from './serviceWorker';

import './Styles/index.less';

dayjs.locale('es');

ReactDOM.render(
  <ConfigProvider locale={esES}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
