import React, { useMemo, useState } from 'react';
import { Button, Col, Form, message, Popconfirm, Row, Table, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useFetchUsers } from '../../Hooks/User.hook';
import { useFetchRoles } from '../../Hooks/Role.hook';
import { UserActions } from './UserActions';
import { process, SAVE, UPDATE } from '../../Service/Api';
import { SearchBar } from '../Atoms/SearchBar';
import { generateQueries } from '../../Utils/query';
import { getSorter } from '../../Utils/sorters';
import { handleErrors } from '../../Utils/errors';
import { aSearchElements, oInitState } from './UserConstants';
import isEqual from 'lodash/isEqual';

export const UserTable = () => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [users, loading, change, updater] = useFetchUsers();
  const [roles] = useFetchRoles();
  const fnSearchElements = useMemo(() => {
    return aSearchElements(roles?.data);
  });
  const [formRef] = Form.useForm();

  const _handleDeactivate = async sId => {
    const response = await process(UPDATE, 'users', { status: 0 }, { id: sId });
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  const columns = [
    {
      dataIndex: 'full_name',
      title: 'Nombre',
      sorter: getSorter('full_name'),
    },
    {
      dataIndex: 'email',
      title: 'Email',
      sorter: getSorter('email'),
    },
    {
      dataIndex: ['role', 'name'],
      title: 'Rol',
      sorter: (a, b) => a.role?.name.localeCompare(b.role?.name),
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            <Col>
              <Button
                onClick={() => {
                  setSelected(row);
                  formRef.setFieldsValue(row);
                  setModal(true);
                }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                onConfirm={() => _handleDeactivate(row._id)}
                title="Está seguro de desactivar este usuario?"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const _handleReset = () => {
    setSearch(oInitState);

    if (users.queries === '' && !isEqual(oInitState, search)) {
      return;
    }

    change();
  };

  const _handleSearch = () => change(generateQueries(search, fnSearchElements));

  const _handleSubmit = async values => {
    setModalLoading(true);
    let response;
    if (selected?._id) {
      response = await process(UPDATE, 'users', values, {
        id: selected._id,
      });
    } else {
      let oSend = { ...values };
      response = await process(SAVE, 'users', oSend);
    }
    setModalLoading(false);
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      handleErrors(data, 'usuarios');
    }
    setModalLoading(false);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3>Usuarios</h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Crear
          </Button>
        </Col>
      </Row>
      <UserActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected: selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: _handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={fnSearchElements}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        isEqual={isEqual(oInitState, search)}
        {...{ search, setSearch }}
      />
      <Table
        columns={columns}
        dataSource={users?.data}
        loading={loading}
        pagination={{
          current: users.skip / 10 + 1,
          onChange: e => change(users.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: users.total,
        }}
        rowKey={row => row._id}
      />
    </div>
  );
};
