import { useState } from 'react';
import { persistor } from '../Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from '../Redux/reducers/auth';
import { App } from '../Redux/reducers/app';
import { request } from '../Redux/reducers/requests';
import { login } from '../Service/Api';

export const useAuth = (extraHandler = undefined) => {
  const { auth } = useSelector(state => ({ auth: state.auth }));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const post = async (email, password) => {
    setLoading(true);
    dispatch(Auth.authRequest());
    let response = await login(email, password);

    setLoading(false);
    if (response?.ok) {
      let { accessToken, user } = response.data;
      dispatch(
        Auth.authSuccess(accessToken, user, {
          rol: user.rol,
          rol_id: user.rol_id,
          rol_name: user.rol_name,
        })
      );

      if (extraHandler !== undefined && typeof extraHandler === 'function') {
        extraHandler();
      }
    } else {
      let oMessage = {};

      switch (response?.status) {
        case 401:
          oMessage['description'] = 'Credenciales invalidas! Favor de revisar';
          break;
        case 403:
          oMessage['message'] = 'Usuario no veritificado!';
          oMessage['description'] =
            'Favor de revisar su correo para activarlo e iniciar sesión. Si no lo encuentra en su bandeja de entrada, por favor revise su carpeta de spam o correo no deseado.';
          break;
        default:
          oMessage['message'] = 'Ocurrió un error inesperado! ';
          oMessage['description'] = 'Favor de contactar con administración';
          break;
      }

      dispatch(Auth.authFailure());
      setError(oMessage);
    }
  };

  const logout = () => {
    dispatch(Auth.authClear());
    dispatch(App.appClear());
    dispatch(request.clearRequests());
    persistor.flush();
    persistor.purge();
  };

  return [auth, post, logout, error, setError, loading];
};
