import React from 'react';
import {
  ApartmentOutlined,
  CommentOutlined,
  HomeOutlined,
  LayoutOutlined,
  UserOutlined,
} from '@ant-design/icons';

// ! Note: Each item or subitem must have a childs array, even if it is empty
// ? (Optional) --> clientOption: true, is used to indicate the menu to do not redirect to other path inside the dashboard (subrouter)
export const Routes = [
  {
    title: 'Client Side',
    slug: 'client',
    path: '/',
    icon: <LayoutOutlined className="anticon" />,
    roles: ['admin'],
    childs: [],
    optionalIcon: true,
    clientOption: true,
  },
  {
    title: 'Home',
    slug: 'home',
    path: '/',
    icon: <HomeOutlined className="anticon" />,
    roles: ['admin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Instructores',
    slug: 'advisers',
    path: '/advisers',
    icon: <ApartmentOutlined className="anticon" />,
    roles: ['admin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Grupos',
    slug: 'groups',
    path: '/groups',
    icon: <ApartmentOutlined className="anticon" />,
    roles: ['admin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Reseñas',
    slug: 'reviews',
    path: '/reviews',
    icon: <CommentOutlined className="anticon" />,
    roles: ['admin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Roles',
    slug: 'roles',
    path: '/roles',
    icon: <ApartmentOutlined className="anticon" />,
    roles: ['admin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Users',
    slug: 'users',
    path: '/users',
    icon: <UserOutlined className="anticon" />,
    roles: ['admin'],
    childs: [],
    optionalIcon: true,
  },
];
