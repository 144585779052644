import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Form,
  Input,
  message,
  Modal,
  notification,
  Rate,
  Row,
  Spin,
  Tag,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import { EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { handleErrors } from '../../Utils/errors';
import { NeedSignIn, RequestForm } from '../Forms';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth, useFetchData } from '../../Hooks';
import { CommentsList } from '../Atoms/Comments';
import { ADVISER, CLIENT } from '../../Constants/roles';
import banner from '../../Assets/img/bannerDetails.jpg';
import MultipleModal from './MultipleModal';
import {
  process,
  GET,
  OPEN_GET,
  UPDATE,
  DELETE,
  FILE_SAVE,
} from '../../Service/Api';
import AvailabilityModal from './AvailabilityModal';
import { formatSchedules, isScheduleSelected } from '../../Utils/formatShedule';
import dayjs from 'dayjs';
import { getServer } from '../../Utils/url';
import { Auth } from '../../Redux/reducers/auth';

const { Meta } = Card;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

const NOT_AVAILABLE = 'No disponible';

const genericAdviser = {
  name: '',
  last_name: '',
  location: '',
  bio: '',
  description: '',
  duration: '',
  rate: 0,
  cost_by_hour: '100.0',
  img: null,
  availability: '',
  prices: [],
  acceptReservations: '',
  experience: '',
  popularTeacher: '',
  languages: [],
  reviews: [],
};

const openNotificationWithIcon = type => {
  notification[type]({
    message: 'No hay horarios registrados',
    duration: 4,
    description: 'Por favor, registre horarios.',
  });
};

export const AdviserDetails = () => {
  const { adviser: aId } = useParams();
  const [{ token }] = useAuth();
  const dispatch = useDispatch();

  // ? Sign in/up modal
  const [modal, setModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  // ? Modal handling packages and schedules
  const [multipleModal, setMultiModal] = useState(false);
  const [availabilityModal, setAvailabilityModal] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [messagePostSelect, setMessagePostSelect] = useState('');

  const [oAdviser, setAdviser] = useState(genericAdviser);
  const [activeTab, setActiveTab] = useState('about-me');
  const url = getServer();

  const [form] = Form.useForm();
  const { user } = useSelector(state => state.auth);

  const isLogedIn = !!Object.keys(user).length;
  const isAdviser = user?.rol === ADVISER;
  const isClient = user?.rol === CLIENT;
  const isCheckingItsProfile = user?._id === oAdviser?.user_id;

  const [aPackagesSession, , , updateApackages] = useFetchData(
    'session-packages',
    `teacher_id=${aId}&status=1`,
    undefined,
    undefined,
    !isLogedIn
  );
  const [aReviews, reviewsLoading] = useFetchData(
    'reviews',
    `adviser_id=${aId}&status=1`,
    undefined,
    undefined,
    !isLogedIn
  );

  // State for average stars
  const [averageStarsState, setAverageStarsState] = useState(null);

  const getAdviser = useCallback(async () => {
    setFetching(true);
    const response = await process(
      isLogedIn ? GET : OPEN_GET,
      'advisers',
      {},
      { id: aId }
    );

    if (response?.ok) {
      setAdviser(() => {
        const _Adviser = response.data;

        const sPhoto = oAdviser.user?.photo
          ? `${url}/upload?target=${oAdviser.user.photo}&token=${token}`
          : 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png';

        const _FetchAdviser = {
          ..._Adviser,
          _id: _Adviser._id,
          name: _Adviser.name,
          img: sPhoto,
          last_name: _Adviser.last_name,
          user_id: _Adviser.user_id,
          bio: _Adviser?.bio || '',
          description: _Adviser?.description || '',
          cost_by_hour: _Adviser.cost_by_hour,
          availability: _Adviser.availability || '',
          languages: _Adviser?.languages || [],
          schedules: _Adviser.schedules || [],
          allow_exam: _Adviser.allow_exam || false,
        };
        if (
          user.rol === 'advisers' &&
          isLogedIn &&
          user._id === _FetchAdviser.user._id &&
          _FetchAdviser.schedules.length === 0
        ) {
          openNotificationWithIcon('warning');
        }
        return _FetchAdviser;
      });
    } else {
      const { data } = response;
      handleErrors(data, 'instructores');
    }
    setFetching(false);
  }, []);

  useEffect(() => {
    if (!aId) {
      return;
    }

    getAdviser();
  }, [aId]);

  const handleTabChange = key => {
    setActiveTab(key);
    const element = document.getElementById(key);
    setTimeout(() => {
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  const resetSelectedAndForm = () => {
    updateApackages();
    setMultiModal(!multipleModal);
  };

  // ? This handler is for Adviser's info form
  const _handleSubmit = async values => {
    setLoading(true);
    const response = await process(UPDATE, 'advisers', values, {
      id: aId,
    });
    if (response?.ok) {
      message.success('¡Datos actualizados exitosamente!');
      setIsEditing(false);

      getAdviser();
    } else {
      const { data } = response;
      handleErrors(data, 'instructores');
    }
    setLoading(false);
  };

  // ? This handler is for MultipleModal
  const _handleSubmitModal = async values => {
    setLoading(true);

    const oSend = {
      cost_by_hour: values.cost_by_hour,
    };

    if (!oSend.cost_by_hour) {
      delete oSend.cost_by_hour;
    }

    // ? Updating adviser
    const response = await process(UPDATE, 'advisers', oSend, {
      id: aId,
    });

    if (response?.ok) {
      message.success('Tus datos se actualizaron correctamente');
      getAdviser();
    } else {
      const { data } = response;
      handleErrors(data, 'Instructores');
    }
    resetSelectedAndForm();
    setLoading(false);
  };

  const handleEventEdited = info => {
    const selectedEvent = info.event;
    const startDateTime =
      dayjs(selectedEvent?.start) || dayjs(selectedEvent?.end);
    const selectedSchedule = {
      start: selectedEvent.start,
      end: selectedEvent.end,
      title: selectedEvent.title,
      day: startDateTime.format('dddd'),
    };

    if (!isScheduleSelected(selectedSchedule, selectedSchedules)) {
      setSelectedSchedules(prevSelectedSchedules => {
        const newSelectedSchedules = [
          ...prevSelectedSchedules,
          selectedSchedule,
        ];
        const schedulesSelectedClient = formatSchedules(
          newSelectedSchedules,
          true
        );
        const postMessage = `Me gustaría agendar mis sesiones para el siguiente horario: ${schedulesSelectedClient.join(
          ', '
        )}. `;

        //Save message
        setMessagePostSelect(postMessage);
        notification.info({
          message: 'Proceder a agregar horarios en la propuesta al asesor',
          description: `¿Quieres agregar los siguientes horarios? ${postMessage}`,
          duration: 0, // No close
          btn: (
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  // Acepte schedules
                  setAvailabilityModal(false);
                  setRequestModal(true);
                  notification.close('confirmarHorarios');
                }}
              >
                Aceptar
              </Button>
              <Button
                type="danger"
                size="small"
                onClick={() => {
                  notification.close('confirmarHorarios');
                  setSelectedSchedules([]);
                }}
              >
                Rechazar
              </Button>
            </div>
          ),
          key: 'confirmarHorarios',
        });

        return newSelectedSchedules;
      });

      return formatSchedules([selectedSchedule], true);
    } else {
      notification.warning({
        message: 'Horario Duplicado',
        description: 'Este horario ya ha sido seleccionado previamente.',
      });
    }
  };

  // TODO: Add user's config (or similar) to show the modal or not
  const showPaymentInfo = () => {
    Modal.confirm({
      centered: true,
      title: 'Información sobre Pago',
      content: (
        <div>
          <p>
            Dado que al solicitar una asesoría, se estará reservando un espacio
            en el horario del asesor, es requerido pagar por adelantado. Si
            estás de acuerdo, puedes continuar con el proceso.
          </p>
          <u>
            (Si surge algún problema con la asesoría, se te reembolsará el
            dinero sin ningún inconveniente)
          </u>
        </div>
      ),
      okText: 'Entendido',
      cancelText: 'Cancelar',
      onOk: () => {
        setRequestModal(true);
      },
    });
  };

  const truncatedAboutMe = () => {
    if (oAdviser.bio.length === 0 || !oAdviser.bio) {
      return NOT_AVAILABLE;
    }

    return oAdviser.bio;
  };

  // ? This useEffect is for average stars calculation and update
  useEffect(() => {
    if (!isNaN(averageStarsState)) {
      setAverageStarsState(averageStarsState);
    }
  }, [averageStarsState]);

  // ? This is for average stars calculation
  const reviews = aReviews.data;
  const { totalStars, totalReviews } = reviews.reduce(
    (accumulator, review) => ({
      totalStars: accumulator.totalStars + review.stars,
      totalReviews: accumulator.totalReviews + 1,
    }),
    { totalStars: 0, totalReviews: 0 }
  );

  // ? Round average stars calculation
  const averageStarsCalculation = Math.round(totalStars / totalReviews);

  // ? This is for image upload
  const [fileUploading, setFileUploading] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  const getPhotoUser = useCallback(async () => {
    setFileLoading(true);
    const photoId = user?.photo;
    if (photoId) {
      const res = await process(GET, 'multimedia', {}, { id: photoId });
      setFilePath(res?.data?.file_path);
    }
    setFileLoading(false);
    return false;
  }, []);

  useEffect(() => {
    getPhotoUser();
  }, []);

  // ? This is for image upload
  const handleImageUpload = async file => {
    if (!file) {
      message.error('No se ha seleccionado un archivo');
      return false;
    }

    const isImage =
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg';

    if (!isImage) {
      message.error('El archivo seleccionado no es una imagen válida');
      return false;
    }

    setFileUploading(true);

    if (filePath) {
      try {
        await process(DELETE, 'multimedia', {}, { id: user.photo });
      } catch (error) {
        setFileUploading(false);
        message.error(
          'HUbo un problema cambiando su imagen de perfil',
          error.message
        );
      }
    }

    // eslint-disable-next-line no-undef
    let oBody = new FormData();
    oBody.append('is_public', true);
    oBody.append('files', file);

    const response = await process(FILE_SAVE, 'multimedia', oBody);

    if (response.ok) {
      const { data } = response;
      const userResponse = await process(
        UPDATE,
        'users',
        { photo: data[0]?._id },
        { id: user._id }
      );

      if (userResponse.ok) {
        message.success('Imagen de perfil actualizada correctamente');
        dispatch(
          Auth.updateUser({
            ...user,
            photo: data[0]?._id,
          })
        );
        setFilePath(data[0]?.file_path);
        // Refresh adviser
        getAdviser();
      } else {
        handleErrors(userResponse.data, 'Actualización de imagen');
      }
    } else {
      const { data: resData } = response;
      handleErrors(resData, 'Subida de imagen');
    }

    setFileUploading(false);
  };

  return (
    <Spin tip="Cargando..." spinning={fetching} size="large">
      <NeedSignIn
        adviser={oAdviser}
        visible={modal}
        onCancel={() => setModal(false)}
        onOk={() => {
          setModal(false);
        }}
      />

      <RequestForm
        adviser={oAdviser}
        visible={requestModal}
        onCancel={() => setRequestModal(false)}
        onOk={() => setRequestModal(false)}
        packages={aPackagesSession}
        postMessage={messagePostSelect || ''}
        preSelectedSchedules={selectedSchedules}
      />

      <Row className="adviser-details-container">
        <Col xs={24} md={7}>
          <Card className="profile-card">
            {isCheckingItsProfile && isLogedIn && (
              <MultipleModal
                adviser={oAdviser}
                visible={multipleModal}
                handler={setMultiModal}
                loading={loading}
                submit={_handleSubmitModal}
                aPackagesSession={aPackagesSession.data}
                aClassSchedule={oAdviser?.schedules
                  ?.filter(schedule => schedule.type === 'class')
                  .map(schedule => {
                    return {
                      _id: schedule._id,
                      day: schedule.day,
                      time_range: [schedule.start_date, schedule.end_date],
                      type: schedule.type,
                    };
                  })}
                aExamSchedule={oAdviser?.schedules
                  ?.filter(schedule => schedule.type === 'exam')
                  .map(schedule => {
                    return {
                      _id: schedule._id,
                      day: schedule.day,
                      time_range: [schedule.start_date, schedule.end_date],
                      type: schedule.type,
                    };
                  })}
                allow_exam={oAdviser?.allow_exam}
                onDataChange={getAdviser}
              />
            )}
            <div className="profile-picture-container">
              <Image
                alt="profile-picture"
                src={
                  oAdviser?.user?.photo
                    ? `${url}/upload?target=${oAdviser.user.photo}&token=${token}`
                    : 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'
                }
                preview={false}
                className="profile-picture"
              />
            </div>
            {isCheckingItsProfile && isLogedIn && (
              <div className="upload-btn">
                <Upload
                  showUploadList={false}
                  beforeUpload={handleImageUpload}
                  accept="image/png, image/jpeg, image/jpg"
                  disabled={fileLoading || fileUploading}
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={fileUploading || fileLoading}
                    style={{ marginBottom: '20px' }}
                  >
                    Subir
                  </Button>
                </Upload>
              </div>
            )}
            <Title level={4}>
              {oAdviser.name} {oAdviser.last_name}
            </Title>
            <Rate
              disabled
              value={
                averageStarsCalculation !== null ? averageStarsCalculation : 0
              }
            />
            <Meta
              title={
                <Tag color="green">
                  {aReviews?.data.length || 0} Valoraciones
                </Tag>
              }
            />
            <br />
            <Title level={5} italic>
              {`Costo por hora: `}
              <Tag
                color="green"
                style={{ fontSize: '15px' }}
              >{` $${oAdviser.cost_by_hour}`}</Tag>
            </Title>
            <Meta
              title={
                <AvailabilityModal
                  handler={setAvailabilityModal}
                  visible={availabilityModal}
                  schedules={oAdviser.schedules}
                  setModalRequest={setRequestModal}
                  handlerEventEdited={handleEventEdited}
                  fromAvailability
                  handlerSchedules={setSelectedSchedules}
                />
              }
              className="meta-container"
            />
            <Divider />
            <Title level={5} italic>
              Paquetes de Sesiones
            </Title>
            <Packages packages={aPackagesSession} />
            <Divider />
            <Button
              type="ghost"
              shape="round"
              size="large"
              className="reservation-button"
              disabled={isLogedIn && isCheckingItsProfile}
              onClick={() => {
                if (!isLogedIn) {
                  setModal(true);
                  return;
                }

                if (isAdviser) {
                  // ? This might be changed later
                  message.info(
                    'No puedes solicitar asesorias a otro instructor'
                  );
                  return;
                }

                if (isClient) {
                  if (
                    !Array.isArray(oAdviser.schedules) ||
                    oAdviser.schedules?.length === 0
                  ) {
                    notification.error({
                      message: 'Este asesor no tiene horarios registrados',
                      description:
                        'No se puede continuar con la solicitud, por favor intenta con otro asesor',
                    });
                    return;
                  }

                  if (
                    !Array.isArray(oAdviser.languages) ||
                    oAdviser.languages?.length === 0
                  ) {
                    notification.error({
                      message: 'Este asesor no tiene idiomas registrados',
                      description:
                        'No se puede continuar con la solicitud, por favor intenta con otro asesor',
                    });
                    return;
                  }

                  showPaymentInfo();
                }
              }}
            >
              Solicitar Asesoria
            </Button>
            <Divider />
            {/* ? This can be used later for badges */}
            {/* <Text className="info-text">
              {oAdviser.acceptReservations || NOT_AVAILABLE}
            </Text>
            <hr />
            <Text className="info-text">
              {oAdviser.experience || NOT_AVAILABLE}
            </Text>
            <hr />
            <Text className="info-text">
              {oAdviser.popularTeacher || NOT_AVAILABLE}
            </Text> */}
          </Card>
        </Col>

        <Col xs={24} md={16}>
          <Card className="about-container">
            {/*  headers about  */}
            <Col span={24}>
              <img
                alt="profile-picture"
                src={banner}
                style={{
                  width: '100%',
                  height: 300,
                  objectFit: 'cover',
                  marginTop: 20,
                }}
              />
              <div className="menu">
                <Tabs
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  size="large"
                  centered
                >
                  <TabPane tab="Sobre mí" key="about-me"></TabPane>
                  <TabPane tab="Acerca de la clase" key="about-class"></TabPane>
                  <TabPane tab="Enseña" key="teaches"></TabPane>
                  <TabPane
                    tab="Valoraciones de sus alumnos"
                    key="comments"
                  ></TabPane>
                </Tabs>
              </div>
            </Col>
            {/* Body about */}
            <Form form={form} name="about-adviser" onFinish={_handleSubmit}>
              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Title level={3} className="about-title">
                    Sobre mí
                  </Title>

                  {isLogedIn && !isEditing && isCheckingItsProfile ? (
                    <Button
                      onClick={() => setIsEditing(true)}
                      type="primary"
                      ghost
                      icon={<EditOutlined />}
                      size="large"
                    >
                      Editar Secciones
                    </Button>
                  ) : /* eslint-disable indent */
                  null}
                </Row>
                {isEditing ? (
                  <Form.Item name="bio" initialValue={oAdviser.bio}>
                    <TextArea
                      showCount
                      maxLength={1000}
                      style={{
                        height: 400,
                        resize: 'none',
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Text id="about-me">
                    <pre
                      className="about-me-content"
                      style={{
                        backgroundColor: '#fff',
                        border: 'none',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {truncatedAboutMe()}
                    </pre>
                  </Text>
                )}
              </Col>

              <Divider />
              <Col span={24}>
                <Title level={3} className="about-title">
                  Descripción
                </Title>
                {isEditing ? (
                  <Form.Item
                    name="description"
                    initialValue={oAdviser.description}
                  >
                    <TextArea
                      showCount
                      maxLength={500}
                      style={{
                        height: 200,
                        resize: 'none',
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Text id="about-class">
                    <pre
                      className="about-class-content"
                      style={{
                        backgroundColor: '#fff',
                        border: 'none',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {oAdviser.description || NOT_AVAILABLE}
                    </pre>
                  </Text>
                )}
              </Col>
              <Col span={24} style={{ textAlign: 'right', marginTop: '2rem' }}>
                {isEditing ? (
                  <>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ marginRight: '2rem' }}
                      icon={<SaveOutlined />}
                      loading={loading}
                      ghost
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={() => setIsEditing(false)}
                      type="primary"
                      danger
                      ghost
                      style={{ marginLeft: '2rem' }}
                    >
                      Cancelar
                    </Button>
                  </>
                ) : null}
              </Col>
              <Divider />
              <Col span={24}>
                <Title level={3} className="about-title">
                  Enseña
                </Title>
                <div id="teaches">
                  {oAdviser?.languages?.length === 0 ? (
                    <Text>No hay idiomas registrados</Text>
                  ) : (
                    oAdviser?.languages?.map((language, index) => (
                      <Card key={index} className="subject-card">
                        <Title level={5} strong className="subject-title">
                          {language.language}:
                        </Title>
                        <div style={{ margin: '8px 0' }}>
                          {language.levels.map((level, i) => (
                            <Tag
                              color="#001529"
                              key={i}
                              className="subgroup-tag"
                            >
                              {level}
                            </Tag>
                          ))}
                        </div>
                      </Card>
                    ))
                  )}
                </div>
              </Col>
            </Form>

            <div id="comments">
              <CommentsList
                comments={aReviews.data}
                loading={reviewsLoading}
                title="Valoraciones de sus alumnos"
                valuation
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default AdviserDetails;

const Packages = ({ packages = [] }) => {
  if (packages.data?.length <= 0) {
    return NOT_AVAILABLE;
  }

  return packages.data.map((item, index) => (
    <div key={index} className="price-container">
      <Text className="price-text">{item.hours} clases</Text>
      <Tag color="green" className="price-text">
        {item.cost}
      </Tag>
    </div>
  ));
};
