import React from 'react';
import { ReviewTable } from '../../Components/Reviews/ReviewTable';

export const ReviewsScreen = () => {
  return (
    <div className="review-screen">
      <ReviewTable />
    </div>
  );
};
