import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';

// ! Weird note, this component theorically can be defined inside Members.details.js, but if you do that, the screen freezes

export const CenterCol = props => {
  const { children, ...rest } = props;
  return (
    <Col {...rest}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {children}
      </div>
    </Col>
  );
};

CenterCol.propTypes = {
  children: PropTypes.node,
};
