import React from 'react';
import { Layout } from 'antd';
import { OuterContainer } from '../../Components/Atoms/OuterContainer';
import { FooterContainer } from '../../Components/Atoms/FooterContainer';
import { AdviserSignUpForm } from '../../Components/Forms';

export const AdviserSignUp = () => {
  return (
    <Layout className="outer-screen top-bar bg-layout fixed-content">
      <OuterContainer>
        <AdviserSignUpForm />
      </OuterContainer>
      <FooterContainer />
    </Layout>
  );
};
