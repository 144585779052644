import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

export const AdviserInfo = ({
  fn = {
    handler: () => ({}),
    handlerSelected: () => ({}),
    selected: {},
  },
  modal = {
    form: {},
    handler: () => ({}),
    loading: false,
    visible: false,
  },
}) => {
  const { name, last_name, user } = fn.selected;
  return (
    <Modal
      cancelText="Cerrar"
      centered
      onCancel={() => {
        fn.handlerSelected({});
        modal.handler(false);
        modal.form.resetFields();
      }}
      okText="Listo"
      onOk={() => {
        fn.handlerSelected({});
        modal.handler(false);
        modal.form.resetFields();
      }}
      title="Información del Asesor"
      visible={modal.visible}
    >
      <div style={{ padding: '16px' }}>
        <p>
          <strong>Nombre:</strong> {name}
        </p>
        <p>
          <strong>Apellido:</strong> {last_name}
        </p>
        <p>
          <strong>Correo Electrónico:</strong> {user?.email}
        </p>
        <p>
          <strong>Teléfono:</strong> {user?.phone}
        </p>
      </div>
    </Modal>
  );
};

AdviserInfo.propTypes = {
  fn: PropTypes.any.isRequired,
  modal: PropTypes.shape({
    form: PropTypes.object,
    handler: PropTypes.func,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    visible: PropTypes.bool,
  }),
};
