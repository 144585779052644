import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { SidebarFilters } from './sidebarFilters';
import { CourseGroupCard } from './courseGroupCard';
import { useDispatch, useSelector } from 'react-redux';
import { Filters } from '../../Redux/reducers/filters';
import { CenterCol } from '../Atoms/CenterColumn';
import { useFetchData } from '../../Hooks';

export const LayoutHome = () => {
  const { user } = useSelector(state => state.auth);
  const isLogedIn = !!Object.keys(user).length;
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filterUserType, setFilterUserType] = useState('student');
  const [filterModalityType, setFilterModalityType] = useState('group_class');
  const [advisers, loadingAdvisers, changeAdvisers] = useFetchData(
    'advisers',
    'status=1',
    0,
    0,
    !isLogedIn
  );
  const [groups, loadingGroups, changeGroups, gUpdater] = useFetchData(
    'groups',
    'status=1',
    0,
    0,
    !isLogedIn
  );
  const dispatch = useDispatch();

  const handleFilters = values => {
    if (filterUserType === 'student') {
      const { language, level, min, max, modality_type } = values;
      setFiltersLoading(true);

      let query = `status=1&languages.language=${language}&languages.levels=${level}`;
      let groupsQuery = `status=1&language=${language}&level=${level}`;

      if (!(modality_type === undefined || modality_type === null)) {
        setFilterModalityType(modality_type);
      }
      if (!(min === undefined || min === null)) {
        query += `&cost_by_hour[$gte]=${min}`;
        groupsQuery += `&session_cost[$gte]=${min}`;
      }
      if (!(max === undefined || max === null)) {
        query += `&cost_by_hour[$lte]=${max}`;
        groupsQuery += `&session_cost[$lte]=${max}`;
      }
      //Search adviser
      changeAdvisers(query, 0, 50);
      changeGroups(groupsQuery, 0, 50);
      dispatch(Filters.filtersSet(language, level));
      setFiltersLoading(false);
    }

    if (filterUserType === 'teacher') {
      const { language, course, modality_type } = values;
      setFiltersLoading(true);
      if (!(modality_type === undefined || modality_type === null)) {
        setFilterModalityType(modality_type);
      }
      let query = `status=1&language=${language}&level=${course}&target=teachers`;
      changeGroups(query, 0, 50);
      dispatch(Filters.filtersSet(language));
      setFiltersLoading(false);
    }
  };

  const handleCleanFilters = () => {
    dispatch(Filters.filtersClear());
    setFilterModalityType('group_class');
    changeAdvisers('status=1', 0, 50);
    changeGroups('status=1', 0, 50);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col xl={6} md={6} xxl={6} sm={24} xs={24}>
          <SidebarFilters
            handlerFilters={handleFilters}
            handlerClearFilters={handleCleanFilters}
            onUserTypeChange={values => setFilterUserType(values)}
          />
        </Col>
        <Col xl={18} md={18} xxl={18} sm={24} xs={24}>
          <Row>
            {loadingGroups || loadingAdvisers || filtersLoading ? (
              <Col span={24}>
                <CenterCol>
                  <Spin size="large" />
                </CenterCol>
              </Col>
            ) : (
              <Col span={24}>
                <CourseGroupCard
                  adviserData={advisers.data}
                  groupData={groups.data}
                  showAdvisers={filterUserType === 'student'}
                  isAdvisersFirst={filterModalityType === 'private_class'}
                  groupUpdater={gUpdater}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
