import immutable from 'seamless-immutable';
import { createActions, createReducer } from 'reduxsauce';

const { Creators, Types } = createActions({
  addRequest: ['requests'],
  updateRequest: ['requests'],
  clearRequests: [],
});

const INITIAL_STATE = immutable({
  requests: {},
});
function add(state, action) {
  const { requests } = action;
  return state.merge({
    requests,
  });
}

function update(state, action) {
  const updatedRequests = action.requests;

  return state.update('requests', aRequests => {
    if (Array.isArray(aRequests) || aRequests?.length === 0) {
      return updatedRequests;
    }

    if (aRequests?.length > 0) {
      return aRequests?.map(existingRequest => {
        const updatedRequest = updatedRequests.find(
          updated => updated._id === existingRequest._id
        );

        return updatedRequest ? updatedRequest : existingRequest;
      });
    }
  });
}

function clear() {
  return INITIAL_STATE;
}

const HANDLERS = {
  [Types.ADD_REQUEST]: add,
  [Types.UPDATE_REQUEST]: update,
  [Types.CLEAR_REQUESTS]: clear,
};

export const request = Creators;

export const appTypes = Types;

export default createReducer(INITIAL_STATE, HANDLERS);
