import React from 'react';
import { Typography, Divider, List } from 'antd';

const { Title, Text } = Typography;

export const AdviserInformation = () => {
  const data = [
    'Eliges tus horarios de trabajo.',
    'Eliges tus cuotas o costos por hora.',
    'Los alumnos te buscan a ti.',
    'Accedes a descuentos en cursos que Educom ofrece a maestros.',
    'Obtienes tu constancia en cada curso.',
    'Trabajas para una escuela reconocida con amplia experiencia y calidad.',
  ];
  return (
    <div className="adviser-information" style={{ padding: '20px' }}>
      <div
        className="adviser-information-header"
        style={{ textAlign: 'center' }}
      >
        <Title level={2}>Información para maestros</Title>
      </div>
      <div className="adviser-information-content">
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Trabajar en Educom</Title>
          </Divider>
          <List
            size="large"
            header={
              <Text strong>Ventajas de trabajar en Educom como Maestro:</Text>
            }
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <Text>{item}</Text>
              </List.Item>
            )}
          />
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Cuotas</Title>
          </Divider>
          <Text>
            Los maestros de clases privadas fijan su propia cuota por hora,
            Educom retiene un 25% de dicha cuota. Por ejemplo, si pones una
            cuenta de $ 100 pesos por hora, Educom te entrega $75.00 pesos y se
            queda con $ 25.00 pesos. Como maestro también puedes trabajar dando
            clases en grupos, en este caso solicita tu alta como maestro de
            grupo contactándote con Educom. En las clases en grupo Educom fija
            el pago por hora para el maestro y el costo por hora para el alumno,
            así como los grupos que se abren.
          </Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Cursos para maestros</Title>
          </Divider>
          <Text>Información sobre los cursos disponibles para maestros...</Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Reglamento</Title>
          </Divider>
          <Text>Información sobre el reglamento de la institución...</Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Constancia</Title>
          </Divider>
          <Text>Información sobre cómo obtener una constancia...</Text>
        </div>
      </div>
    </div>
  );
};
