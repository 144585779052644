import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { SearchSelector } from '../../Components/Atoms/SearchSelector';
import { languages, levelsSelect } from '../../Constants/levels';
import { useSelector } from 'react-redux';
import { CustomButton } from '../Atoms/CustomButtom';
import PropTypes from 'prop-types';
import {
  budgetsList,
  classModalityTypes,
  sessionModalityTypes,
} from '../../Constants/SelectionOptions';
const { Option } = Select;
const { Title, Text } = Typography;

/* eslint-disable */
export const SidebarFilters = ({
   handlerFilters = values => ({}),
   handlerClearFilters = () => ({}),
   onUserTypeChange = values => ({}),
}) => {
  /* eslint-enable */
  const { user } = useSelector(state => state.auth);
  const isLogedIn = !!Object.keys(user).length;

  const [hasSearch, setHasSearch] = useState(false);
  const [userType, setUserType] = useState('student');
  // eslint-disable-next-line no-unused-vars
  const [formRef] = Form.useForm();

  useEffect(() => {
    if (!isLogedIn) {
      return;
    }
  }, [isLogedIn]);

  const _handleFilters = values => {
    setHasSearch(true);
    if (userType === 'student') {
      if (values.language === undefined || values.level === undefined) {
        message.info(
          'Para buscar cursos o asesores debes elegir lenguaje y nivel'
        );
        return;
      }
      const { language, level, budgets, modality_type } = values;

      let min, max;
      switch (budgets) {
        case 0:
          min = null;
          max = null;
          break;
        case 1:
          min = null;
          max = 99;
          break;
        case 2:
          min = 100;
          max = 200;
          break;
        case 3:
          min = 201;
          max = 300;
          break;
        case 4:
          min = 301;
          max = 400;
          break;
        case 5:
          min = 401;
          max = 500;
          break;
        case 6:
          min = 501;
          max = null;
          break;
        default:
          min = null;
          max = null;
          break;
      }
      handlerFilters({
        language: language,
        level: level,
        min: min,
        max: max,
        modality_type: modality_type,
      });
    }

    if (userType === 'teacher') {
      if (values.course === undefined || values.language === undefined) {
        message.info('Para buscar cursos debes elegir un idioma y un nivel');
        return;
      }
      const { language, course, modality_type } = values;
      handlerFilters({
        language: language,
        course: course,
        modality_type: modality_type,
      });
    }
  };

  useEffect(() => {
    onUserTypeChange(userType);
  }, [userType]);

  const handleCleanFilters = () => {
    formRef.resetFields();
    handlerClearFilters();
  };

  const [isMobile, setIsMobile] = useState(false);

  // ? This useEffect is for setting the isMobile state
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return (
    <div
      className="search-screen "
      style={{
        display: 'flex',
        height: '100%',
      }}
    >
      <div
        className="filter-container filter-container-wrapper"
        style={{
          border: '1px solid #e8e8e8',
          flex: 1,
          height: '120vh',
        }}
      >
        <Row className="filter-row">
          <Title level={2} className="title">
            ¡Bienvenido a Educom,
            <span className="subtitle">
              {' tu puerta al facinante mundo de los idiomas!'}
            </span>
          </Title>
        </Row>
        <Row
          style={{ marginBottom: hasSearch ? 0 : 10 }}
          className="filter-row"
        >
          <Title level={5} className="subtitle description">
            Estamos emocionados por tenerte aqui y de acompañarte en tu viaje
            hacia el dominio de lenguas universales.
          </Title>
        </Row>
        <Row
          style={{
            marginBottom: hasSearch ? 0 : isMobile ? 20 : 8,
          }}
          className="filter-row"
        >
          <Title level={5} style={{ fontSize: 12 }}>
            A continuación
            <span className="subtitle">
              {
                ' te invitamos a solicitar una cotización para clases de idiomas:'
              }
            </span>
          </Title>
        </Row>
        <Row
          className="container-buttons"
          align={'middle'}
          justify={'space-around'}
        >
          <Col
            span={12}
            style={{ margin: '0', padding: '4px', height: '100%' }}
          >
            <Button
              type={userType === 'student' ? 'default' : 'ghost'}
              onClick={() => {
                setUserType('student');
                handleCleanFilters();
              }}
              size="large"
              className="user-type-button"
            >
              Para Alumnos
            </Button>
          </Col>
          <Col
            span={12}
            style={{ margin: '0', padding: '4px', height: '100%' }}
          >
            <Button
              type={userType === 'teacher' ? 'default' : 'ghost'}
              onClick={() => {
                setUserType('teacher');
                handleCleanFilters();
              }}
              size="large"
              className="user-type-button"
            >
              Para Maestros
            </Button>
          </Col>
        </Row>
        <Row
          className="filter-row"
          style={{ marginBottom: hasSearch ? 0 : 120 }}
        >
          <Col xs={24} sm={24} md={24}>
            <Form
              layout="vertical"
              form={formRef}
              onFinish={_handleFilters}
              size="small"
            >
              <Row gutter={[24]} justify="center">
                {userType === 'student' ? (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Text className={'label'}>Idioma de interés:</Text>
                        }
                        name="language"
                        className="form-item"
                      >
                        <SearchSelector
                          allowClear
                          size="large"
                          placeholder="Seleccione un idioma"
                        >
                          {languages.map(({ value, label }, i) => (
                            <Option key={i} value={value}>
                              {label}
                            </Option>
                          ))}
                        </SearchSelector>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Text className={'label'}>Modalidad de Clases:</Text>
                        }
                        name="modality_type"
                        className="form-item"
                      >
                        <SearchSelector
                          allowClear
                          size="large"
                          placeholder="Seleccione una modalidad"
                        >
                          {classModalityTypes.map(({ value, label }, i) => (
                            <Option key={i} value={value}>
                              {label}
                            </Option>
                          ))}
                        </SearchSelector>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Tooltip
                        placement="right"
                        trigger="click"
                        title="Los instructores con un nivel mayor pueden apoyarte en niveles inferiores, aunque puede implicar un costo mayor."
                      >
                        <Form.Item
                          label={
                            <Text className={'label'}>
                              Nivel de conocimiento:
                            </Text>
                          }
                          name="level"
                          className="form-item"
                        >
                          <SearchSelector
                            allowClear
                            size="large"
                            placeholder="Seleccione un nivel"
                          >
                            {levelsSelect.map(({ value }, i) => (
                              <Option key={i} value={value}>
                                {value}
                              </Option>
                            ))}
                          </SearchSelector>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Text className={'label'}>
                            Presupuesto deseado por hora:
                          </Text>
                        }
                        name="budgets"
                        className="form-item"
                      >
                        <SearchSelector
                          allowClear
                          size="large"
                          placeholder="Seleccione un presupuesto"
                        >
                          {budgetsList.map(buget => (
                            <Option key={buget} value={buget?.value}>
                              {buget.label}
                            </Option>
                          ))}
                        </SearchSelector>
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Text className={'label'}>
                            Selecciona el idioma que impartes:
                          </Text>
                        }
                        name="language"
                        className="form-item"
                      >
                        <SearchSelector
                          allowClear
                          size="large"
                          placeholder="Seleccione un idioma"
                        >
                          {languages.map(({ value, label }, i) => (
                            <Option key={i} value={value}>
                              {label}
                            </Option>
                          ))}
                        </SearchSelector>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Text className={'label'}>
                            Nivel de conocimiento:
                          </Text>
                        }
                        name="course"
                        className="form-item"
                      >
                        <SearchSelector
                          allowClear
                          size="large"
                          placeholder="Seleccione un idioma"
                        >
                          {levelsSelect.map(({ value }, i) => (
                            <Option key={i} value={value}>
                              {value}
                            </Option>
                          ))}
                        </SearchSelector>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Text className={'label'}>
                            Modalidad para la clase de maestros:
                          </Text>
                        }
                        name="modality_type"
                        className="form-item"
                      >
                        <SearchSelector
                          allowClear
                          size="large"
                          placeholder="Selecciona una modalidad de clase"
                        >
                          {sessionModalityTypes.map(({ value, label }, i) => (
                            <Option key={i} value={value}>
                              {label}
                            </Option>
                          ))}
                        </SearchSelector>
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CustomButton
                    title="Explorar posibilidades"
                    handleAction={formRef.submit}
                    type="info"
                    size="large"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

SidebarFilters.propTypes = {
  handlerFilters: PropTypes.func,
  handlerClearFilters: PropTypes.func,
  onUserTypeChange: PropTypes.func,
};
