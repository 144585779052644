import React from 'react';
import { AdviserTable } from '../../Components/Advisers/AdviserTable';

export const AdvisersScreen = () => {
  return (
    <div className="adviser-screen">
      <AdviserTable />
    </div>
  );
};
