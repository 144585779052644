import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  message,
  InputNumber,
} from 'antd';
import { CenterCol } from '../../Components/Atoms/CenterColumn';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { SearchSelector } from '../../Components/Atoms/SearchSelector';
import { cefrLevels, languages } from '../../Constants/levels';
import { useDispatch, useSelector } from 'react-redux';
import { Filters } from '../../Redux/reducers/filters';
import { useFetchData } from '../../Hooks';
import { RequestGroupTable } from './RequestGroupTable';
const { Option } = Select;
const { Title } = Typography;
import { CLIENT } from '../../Constants/roles';

export const RequestGroupList = () => {
  const { language: sLanguage, level: sLevel } = useSelector(
    state => state.filters
  );
  const { user } = useSelector(state => state.auth);
  const isLogedIn = !!Object.keys(user).length;
  const isClient = user?.rol === CLIENT;

  const [hasSearch, setHasSearch] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filters, setFilters] = useState({
    language: sLanguage,
    level: sLevel,
    min: null,
    max: null,
  });
  const [formRef] = Form.useForm();
  const [groups, loading, change, updater] = useFetchData(
    'groups',
    'status=1',
    0,
    0,
    !isLogedIn
  );
  const [requests, , aChange, aUpdater] = useFetchData(
    'group-requests',
    `client_id=${user?.client_id}`,
    0,
    0
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLogedIn) {
      return;
    }

    change('status=1', 0, 50);
    aChange(`client_id=${user?.client_id}`, 0, 50);
  }, [isLogedIn]);

  const handleFilters = values => {
    setHasSearch(true);
    if (values.language === undefined || values.level === undefined) {
      message.info('Para buscar grupos debes elegir lenguaje y nivel');
      return;
    }
    const { language, level, max, min } = values;

    setFiltersLoading(true);
    setFilters({
      language,
      level,
      min,
      max,
    });

    let query = `status=1&language=${language}&level=${level}`;

    if (min !== undefined && max !== undefined) {
      query += `&session_cost[$gte]=${min}&session_cost[$lte]=${max}`;
    } else if (min !== undefined) {
      query += `&session_cost[$gte]=${min}`;
    } else if (max !== undefined) {
      query += `&session_cost[$lte]=${max}`;
    }
    //Search adviser
    change(query, 0, 50);
    dispatch(Filters.filtersSet(language, level));
    setFiltersLoading(false);
  };

  const handleCleanFilters = () => {
    setFilters({
      language: null,
      level: null,
      min: null,
      max: null,
    });
    formRef.resetFields();
    dispatch(Filters.filtersClear());
    change('status=1', 0, 50);
  };

  const [isMobile, setIsMobile] = useState(false);

  // ? This useEffect is for setting the isMobile state
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  useEffect(() => {
    if (user?.client_id) {
      aChange(`client_id=${user?.client_id}`, 0, 50);
    }
  }, [user.client_id]);

  return (
    <div className="search-screen">
      <div className="filter-container">
        <Row>
          <Title level={1}>Educom</Title>
        </Row>
        <Row style={{ marginBottom: hasSearch ? 0 : 70 }}>
          <Title level={2}>Escuela de idiomas</Title>
        </Row>
        <Row style={{ marginBottom: hasSearch ? 0 : isMobile ? 40 : 70 }}>
          <Title level={1}>
            Suscribete a un grupo de idiomas a continuación:
          </Title>
        </Row>
        <Row
          className="filter-row"
          // eslint-disable-next-line capitalized-comments
          // style={{ marginBottom: hasSearch ? 0 : 120 }}
        >
          <Col xs={24} sm={24} md={24} style={{ marginBottom: '30px' }}>
            <Form layout="vertical" form={formRef} onFinish={handleFilters}>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
                justify="center"
              >
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="&nbsp;" name="language">
                    <SearchSelector
                      allowClear
                      size="large"
                      placeholder="¿Qué te interesa aprender?"
                    >
                      {languages.map(({ value, label }, i) => (
                        <Option key={i} value={value}>
                          {label}
                        </Option>
                      ))}
                    </SearchSelector>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5}>
                  <Tooltip
                    placement="right"
                    trigger="click"
                    title="Los instructores con un nivel mayor pueden apoyarte en niveles inferiores, aunque puede implicar un costo mayor."
                  >
                    <Form.Item label="&nbsp;" name="level">
                      <SearchSelector
                        allowClear
                        size="large"
                        placeholder="Nivel"
                      >
                        {cefrLevels.map(level => (
                          <Option key={level} value={level}>
                            {level}
                          </Option>
                        ))}
                      </SearchSelector>
                    </Form.Item>
                  </Tooltip>
                </Col>
                {!isMobile ? (
                  <Col xs={24} sm={12} md={1}>
                    <Divider type="vertical" style={{ height: '100%' }} />
                  </Col>
                ) : null}
                <Row
                  gutter={[16, 16]}
                  style={{ marginTop: isMobile ? '5px' : '' }}
                >
                  <Col xs={12} sm={12}>
                    <Form.Item label="Costo por sesión" name="min">
                      <InputNumber
                        type="number"
                        placeholder="Min"
                        addonAfter={'MXN'}
                        min={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12}>
                    <Form.Item label=" " name="max">
                      <InputNumber
                        type="number"
                        placeholder="Max"
                        addonAfter={'MXN'}
                        min={1}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Col>
                  <Form.Item label=" ">
                    <Button
                      type="primary"
                      size="large"
                      icon={<SearchOutlined />}
                      onClick={formRef.submit}
                      style={{ width: isMobile ? '200px' : '' }}
                    >
                      {isMobile ? 'Buscar' : null}
                    </Button>
                  </Form.Item>
                </Col>
                {Object.keys(filters)
                  .map(sKey => filters[sKey])
                  .some(
                    value =>
                      value !== null && value !== undefined && value !== ''
                  ) ? (
                  /* eslint-disable */
                  <Col xs={24} sm={12} md={1}>
                    <Form.Item label=" ">
                      <Button
                        type="primary"
                        danger
                        size="large"
                        icon={<DeleteOutlined />}
                        onClick={handleCleanFilters}
                      />
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      {loading || filtersLoading ? (
        <CenterCol>
          <Spin size="large" />
        </CenterCol>
      ) : (
        <div
          className="cards-container"
          style={{ display: 'block', margin: '2rem' }}
        >
          {!!filters.language && !!filters.level ? (
            <Row>
              <Title level={2}>
                Grupos de&nbsp;
                <b style={{ color: '#EF3900' }}>{filters.language}</b>
                &nbsp;-&nbsp;
                <b style={{ color: '#408FFF' }}>{filters.level}</b>
                &nbsp;disponibles:
              </Title>
            </Row>
          ) : null}
          <RequestGroupTable
            groups={groups}
            loading={loading}
            updater={updater}
            change={change}
            isLogedIn={isLogedIn}
            isClient={isClient}
            client_id={user?.client_id}
            updaterRequests={aUpdater}
            requests={requests}
          />
        </div>
      )}
    </div>
  );
};
