import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Select, Button, List } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { formatSchedules } from '../../Utils/formatShedule';
const { Option } = Select;

export const PreviewShedule = ({
  horarios,
  horariosConfig,
  onHorarioSelect,
  schedulesClient,
  setNewSchedules,
}) => {
  const [editClientSchedules, setEditClientSchedules] = useState(false);
  const [selectedInicio, setSelectedInicio] = useState(null);
  const [selectedEnd, setSelectedEnd] = useState(null);
  const [selectedRange, setSelectedRange] = useState([]);
  const [showSelects, setShowSelects] = useState(false);
  const [showSingleSelect, setShowSingleSelect] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState(
    schedulesClient || []
  );
  const [showDefaultHorarios, setShowDefaultHorarios] = useState(
    selectedSchedules.length > 0 ? true : false
  );

  const handleHorarioSelect = (acceptedSchedules = false) => {
    if (selectedInicio && selectedEnd) {
      const horario = `${selectedInicio} a ${selectedEnd}`;
      onHorarioSelect(`Tu horario puede ser de ${horario}, ¿te parece?`);
    } else if (selectedRange?.length !== 0) {
      if (selectedRange.length === 1) {
        onHorarioSelect(
          `Tu horario puede ser de ${selectedRange[0]}, ¿te parece?`
        );

        setNewSchedules(selectedRange);
        return;
      }

      onHorarioSelect(
        `Tu horario puede ser de la siguiente manera ${selectedRange.join(
          ', '
        )}, ¿te parece?`
      );
      setNewSchedules(selectedRange);
    } else if (acceptedSchedules) {
      const schedulesSelectedClient = formatSchedules(selectedSchedules, true);
      onHorarioSelect(
        `¡Excelente elección! El horario "${schedulesSelectedClient.join(
          ', '
        )}" es perfecto.`
      );
    }
  };

  const handleShowSelects = () => {
    setShowSelects(true);
    setShowSingleSelect(false);
  };

  const handleShowRangeSelect = () => {
    setShowSelects(false);
    setShowSingleSelect(true);
  };

  const handleGoBack = () => {
    setShowSelects(false);
    setShowSingleSelect(false);
    onHorarioSelect(null);
    setSelectedInicio(null);
    setSelectedEnd(null);
    setSelectedRange([]);
  };

  const handleRemoveSchedule = index => {
    setSelectedSchedules(prevSelectedSchedules => {
      const updatedSchedules = [...prevSelectedSchedules];
      updatedSchedules.splice(index, 1);
      return updatedSchedules;
    });
  };

  const handleAcceptedSchedules = () => {
    handleHorarioSelect(true);
  };
  return (
    <div
      style={{
        padding: '16px',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
      }}
    >
      {!editClientSchedules ? (
        <>
          <h3>Horarios seleccionados por el cliente</h3>
          <List
            dataSource={formatSchedules(selectedSchedules, true)}
            renderItem={(item, i) => (
              <List.Item
                style={{
                  background: 'lightyellow',
                  border: '1px solid #d3d3d3',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                  fontFamily: 'cursive',
                }}
              >
                <div
                  key={i}
                  style={{
                    fontSize: '15px',
                    marginLeft: '8px',
                  }}
                  defaultChecked={true}
                >
                  {item}
                </div>
                {editClientSchedules ? (
                  <Button
                    type="link"
                    danger
                    onClick={() => handleRemoveSchedule(i)}
                    icon={<CloseOutlined />}
                  />
                ) : null}
              </List.Item>
            )}
          />
        </>
      ) : (
        <Alert
          message="La propuesta de horarios del cliente será ignorada. Si no desea ignorarla, presione el botón 'Cancelar'"
          type="warning"
          showIcon
          closable
          style={{ marginBottom: '2%' }}
        />
      )}

      {selectedRange?.length !== 0 && (
        <>
          <h3>Horarios Seleccionados</h3>
          <List
            dataSource={selectedRange}
            renderItem={(item, i) => (
              <List.Item
                style={{
                  background: 'lightyellow',
                  border: '1px solid #d3d3d3',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                  fontFamily: 'cursive',
                }}
              >
                <div
                  key={i}
                  style={{
                    fontSize: '15px',
                    marginLeft: '8px',
                  }}
                  defaultChecked={true}
                >
                  {item}
                </div>
                {/* {editClientSchedules ? (
                  <Button
                    type="link"
                    danger
                    onClick={() => {
                      let currentValues = [...selectedRange];
                      currentValues.splice(i, 1);
                      setSelectedRange(currentValues);
                      onHorarioSelect('');
                    }}
                    icon={<CloseOutlined />}
                  />
                ) : null} */}
              </List.Item>
            )}
          />
        </>
      )}

      {showDefaultHorarios && (
        <>
          <Button
            type="primary"
            onClick={() => {
              setEditClientSchedules(true);
              setShowDefaultHorarios(!showDefaultHorarios);
            }}
            style={{ marginTop: '16px', marginRight: '8px' }}
          >
            Seleccionar otro horario
          </Button>
          <Button
            type="primary"
            onClick={handleAcceptedSchedules}
            style={{ marginTop: '16px' }}
          >
            Aceptar horarios
          </Button>
        </>
      )}

      {showSelects && (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: '8px' }}>Inicio:</label>
            <Select
              value={selectedInicio}
              onChange={value => setSelectedInicio(value)}
              style={{ flex: 1 }}
            >
              <Option value="">Seleccione el inicio</Option>
              {horarios.map((horario, index) => (
                <Option key={index} value={horario}>
                  {horario}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}
          >
            <label style={{ marginRight: '8px' }}>Final:</label>
            <Select
              value={selectedEnd}
              onChange={value => setSelectedEnd(value)}
              style={{ flex: 1 }}
            >
              <Option value="">Seleccione el final</Option>
              {horarios.map((horario, index) => (
                <Option key={index} value={horario}>
                  {horario}
                </Option>
              ))}
            </Select>
          </div>
          <Button
            type="primary"
            onClick={handleHorarioSelect}
            style={{ marginTop: '16px' }}
          >
            Seleccionar Horario
          </Button>
          <Button
            type="default"
            onClick={handleGoBack}
            style={{ marginTop: '16px', marginLeft: '8px' }}
          >
            Volver dsaddsa
          </Button>
        </div>
      )}
      {showSingleSelect && (
        <div>
          <Select
            allowClear
            mode="multiple"
            placeholder="Seleccione un horario"
            value={selectedRange}
            onChange={value => {
              onHorarioSelect('');
              setSelectedRange(value);
            }}
            style={{ width: '100%' }}
          >
            {horariosConfig.map((horario, index) => (
              <Option key={index} value={horario}>
                {horario}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={handleHorarioSelect}
            style={{ marginTop: '16px' }}
          >
            Seleccionar horario
          </Button>
          <Button
            type="default"
            onClick={handleGoBack}
            style={{ marginTop: '16px', marginLeft: '8px' }}
          >
            Volver
          </Button>
        </div>
      )}
      {!showSelects && !showSingleSelect && !showDefaultHorarios && (
        <div>
          <Button
            type="primary"
            danger
            onClick={() => {
              setShowDefaultHorarios(!showDefaultHorarios);
              setEditClientSchedules(false);
            }}
            style={{ marginTop: '16px', marginRight: '8px' }}
          >
            Cancelar
          </Button>

          <Button
            type="primary"
            onClick={handleShowSelects}
            style={{ marginTop: '16px', marginRight: '8px' }}
          >
            Personalizar Horario
          </Button>
          <Button
            type="primary"
            onClick={handleShowRangeSelect}
            style={{ marginTop: '16px' }}
          >
            Seleccionar mis horarios
          </Button>
        </div>
      )}
    </div>
  );
};

PreviewShedule.propTypes = {
  horarios: PropTypes.array,
  horariosConfig: PropTypes.array,
  onHorarioSelect: PropTypes.func,
  schedulesClient: PropTypes.array,
  setNewSchedules: PropTypes.func,
};
