import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import { useFetchRoles } from '../../Hooks/Role.hook';

export const UserForm = ({ formRef, onSubmit }) => {
  const [password, setPassword] = useState('');
  const [roles, loading] = useFetchRoles();

  return (
    <Form form={formRef} layout="vertical" name="UserForm" onFinish={onSubmit}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Usuario"
            name="username"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Nombre"
            name="full_name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
              {
                message:
                  'La contraseña debe tener al menos 1 mayúscula, 1 minúscula, 1 número, 1 caracter especial, y 8 caracteres de longitud.',
                pattern:
                  /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?=.*[!@#$%^&*;]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
              },
            ]}
          >
            <Input.Password
              onChange={({ target: { value } }) => {
                setPassword(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Repetir contraseña"
            name="rpassword"
            rules={[
              {
                required: true,
                pattern:
                  /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?=.*[!@#$%^&*;]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                validator: (_, value) => {
                  if (value !== password) {
                    return Promise.reject('Las contraseñas no coinciden');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Estatus" name="status" initialValue={1}>
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Rol"
            name="rol_id"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Select loading={loading}>
              {roles?.data.map(oRol => (
                <Select.Option key={oRol._id} value={oRol._id}>
                  {oRol.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

UserForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
