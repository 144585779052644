import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

export const CustomButton = ({
  type = 'default',
  title,
  handleAction,
  ...props
}) => {
  return (
    <div style={{ width: '100%', marginTop: '15px' }}>
      <Button
        className="CustomButton"
        onClick={handleAction}
        type={type}
        {...props}
      >
        {title}
      </Button>
    </div>
  );
};

CustomButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  handleAction: PropTypes.func,
};
