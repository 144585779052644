/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Row,
  Table,
  message,
  Modal,
  notification,
  Tag,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { NeedSignIn } from '../Forms';

dayjs.extend(utc);

import { SAVE, process } from '../../Service/Api';
// 0 - Pending, 1 - paid, 2 - finished, 3 - cancelled

const statusRequest = [
  {
    label: 'Pendiente',
    value: 0,
    color: 'warning',
  },
  {
    label: 'Pagado',
    value: 1,
    color: 'success',
  },
  {
    label: 'Finalizado',
    value: 2,
    color: 'success',
  },
  {
    label: 'Cancelado',
    value: 3,
    color: 'error',
  },
];

export const RequestGroupTable = ({
  groups,
  loading,
  change,
  updater,
  isLogedIn,
  isClient,
  client_id,
  updaterRequests,
  requests = [],
}) => {
  const [oAdviser, setAdviser] = useState({});
  const [modal, setModal] = useState(false);
  const calculateNumClasses = (weekDays, startDate, endDate) => {
    if (weekDays && startDate && endDate) {
      const start = dayjs(startDate).utc();
      const end = dayjs(endDate).utc();

      // Obtener el número de días por semana especificados
      const numDaysPerWeek = weekDays.length;

      // Calcular el número total de días entre las fechas de inicio y fin, incluyendo el día de fin
      const diffInDays = end.diff(start, 'days') + 1;

      // Calcular el número total de semanas completas entre las fechas de inicio y fin
      const numWeeks = Math.floor(diffInDays / 7);

      // Calcular el número de días de clases en la semana final
      const remainingDays = diffInDays % 7;

      // Calcular el número total de días de clases
      let numDaysOfClasses = numWeeks * numDaysPerWeek;

      // Verificar si el día de fin coincide con alguno de los días especificados en weekDays
      const endDayOfWeek = end.day();
      if (weekDays.includes(endDayOfWeek)) {
        numDaysOfClasses++;
      }

      // Agregar los días de clases en la semana final si corresponde
      if (remainingDays > 0) {
        const lastDayOfWeek = start.add(numWeeks * 7, 'days').day();
        const daysInFinalWeek = weekDays.filter(
          day => day >= lastDayOfWeek
        ).length;
        numDaysOfClasses += Math.min(daysInFinalWeek, remainingDays);
      }

      return numDaysOfClasses;
    }
    return 0;
  };

  const _handleSubmit = async values => {
    const oSend = {
      client_id,
      group_id: values._id,
    };
    if (!oSend?.group_id || !oSend?.client_id) {
      return message.error('Se requiere el id del grupo y el id del cliente');
    }

    const response = await process(SAVE, 'group-requests', { ...oSend });

    if (response?.ok) {
      let oMessage = {
        message: `Solicitud enviada exitosamente`,
        duration: 4,
      };

      notification.success(oMessage);

      setTimeout(() => {
        if (!response.data.pay_url) {
          return;
        }
        window.location.replace(response.data.pay_url);
      }, [2000]);

      updater();
      updaterRequests();
    } else {
      message.error('Error al suscribir');
    }
  };

  const columns = [
    {
      dataIndex: 'language',
      title: 'Idioma',
    },
    {
      dataIndex: 'level',
      title: 'Nivel',
    },
    {
      dataIndex: 'start_date',
      title: 'Fecha Inicio',
      render: date => dayjs(date).utc().format('DD-MMM-YYYY'),
    },
    {
      dataIndex: 'end_date',
      title: 'Fecha Fin',
      render: date => dayjs(date).utc().format('DD-MMM-YYYY'),
    },
    {
      dataIndex: '',
      title: 'Horario',
      render: data => `${data?.start_hour} a ${data?.end_hour}`,
    },
    {
      dataIndex: 'week_days',
      title: 'Dias',
      render: data => {
        const days = data.map(day => dayjs().utc().day(day).format('dddd'));
        return days.join(' y ');
      },
    },
    {
      dataIndex: '',
      title: 'Clases',
      render: data =>
        calculateNumClasses(data.week_days, data.start_date, data.end_date),
    },

    {
      dataIndex: 'session_cost',
      title: 'Costo por sesión',
      render: value => `$${value || 0}`,
    },
    {
      dataIndex: '',
      title: 'Costo total',
      render: data =>
        data.session_cost *
        calculateNumClasses(data.week_days, data.start_date, data.end_date),
    },
    {
      dataIndex: 'member_limit',
      title: 'Cupo maximos',
      render: value => value || 0,
    },
    {
      dataIndex: '',
      title: 'Lugares disponibles',
      render: data => data?.member_limit || 0,
    },
    {
      dataIndex: '',
      title: 'Tiempo restante para inicio',
      render: data => {
        const start = dayjs(data.start_date);
        const now = dayjs();

        const diffInHours = start.diff(now, 'hours');
        const diffInMinutes = start.diff(now, 'minutes');

        if (diffInHours > 24) {
          const diffInDays = Math.floor(diffInHours / 24);
          const remainingHours = diffInHours % 24;
          return `${diffInDays} días y ${remainingHours} horas`;
        } else if (diffInHours > 0) {
          return `${diffInHours} horas y ${diffInMinutes % 60} minutos`;
        } else {
          return 'El evento ya ha comenzado';
        }
      },
    },
    {
      dataIndex: '',
      title: '',
      render: (_, row) => {
        const matchingRequest = requests?.data?.find(
          request => request?.group_id?.toString() === row?._id.toString()
        );

        return (
          <Row>
            <Col>
              {!matchingRequest ? (
                <Button
                  onClick={() => {
                    if (!isLogedIn) {
                      setModal(true);
                      return;
                    }
                    if (isClient) {
                      showPaymentInfo(row);
                    } else {
                      message.error('Solo los clientes pueden suscribirse');
                    }
                  }}
                >
                  Suscribete <EditOutlined />
                </Button>
              ) : (
                <Tag color={statusRequest[matchingRequest?.status]?.color}>
                  {statusRequest[matchingRequest?.status]?.label}
                </Tag>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  const comfirmSubscription = row => {
    Modal.confirm({
      centered: true,
      title: 'Confirmar',
      content: `Está seguro de continuar con la suscripción para el grupo de ${
        row.language
      } con nivel ${row.level} con el instructor Fernando con por un total de ${
        row.session_cost *
        calculateNumClasses(row.week_days, row.start_date, row.end_date)
      } MXN?`,
      // eslint-disable-next-line no-shadow
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk: () => _handleSubmit(row),
    });
  };

  const showPaymentInfo = row => {
    Modal.confirm({
      centered: true,
      title: 'Información sobre Pago',
      content: (
        <div>
          <p>
            Dado que al suscribir a un grupo, se estará reservando un espacio en
            el grupo del asesor, es requerido pagar por adelantado. Si estás de
            acuerdo, puedes continuar con el proceso.
          </p>
          <u>
            (Si surge algún problema con la asesoría, se te reembolsará el
            dinero sin ningún inconveniente)
          </u>
        </div>
      ),
      okText: 'Entendido',
      cancelText: 'Cancelar',
      onOk: () => {
        comfirmSubscription(row);
      },
    });
  };

  return (
    <div>
      <Row justify="center">
        <Col>
          <h2>Grupos</h2>
        </Col>
      </Row>
      <NeedSignIn
        adviser={oAdviser}
        visible={modal}
        onCancel={() => setModal(false)}
        onOk={() => {
          setModal(false);
        }}
      />

      <Table
        columns={columns}
        dataSource={groups?.data}
        loading={loading}
        pagination={{
          current: groups.skip / 10 + 1,
          onChange: e => change(groups.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: groups.total,
        }}
        rowKey={row => row._id}
      />
    </div>
  );
};

RequestGroupTable.propTypes = {
  groups: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updater: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isLogedIn: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  client_id: PropTypes.string,
  requests: PropTypes.object,
  updaterRequests: PropTypes.func,
};
