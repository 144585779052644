import dayjs from 'dayjs';
import moment from 'moment';

export const getDaysDisplay = (daysArray = []) => {
  if (daysArray.length === 0) {
    return '';
  }

  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  let text = days[daysArray[0]];

  for (let i = 1; i < daysArray.length - 1; i++) {
    text += ', ' + days[daysArray[i]];
  }
  if (daysArray.length > 1) {
    text += ' y ' + days[daysArray.slice().pop()];
  }

  return text;
};

export const getLevelDescription = value => {
  if (value === undefined || value === null) {
    return '';
  }

  let text = value.toUpperCase();

  if (text === 'A1') {
    return 'Basico';
  }

  if (text === 'A2') {
    return 'Pre-intermedio';
  }

  if (text === 'B1') {
    return 'Intermedio';
  }

  if (text === 'B2') {
    return 'Intermedio Superior';
  }

  if (text === 'C1' || text === 'C2') {
    return 'Avanzado';
  }

  return '';
};

export const calculateNumClasses = (weekDays, startDate, endDate) => {
  if (weekDays && startDate && endDate) {
    const start = dayjs(startDate).utc();
    const end = dayjs(endDate).utc();

    // Obtener el número de días por semana especificados
    const numDaysPerWeek = weekDays.length;

    // Calcular el número total de días entre las fechas de inicio y fin, incluyendo el día de fin
    const diffInDays = end.diff(start, 'days');

    // Calcular el número total de semanas completas entre las fechas de inicio y fin
    const numWeeks = Math.floor(diffInDays / 7);

    // Calcular el número de días de clases en la semana final
    const remainingDays = diffInDays % 7;

    // Calcular el número total de días de clases
    let numDaysOfClasses = numWeeks * numDaysPerWeek;

    // Verificar si el día de fin coincide con alguno de los días especificados en weekDays
    //const endDayOfWeek = end.day();
    //if (weekDays.includes(endDayOfWeek)) {
    //  numDaysOfClasses++;
    //}

    // Agregar los días de clases en la semana final si corresponde
    if (remainingDays > 0) {
      const lastDayOfWeek = start.add(numWeeks * 7, 'days').day();
      const daysInFinalWeek = weekDays.filter(
        day => day >= lastDayOfWeek
      ).length;
      numDaysOfClasses += Math.min(daysInFinalWeek, remainingDays);
    }

    return numDaysOfClasses;
  }
  return 0;
};

export const formatLocaleDate = sDate => {
  const oToday = dayjs().utc().format('YYYY-MM-DD');
  const oUtc = moment(sDate, 'HH:mm');
  const sTime = `${oToday}T${oUtc.format('HH:mm')}:00`;

  return dayjs(sTime).utc('z').local().format('HH:mm');
};
