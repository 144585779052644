import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tabs,
  Table,
  TimePicker,
  Tooltip,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { process, SAVE, UPDATE } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';
const { TabPane } = Tabs;
import dayjs from 'dayjs';

const daysOptions = [
  { label: 'Lunes', value: 1 },
  { label: 'Martes', value: 2 },
  { label: 'Miércoles', value: 3 },
  { label: 'Jueves', value: 4 },
  { label: 'Viernes', value: 5 },
  { label: 'Sábado', value: 6 },
  { label: 'Domingo', value: 7 },
];

const setArrays = (array, setter) => {
  if (!Array.isArray(array) || !array?.length) {
    return;
  }

  setter(array);
};

// TODO: Reorganize component's functions to improve readability

const MultipleModal = ({
  adviser,
  submit,
  loading,
  handler,
  visible,
  aPackagesSession = [],
  aClassSchedule = [],
  aExamSchedule = [],
  allow_exam = false,
  onDataChange = () => null,
}) => {
  // TODO: Reduce this amount of states
  const [form] = Form.useForm();
  const [packageForm] = Form.useForm();
  const [classForm] = Form.useForm();
  const [examForm] = Form.useForm();

  const [tab, setTab] = useState('1');
  const [packageLoading, setPackageLoading] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);

  const [packages, setPackages] = useState([]);
  const [hours, setHours] = useState([]);
  const [examHours, setExamHours] = useState([]);

  const [isEditingPackages, setIsEditingPackages] = useState(false);
  const [isEditingHours, setIsEditingHours] = useState(false);
  const [isEditingExamHours, setIsEditingExamHours] = useState(false);

  const needToUpdate = useRef(false);

  useEffect(() => {
    if (!visible) {
      return;
    }

    setArrays(aPackagesSession, setPackages);
    setArrays(aClassSchedule, setHours);
    setArrays(aExamSchedule, setExamHours);
    form.setFieldsValue({ cost_by_hour: adviser?.cost_by_hour });
  }, [visible]);

  const handleDeleteSchedule = async (row, nIndex) => {
    const { type } = row;
    const aTemp = [...(type === 'class' ? hours : examHours)];

    if (row._id) {
      const nFIndex = aTemp.findIndex(oItem => oItem._id === row._id);

      const response = await process(
        UPDATE,
        'advisers',
        { $pull: { schedules: { _id: row._id } } },
        { id: adviser._id }
      );

      if (response.ok) {
        message.success('Horario eliminado correctamente');
        aTemp.splice(nFIndex, 1);
        needToUpdate.current = true;
      } else {
        const { data } = response;
        handleErrors(data, 'horario');
        return;
      }
    } else {
      aTemp.splice(nIndex, 1);
    }

    if (type === 'class') {
      setHours(aTemp);
    } else {
      setExamHours(aTemp);
    }
  };

  const handleEditSchedule = (row, nIndex) => {
    const { type } = row;

    if (type === 'class') {
      classForm.setFieldsValue({
        ...row,
        time_range: [dayjs(row.time_range[0]), dayjs(row.time_range[1])],
      });

      setHours(hours.filter((_, index) => index !== nIndex));
      setIsEditingHours(true);
    } else {
      examForm.setFieldsValue({
        ...row,
        time_range: [dayjs(row.time_range[0]), dayjs(row.time_range[1])],
      });

      setExamHours(examHours.filter((_, index) => index !== nIndex));
      setIsEditingExamHours(true);
    }
  };

  const handleDeletePack = async (row, nIndex) => {
    const aTemp = [...packages];
    if (row._id) {
      const nFIndex = aTemp.findIndex(oItem => oItem.id === row.id);

      const response = await process(
        UPDATE,
        'session-packages',
        { status: 0 },
        { id: row._id }
      );

      if (response.ok) {
        message.success('Paquete eliminado correctamente');
        aTemp.splice(nFIndex, 1);
      } else {
        const { data } = response;
        handleErrors(data, 'paquete');
        return;
      }
    } else {
      aTemp.splice(nIndex, 1);
    }

    setPackages(aTemp);
  };

  const handleEditPack = (row, nIndex) => {
    packageForm.setFieldsValue(row);
    setPackages(packages.filter((_, index) => index !== nIndex));
    setIsEditingPackages(true);
  };

  const aColumns = [
    {
      dataIndex: 'day',
      key: 'day',
      render: day => {
        const sDay = daysOptions.find(oItem => oItem.value === day);

        return sDay?.label || '';
      },
      title: 'Día',
    },
    {
      dataIndex: 'time_range',
      key: 'time_range',
      render: value => {
        return dayjs(value[0]).format('h:mm a');
      },
      title: 'De',
    },
    {
      dataIndex: 'time_range',
      key: 'time_range',
      render: value => {
        return dayjs(value[1]).format('h:mm a');
      },
      title: 'A',
    },
    {
      dataIndex: 'day',
      key: 'day',
      render: (_, row, index) => (
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => handleEditSchedule(row, index)}
              disabled={
                row.type === 'class' ? isEditingHours : isEditingExamHours
              }
            >
              Editar
            </Button>
          </Col>
          <Col span={12}>
            <Popconfirm
              onConfirm={() => handleDeleteSchedule(row, index)}
              title="¿Está seguro de eliminar este horario, esta acción no se puede deshacer?"
            >
              <Button danger>Eliminar</Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
      title: 'Acciones',
    },
  ];

  const packageColumns = [
    {
      dataIndex: 'hours',
      key: 'hours',
      title: 'Horas',
    },
    {
      dataIndex: 'cost',
      key: 'cost',
      title: 'Costo',
    },
    {
      dataIndex: 'status',
      key: 'status',
      render: status => {
        const bStatus = status === 1;

        return (
          <Tag color={bStatus ? 'success' : 'error'}>
            {bStatus ? 'Activo' : 'Inactivo'}
          </Tag>
        );
      },
      title: 'Estatus',
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (_, row, index) => (
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => handleEditPack(row, index)}
              disabled={isEditingPackages}
            >
              Editar
            </Button>
          </Col>
          <Col span={12}>
            <Popconfirm
              onConfirm={() => handleDeletePack(row, index)}
              title="¿Está seguro de eliminar este paquete, esta acción no se puede deshacer?"
            >
              <Button danger>Eliminar</Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const handleNewPackages = async () => {
    setPackageLoading(true);
    const values = packageForm.getFieldsValue(true),
      oSend = {
        ...values,
        teacher_id: adviser._id,
      };

    let response, sMessage;

    if (values._id) {
      response = await process(UPDATE, 'session-packages', oSend, {
        id: values._id,
      });
      sMessage = 'Paquete actualizado correctamente';
    } else {
      response = await process(SAVE, 'session-packages', oSend);
      sMessage = 'Paquete guardado correctamente';
    }

    if (response.ok) {
      message.success(sMessage);
      setPackages([
        ...packages,
        {
          _id: response.data._id,
          ...values,
        },
      ]);
      packageForm.resetFields();
      needToUpdate.current = true;
    } else {
      const { data } = response;
      handleErrors(data, 'paquete');
    }

    setPackageLoading(false);
    setIsEditingPackages(false);
  };

  /**
   *
   * @param {integer} mode - 1: Class Schedule, 2: Exam Schedule
   */
  const handleSaveSchedule = async (mode = 1) => {
    setScheduleLoading(true);
    const currentHours = mode === 1 ? hours : examHours;
    let aTemp = [...currentHours],
      oValues = {},
      oSend = {},
      sMessage;

    if (mode === 1) {
      oValues = classForm.getFieldsValue(true);
    } else {
      oValues = examForm.getFieldsValue(true);
    }

    const [start, end] = oValues.time_range;

    if (oValues._id) {
      try {
        await process(
          UPDATE,
          'advisers',
          { $pull: { schedules: { _id: oValues._id } } },
          { id: adviser._id }
        );
      } catch (error) {
        setScheduleLoading(false);
        message.error('Hubo un error actualizando el horario: ', error);
        return;
      }
      sMessage = 'Horario actualizado correctamente';
    } else {
      sMessage = 'Horario guardado correctamente';
    }

    oSend = {
      $push: {
        schedules: [
          {
            day: oValues.day,
            start_date: start,
            end_date: end,
            type: mode === 1 ? 'class' : 'exam',
          },
        ],
      },
    };

    const response = await process(UPDATE, 'advisers', oSend, {
      id: adviser._id,
    });

    if (response.ok) {
      message.success(sMessage);

      const oSchedule = response.data.schedules.slice(-1)[0];

      aTemp.push({
        _id: oSchedule._id,
        day: oSchedule.day,
        time_range: [start, end],
      });

      if (mode === 1) {
        setHours(aTemp);
        classForm.resetFields();
      } else {
        setExamHours(aTemp);
        examForm.resetFields();
      }

      needToUpdate.current = true;
    } else {
      const { data } = response;
      handleErrors(data, 'horarios');
    }

    setScheduleLoading(false);

    if (mode === 1) {
      setIsEditingHours(false);
    } else {
      setIsEditingExamHours(false);
    }
  };

  /**
   *
   * @param {integer} mode - 0: Schedule, 1: Class Schedule, 2: Exam Schedule
   */
  const handleCancelEdit = (mode = null) => {
    switch (mode) {
      case 0: {
        const oValues = packageForm.getFieldsValue(true);
        setPackages([
          ...packages,
          {
            ...oValues,
          },
        ]);
        packageForm.resetFields();
        setIsEditingPackages(false);
        break;
      }
      case 1: {
        const oValues = classForm.getFieldsValue(true);
        setHours([
          ...hours,
          {
            ...oValues,
          },
        ]);
        classForm.resetFields();
        setIsEditingHours(false);
        break;
      }
      case 2: {
        const oValues = examForm.getFieldsValue(true);
        setExamHours([
          ...examHours,
          {
            ...oValues,
          },
        ]);
        examForm.resetFields();
        setIsEditingExamHours(false);
        break;
      }
      default:
        break;
    }
  };

  const handleModalFooter = () => {
    if (tab === '1') {
      return [
        <Button
          key="back"
          onClick={() => {
            handler(!visible);
          }}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          Guardar
        </Button>,
      ];
    }

    return [
      <Button
        key="back"
        type="primary"
        onClick={() => {
          handler(!visible);
        }}
      >
        OK
      </Button>,
    ];
  };

  return (
    <Divider>
      <Tooltip title="Configuración de Perfil">
        <Button
          onClick={() => handler(!visible)}
          size="large"
          type="primary"
          ghost
          icon={<SettingOutlined />}
        />
      </Tooltip>

      {/* Modal Begins */}
      <Modal
        centered
        title="Configuracion de Perfil"
        open={visible}
        onOk={() => form.submit()}
        okText="Guardar"
        confirmLoading={loading}
        onCancel={() => {
          handler(!visible);
        }}
        afterClose={() => {
          setPackages([]);
          setHours([]);
          setExamHours([]);
          setTab('1');
          form.resetFields();
          packageForm.resetFields();
          classForm.resetFields();
          examForm.resetFields();
          setIsEditingPackages(false);
          setIsEditingHours(false);
          setIsEditingExamHours(false);

          if (needToUpdate.current) {
            onDataChange();
            needToUpdate.current = false;
          }
        }}
        cancelButtonProps={{ disabled: loading }}
        footer={handleModalFooter()}
        width={700}
      >
        <Form
          form={form}
          onFinish={submit}
          name="adviser-profile"
          layout="horizontal"
        >
          <Tabs defaultActiveKey="1" onChange={setTab} activeKey={tab}>
            <TabPane tab="Costo General" key="1">
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Form.Item
                    name="cost_by_hour"
                    label="Costo por Sesión Individual"
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Paquetes de Sesiones" key="2">
              <Form
                form={packageForm}
                layout="vertical"
                onFinish={handleNewPackages}
              >
                <Row gutter={[12, 12]}>
                  <Col span={4}>
                    <Form.Item
                      name="_id"
                      label="id"
                      style={{ display: 'none' }}
                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item name="hours" label="Horas">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="cost" label="Costo">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Estatus" name="status">
                      <Select>
                        <Select.Option value={0}>Inactivo</Select.Option>
                        <Select.Option value={1}>Activo</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="&nbsp;">
                      <Button
                        onClick={() => packageForm.submit()}
                        loading={packageLoading}
                      >
                        {isEditingPackages ? 'Actualizar' : 'Agregar'}
                      </Button>
                    </Form.Item>
                  </Col>
                  {isEditingPackages && (
                    <Col>
                      <Form.Item label="&nbsp;">
                        <Button danger onClick={() => handleCancelEdit(0)}>
                          Cancelar
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table columns={packageColumns} dataSource={packages} />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Disponibilidad para clases" key="3">
              <Form
                form={classForm}
                layout="vertical"
                onFinish={() => handleSaveSchedule(1)}
              >
                <Row align="middle" gutter={[12, 12]}>
                  <Col span={6}>
                    <Form.Item
                      label="Seleccione el día"
                      name="day"
                      rules={[
                        {
                          required: true,
                          message: 'Debe ingresar un día',
                        },
                      ]}
                    >
                      <Select>
                        {daysOptions.map(oItem => (
                          <Select.Option key={oItem.value} value={oItem.value}>
                            {oItem.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label="Horario"
                      name="time_range"
                      rules={[
                        {
                          required: true,
                          message: 'Debe ingresar un horario',
                        },
                      ]}
                    >
                      <TimePicker.RangePicker
                        allowClear
                        use12Hours
                        format="h:mm a"
                        minuteStep={5}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="&nbsp;">
                      <Button
                        onClick={() => classForm.submit()}
                        loading={scheduleLoading}
                      >
                        {isEditingHours ? 'Actualizar' : 'Agregar'}
                      </Button>
                    </Form.Item>
                  </Col>
                  {isEditingHours && (
                    <Col>
                      <Form.Item label="&nbsp;">
                        <Button danger onClick={() => handleCancelEdit(1)}>
                          Cancelar
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table columns={aColumns} dataSource={hours} />
                </Col>
              </Row>
            </TabPane>

            {allow_exam && (
              <TabPane tab="Disponibilidad para examen" key="4">
                <Form
                  form={examForm}
                  layout="vertical"
                  onFinish={() => handleSaveSchedule(2)}
                >
                  <Row align="middle" gutter={[12, 12]}>
                    <Col span={6}>
                      <Form.Item
                        label="Seleccione el día"
                        name="day"
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar un día',
                          },
                        ]}
                      >
                        <Select>
                          {daysOptions.map(oItem => (
                            <Select.Option
                              key={oItem.value}
                              value={oItem.value}
                            >
                              {oItem.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        label="Horario"
                        name="time_range"
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar un horario',
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          allowClear
                          use12Hours
                          format="h:mm a"
                          minuteStep={5}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="&nbsp;">
                        <Button
                          onClick={() => examForm.submit()}
                          loading={scheduleLoading}
                        >
                          {isEditingExamHours ? 'Actualizar' : 'Agregar'}
                        </Button>
                      </Form.Item>
                    </Col>
                    {isEditingExamHours && (
                      <Col>
                        <Form.Item label="&nbsp;">
                          <Button danger onClick={() => handleCancelEdit(2)}>
                            Cancelar
                          </Button>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Form>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Table columns={aColumns} dataSource={examHours} />
                  </Col>
                </Row>
              </TabPane>
            )}
          </Tabs>
        </Form>
      </Modal>
    </Divider>
  );
};

MultipleModal.propTypes = {
  adviser: PropTypes.object,
  handler: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  submit: PropTypes.func,
  aPackagesSession: PropTypes.arrayOf(PropTypes.object),
  aClassSchedule: PropTypes.arrayOf(PropTypes.object),
  aExamSchedule: PropTypes.arrayOf(PropTypes.object),
  allow_exam: PropTypes.bool,
  onDataChange: PropTypes.func,
};

export default MultipleModal;
