import React from 'react';
import { Typography, Divider, List } from 'antd';

const { Title, Text } = Typography;

export const Companies = () => {
  const data = [
    'Sus alumnos pueden incorporarse a clases de idiomas en grupos, con costos muy accesibles y grupos reducidos, o bien a clases particulares con horarios, niveles y maestros a elegir.',
    'Sus los alumnos se incorporan a una escuela con más de 15 años de experiencia dando clases en la zona industrial a empresas de todos tamaños y de calidad mundial. Educom puede bajo petición dar datos y referencias de empresas en las que ha impartido clases de idiomas.',
    'Recibe reporte de avances de sus alumnos.',
    'La administración de los estudios de sus empleados se facilita, ya que cada empleado es responsable de incorporarse a un grupo o de tomar una clase privada y de avanzar. Educom le notificará y dará constancia cada vez que un empleado o miembro de su organización apruebe un nivel del Marco Común Europeo de Referencia para las Lenguas.',
  ];
  return (
    <div className="companies-information" style={{ padding: '20px' }}>
      <div
        className="companies-information-header"
        style={{ textAlign: 'center' }}
      >
        <Title level={2}>Descuentos para empresas y grupos</Title>
      </div>
      <div className="companies-content">
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Descuentos</Title>
          </Divider>
          <Text>Información sobre descuentos para empresas y grupos</Text>
        </div>
        <div className="info-section">
          <Divider orientation="left" orientationMargin={0}>
            <Title level={3}>Ventajas</Title>
          </Divider>
          <List
            size="large"
            header={
              <Text strong>
                Ventajas para empresas y grupos de estudiar en Educom:
              </Text>
            }
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <Text>{item}</Text>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};
