import React, { useState, useEffect } from 'react';
import { Input, Button, List, Alert, Divider } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs'; // Importa dayjs para formateo de fechas
import { PreviewShedule } from './previewSchedule';
import {
  days,
  formatEvents,
  formatSchedules,
  formatSchedulesTimes,
} from '../../Utils/formatShedule';

export const ChatBox = ({
  messages: request,
  currentUserId,
  onSubmit,
  setModal,
  isAccepted,
  status = -1,
  schedulesClient,
  schedulesAdviser,
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState(request?.schedule_proposal);
  const [newSchedules, setNewSchedules] = useState([]);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '') {
      const newMessageObject = {
        message: newMessage,
        user_id: currentUserId,
        date: new Date().toISOString(),
      };

      setMessages(prevMessages => [...prevMessages, newMessageObject]);
      setNewMessage('');

      let updatedSchedules = [];

      if (newSchedules.length > 0) {
        updatedSchedules = newSchedules.map(schedule => {
          const [day, hours] = schedule.split('-');

          const [start, end] = hours.split('a').map(time => time.trim());

          const currentDate = dayjs();

          return {
            day: days.indexOf(day.trim()) + 1,
            start: dayjs(
              `${currentDate.format('YYYY-MM-DD')} ${start}`
            ).toISOString(),
            end: dayjs(
              `${currentDate.format('YYYY-MM-DD')} ${end}`
            ).toISOString(),
          };
        });
      }

      onSubmit(request, [newMessageObject], updatedSchedules);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await handleSendMessage();
    if (setModal) {
      setModal(false);
    }
  };

  const formatDate = date => {
    const targetDate = dayjs(date);
    const now = dayjs();
    const yesterday = now.subtract(1, 'day');

    if (now.isSame(targetDate, 'day')) {
      return 'hoy';
    } else if (yesterday.isSame(targetDate, 'day')) {
      return 'ayer';
    } else {
      return targetDate.format('ddd D');
    }
  };

  const formateSchedulesAdviser = formatEvents(
    formatSchedulesTimes(schedulesAdviser)
  );

  useEffect(() => {
    if (request) {
      setMessages(request.schedule_proposal);
    }
  }, [request]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flex: 1, overflowY: 'auto', padding: '10px' }}>
        {isAccepted !== undefined &&
          (isAccepted ? (
            <div>
              <Alert
                message="Aceptar Solicitud"
                description="Usa la caja de textos para acordar los detalles."
                type="info"
                showIcon
              />
              <PreviewShedule
                horarios={[
                  '7:12 PM',
                  '8:12 PM',
                  '5:00 PM',
                  '6:00 PM',
                  '3:00 PM',
                  '4:00 PM',
                ]} //Todo: Reemplazar con los horarios reales
                horariosConfig={formatSchedules(formateSchedulesAdviser, true)} //Todo: Reemplazar con los horarios reales
                onHorarioSelect={msj => {
                  setNewMessage(msj);
                }}
                schedulesClient={schedulesClient}
                setNewSchedules={setNewSchedules}
              />
            </div>
          ) : (
            <Alert
              message="Cancelar Solicitud"
              description="Usa la caja de textos para describir los motivos."
              type="warning"
              showIcon
            />
          ))}
        <List
          dataSource={messages}
          renderItem={item => (
            <List.Item
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:
                  item.user_id === currentUserId ? 'flex-start' : 'flex-end',
                marginBottom: '10px',
              }}
              key={item._id}
            >
              <div
                style={{
                  backgroundColor:
                    item.user_id === currentUserId ? '#e5e5ea' : '#d4eaf9',
                  padding: '10px',
                  borderRadius: '10px',
                  maxWidth: '70%',
                  alignSelf:
                    item.user_id === currentUserId ? 'flex-start' : 'flex-end',
                }}
              >
                {item.message}
                <div style={{ fontSize: '12px', textAlign: 'right' }}>
                  {dayjs(item.date).format('hh:mm A')}{' '}
                  {/* Formato de 12 horas */}
                </div>
              </div>
              {dayjs(item.date).isBefore(dayjs(), 'day') && (
                <Divider style={{ opacity: '0.3' }}>
                  <div style={{ fontSize: '12px', textAlign: 'right' }}>
                    {formatDate(item.date)}
                  </div>
                </Divider>
              )}
            </List.Item>
          )}
        />
      </div>
      <form onSubmit={[4, 5].includes(status) ? null : handleSubmit}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            borderTop: '1px solid #e5e5ea',
          }}
        >
          <Input.TextArea
            allowClear
            autoSize
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            placeholder="Escribe un mensaje"
            style={{ flex: 1, marginRight: '10px' }}
            disabled={[4, 5].includes(status)}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            htmlType="submit"
            disabled={[4, 5].includes(status)}
          />
        </div>
      </form>
    </div>
  );
};

ChatBox.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentUserId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setModal: PropTypes.func,
  isAccepted: PropTypes.bool,
  status: PropTypes.number,
  schedulesClient: PropTypes.array,
  schedulesAdviser: PropTypes.array,
};
