import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Select,
  Popconfirm,
  Row,
  Tag,
  Table,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  levelsSelect,
  languages as languagesList,
} from '../../Constants/levels';
import { process, UPDATE } from '../../Service/Api';
import { handleErrors } from '../../Utils/errors';
import PropTypes from 'prop-types';

const tagRender = props => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="cyan"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}
    >
      {label}
    </Tag>
  );
};

export const CEFRForm = ({
  languages = [],
  selected = {},
  visible,
  onCancel,
  onFinish,
}) => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [aLang, setLang] = useState([]);
  const [formRef] = Form.useForm();

  const needToUpdate = useRef(false);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (languages.length === 0) {
      return;
    }

    setLang(languages);
  }, [visible]);

  const handleEdit = (row, nIndex) => {
    setIsEditing(true);
    formRef.setFieldsValue(row);
    setLang(aLang.filter((_, index) => index !== nIndex));
  };

  const handleDelete = async (row, nIndex) => {
    const aTemp = [...aLang];

    if (!row._id) {
      message.error('No se puede eliminar este lenguaje');
      return;
    }

    const response = await process(
      UPDATE,
      'advisers',
      { $pull: { languages: { _id: row._id } } },
      { id: selected._id }
    );
    if (response.ok) {
      message.success('Lenguaje eliminado correctamente');
      aTemp.splice(nIndex, 1);
      needToUpdate.current = true;
    } else {
      const { data } = response;
      handleErrors(data, 'instructores');
      return;
    }
    setLang(aTemp);
  };

  const handleCancel = () => {
    const aValues = formRef.getFieldsValue();
    setLang([...aLang, aValues]);
    formRef.resetFields();
    setIsEditing(false);
  };

  const columns = [
    {
      title: 'Lenguaje',
      dataIndex: 'language',
    },
    {
      title: 'Nivel(es)',
      dataIndex: 'levels',
      render: levels => {
        if (levels.length === 0) {
          return 'Sin nivel';
        }

        return levels.map(level => (
          <Tag color="cyan" key={level}>
            {level}
          </Tag>
        ));
      },
    },
    {
      title: 'Acciones',
      dataIndex: '_id',
      render: (_, row, index) => (
        <Row gutter={[12, 12]}>
          <Col>
            <Button onClick={() => handleEdit(row, index)} disabled={isEditing}>
              <EditOutlined />
            </Button>
          </Col>
          <Col>
            <Popconfirm
              onConfirm={() => handleDelete(row, index)}
              title="¿Está seguro de eliminar este lenguaje (esta acción no se puede deshacer)?"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const _handleSubmit = async () => {
    setLoading(true);
    let oValues = formRef.getFieldsValue(true),
      aTemp = [...aLang],
      oSend,
      sMessage;

    if (oValues._id) {
      try {
        await process(
          UPDATE,
          'advisers',
          { $pull: { languages: { _id: oValues._id } } },
          { id: selected._id }
        );
      } catch (error) {
        setLoading(false);
        message.error('Hubo un error actualizando el lenguaje: ', error);
      }
      sMessage = 'Lenguaje actualizado correctamente';
    } else {
      sMessage = 'Lenguaje agregado correctamente';
    }

    oSend = {
      $push: {
        languages: [
          {
            language: oValues.language,
            levels: oValues.levels,
          },
        ],
      },
    };

    const response = await process(UPDATE, 'advisers', oSend, {
      id: selected._id,
    });

    if (response.ok) {
      message.success(sMessage);

      const oLanguage = response.data.languages.slice(-1)[0];

      aTemp.push({
        _id: oLanguage._id,
        language: oLanguage.language,
        levels: oLanguage.levels,
      });

      setLang(aTemp);
      formRef.resetFields();
      setIsEditing(false);
      needToUpdate.current = true;
    } else {
      const { data } = response;
      handleErrors(data, 'lenguajes del asesor');
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      afterClose={() => {
        formRef.resetFields();
        setIsEditing(false);
        setLang([]);
        needToUpdate.current = false;
      }}
      destroyOnClose
      maskClosable={false}
      onCancel={needToUpdate.current ? onFinish : onCancel}
      title="Seleccionar nivel de MCER / CEFR"
      open={visible}
      width={800}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={needToUpdate.current ? onFinish : onCancel}
        >
          Finalizar
        </Button>,
      ]}
    >
      <Form
        form={formRef}
        layout="vertical"
        name="CEFRForm"
        onFinish={_handleSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <Form.Item
              label="Lenguaje"
              name="language"
              rules={[
                {
                  required: true,
                  message: 'Debe ingresar un lenguaje',
                },
              ]}
            >
              <Select
                options={languagesList.filter(
                  item =>
                    aLang.map(lang => lang.language).indexOf(item.value) === -1
                )}
                placeholder="Seleccionar lenguaje"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Nivel(es)"
              name="levels"
              rules={[
                {
                  required: true,
                  message: 'Debe ingresar al menos un nivel',
                },
              ]}
            >
              <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                options={levelsSelect}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="&nbsp;">
              <Button onClick={() => formRef.submit()} loading={loading}>
                {isEditing ? 'Actualizar' : 'Agregar'}
              </Button>
            </Form.Item>
          </Col>
          {isEditing && (
            <Col span={3}>
              <Form.Item label="&nbsp;">
                <Button danger onClick={() => handleCancel()}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      <Table columns={columns} dataSource={aLang} />
    </Modal>
  );
};

CEFRForm.propTypes = {
  languages: PropTypes.array,
  selected: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
};
