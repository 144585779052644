/* eslint-disable indent */
import { React, useEffect, useState } from 'react';
import { Avatar, Card, Col, Divider, Row } from 'antd';
import { CustomButton } from '../Atoms/CustomButtom';
import { StarFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuth, useFetchData, useNavigation } from '../../Hooks';
import { getServer } from '../../Utils/url';
import { useSelector } from 'react-redux';
import { ExpandableText } from '../Atoms/ExpanderText';

/* eslint-disable */
export const CardAdviser = ({
  adviserData,
  handlerSubscribe = (adviser, sessions) => ({}),
}) => {
  /* eslint-enable */
  const [{ token }] = useAuth();
  const url = getServer();
  const [, nav] = useNavigation();
  const { user } = useSelector(state => state.auth);
  const isLogedIn = !!Object.keys(user).length;

  const _handleDetails = sId => {
    nav(`/advisers/details/${sId}`);
  };

  const [aReviews] = useFetchData(
    'reviews',
    `adviser_id=${adviserData._id}&status=1`,
    undefined,
    undefined,
    !isLogedIn
  );

  const [aPackagesSession] = useFetchData(
    'session-packages',
    `teacher_id=${adviserData._id}&status=1`,
    undefined,
    undefined,
    !isLogedIn
  );

  const [averageStarsState, setAverageStarsState] = useState(null);

  useEffect(() => {
    if (!isNaN(averageStarsState)) {
      setAverageStarsState(averageStarsState);
    }
  }, [averageStarsState]);

  const reviews = aReviews.data;

  const { totalStarts, totalReviews } = reviews.reduce(
    (accumulator, review) => ({
      totalStarts: accumulator.totalStarts + review.stars,
      totalReviews: accumulator.totalReviews + 1,
    }),
    { totalStarts: 0, totalReviews: 0 }
  );

  const averageStarsCalculation = Math.round(totalStarts / totalReviews);

  return (
    <Card className="card-desing">
      <Row gutter={4} align={'middle'} justify={'space-evenly'}>
        <Col
          span={8}
          style={{
            padding: 0,
            margin: 0,
            height: '5rem',
            justifyContent: 'center',
          }}
        >
          <Row gutter={[2, 2]} justify={'center'} align={'middle'}>
            <Avatar
              shape={'square'}
              size={80}
              src={
                adviserData?.user?.photo
                  ? `${url}/upload?target=${adviserData.user.photo}&token=${token}`
                  : 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'
              }
            />
          </Row>
        </Col>
        <Col
          xl={16}
          xxl={16}
          md={14}
          xs={16}
          sm={16}
          style={{ paddingLeft: '1em' }}
        >
          <Row gutter={[4, 2]} justify={'start'}>
            <Col span={24}>
              <span className="label">Idiomas:</span>
            </Col>
            <Col span={24}>
              <div className={'texto'}>
                {adviserData?.languages?.length > 0 &&
                  adviserData.languages.map((lang, index) => (
                    <span key={index}>
                      {lang.language}{' '}
                      {index < adviserData?.languages?.length - 1 ? ' - ' : ''}{' '}
                    </span>
                  ))}
              </div>
            </Col>
          </Row>
          <Row gutter={[4, 2]} justify={'start'}>
            <Col span={24}>
              <span className="label">Niveles:</span>
            </Col>
            <Col span={24} style={{ marginBottom: 8 }}>
              <div className={'texto'}>
                {adviserData?.languages?.length > 0 &&
                  adviserData.languages.map((lang, index) => (
                    <span key={index}>
                      {lang.levels.length > 0 &&
                        lang.levels.map(
                          (lv, eIndex) =>
                            `${lv}${
                              eIndex < lang.levels.length - 1 ? ' - ' : ''
                            } `
                        )}
                    </span>
                  ))}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row justify={'start'}>
        <Col
          span={12}
          className="cost_by_hour-container flex-and-directions-columns"
        >
          <span className="label">Costo por hora:</span>
          <span className={'texto'}>
            {Number(adviserData?.price)
              ? ` $ ${Number(adviserData?.cost_by_hour).toFixed(2)} MXN `
              : ` $ ${adviserData?.cost_by_hour} MXN `}
          </span>
        </Col>
        <Col span={12} className="flex-and-directions-columns">
          <span className="label">Reseñas:</span>
          <span className={'texto'}>
            <StarFilled style={{ color: '#F7D050' }} width={200} />
            {` ${
              isNaN(averageStarsCalculation) ? 0 : averageStarsCalculation
            }/5`}
          </span>
        </Col>
        <Divider className="divider-style large-margin" />
        <Col span={24} style={{ marginTop: 0, paddingTop: 0 }}>
          <span className="label">Maestro:</span>
        </Col>
        <Col span={24}>
          <span className={'texto'}>
            {adviserData?.name} {adviserData?.last_name}
          </span>
        </Col>
        <Divider className="divider-style large-margin" />
        <Col span={24} style={{ marginTop: 0, paddingTop: 0 }}>
          <span className="label">Sobre mi:</span>
        </Col>
        <Col span={24} style={{ maxHeight: '75px', height: '75px' }}>
          <ExpandableText
            className="texto"
            text={adviserData.bio ?? ''}
            limit={100}
          ></ExpandableText>
        </Col>
        <CustomButton
          handleAction={() =>
            adviserData?._id ? _handleDetails(adviserData._id) : null
          }
          title="Perfil completo"
          type="default"
          className={'btn-info'}
        />
        <Divider className="divider-style" />
        <CustomButton
          handleAction={() => handlerSubscribe(adviserData, aPackagesSession)}
          size="large"
          title="Inscríbete ahora"
          type="primary"
          className={'btn-primary'}
        />
      </Row>
    </Card>
  );
};

CardAdviser.propTypes = {
  adviserData: PropTypes.object,
  isAdmin: PropTypes.bool,
  handlerSubscribe: PropTypes.func,
};
