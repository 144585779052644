import dayjs from 'dayjs';

/**
 * Generic function to get sorter for antd table
 * @param {string} field - Field to sort
 * @param {string} type - Type of field to sort
 */
export function getSorter(field, type = '') {
  switch (type) {
    case 'number':
      return (a, b) => +a[field] - +b[field];
    case 'date':
      return (a, b) =>
        !!a[field] && !!b[field] ? dayjs(a[field]) - dayjs(b[field]) : null;
    default:
      return (a, b) =>
        !!a[field] && !!b[field] ? a[field].localeCompare(b[field]) : null;
  }
}
